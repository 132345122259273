import WebComponent from '../../../../WebComponent.js'
import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/List/List.js'
import ListService from '@isceco/widget-library2/basic-elements/List/ListService.js'
import {geschaeftsVorfallNavigationLinks} from "../../Geschaeftsvorfall";

export default class StatistikendatenUebersicht extends WebComponent {

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {

    return html`
        <vzavg-link
          size="medium" text="${this.i18n.translate('title')}"
          resource="${geschaeftsVorfallNavigationLinks.statistikDaten.hash}"
        ></vzavg-link>
        <p>content</p>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/StatistikendatenUebersicht/i18n.json'
  }
}
customElements.define('statistikdaten-uebersicht', StatistikendatenUebersicht)

class StatistikdatenUebersichtListService extends ListService {
  get data() {
    return [
      {nb: 'Anzahl Schweizer', value: '386'},
      {nb: 'Anzahl Schweizerinnen', value: '405'},
      {nb: 'Anzahl Ausländer', value: '319'},
      {nb: 'Anzahl Ausländerinnen', value: '111'},
      {nb: 'Anzahl verliehene Personen', value: "1'221"},
      {nb: 'Geleistete Ensatzstunden', value: "381'317"}
    ]
  }
}
