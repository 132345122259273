import {html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class GeschaeftsstellenUebersicht extends TranslationElement {

  static get properties() {
    return {
      adressen: {type: Array},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.adressen?.length) {
      return html`
        ${this._renderTitel()}
        <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.geschaeftsstellen.no.items')}</p>
      `
    }
    return html`
      ${this._renderTitel()}
      <div>
        ${this.adressen?.map(adresse => this._renderAdresse(adresse))}
      </div>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/GeschaeftsstellenUebersicht/i18n.json'
  }

  _renderAdresse(adresse) {
    return html`
      <adresse-element .adresse="${adresse}" gesuchId="${this.gesuchId}"></adresse-element>`
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.geschaeftsstellen.title')}"
        resource="${geschaeftsVorfallNavigationLinks.adresse.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

}
customElements.define('geschaeftsstellen-uebersicht', GeschaeftsstellenUebersicht)
