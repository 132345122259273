import {html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class PendenzenUebersicht extends TranslationElement {

  render() {
    return html`
      ${this._renderTitel()}
      <p>
        ${this.i18n.translate('geschaeftsvorfall.uebersicht.pendenzen.title.anzahl')}: <a href="${this.link}">${this.totalPendenzen}</a>
      </p>
    `
  }

  static get properties() {
    return {
      totalPendenzen: {type: Number},
      gesuchId: {type: String}
    }
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.pendenzen.title')}"
        resource="${geschaeftsVorfallNavigationLinks.pendenz.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.pendenz.hash}/${this.gesuchId}`
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/PendenzenUebersicht/i18n.json'
  }
}
customElements.define('pendenzen-uebersicht', PendenzenUebersicht)
