import BetriebService from "../../../services/BetriebService";
import {geschaeftsVorfallNavigationLinks} from "../Geschaeftsvorfall";
import BetriebHistoryColumns from "./Columns/BetriebHistoryColumns";
import GesuchHistoryColumns from "./Columns/GesuchHistoryColumns";
import KautionService from "../../../services/KautionService";
import KautionHistoryColumns from "./Columns/KautionHistoryColumns";
import GesuchService from "../../../services/GesuchService";
import AdresseHistoryColumns from "./Columns/AdresseHistoryColumns";
import VerantwortlichePersonHistoryColumns from "./Columns/VerantwortlichePersonHistoryColumns";
import AdresseService from "../../../services/AdresseService";
import StatistikService from "../../../services/StatistikService";
import StatistikdatenHistoryColumns from "./Columns/StatistikdatenHistoryColumns";
import VerantwortlichePersonService from "../../../services/VerantwortlichePersonService";

export default class AuditedViews {
  static getViews() {
    return [
      {
        key: geschaeftsVorfallNavigationLinks.betriebHistory.id,
        service: new BetriebService(),
        backUrl: geschaeftsVorfallNavigationLinks.betrieb.hash,
        columns: BetriebHistoryColumns
      },
      {
        key: geschaeftsVorfallNavigationLinks.gesuchHistory.id,
        service: new GesuchService(),
        backUrl: geschaeftsVorfallNavigationLinks.gesuch.hash,
        columns: GesuchHistoryColumns
      },
      {
        key: geschaeftsVorfallNavigationLinks.adresseHistory.id,
        service: new AdresseService(),
        backUrl: geschaeftsVorfallNavigationLinks.adresse.hash,
        columns: AdresseHistoryColumns
      },
      {
        key: geschaeftsVorfallNavigationLinks.verantwortlichePersonHistory.id,
        service: new VerantwortlichePersonService(),
        backUrl: geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash,
        columns: VerantwortlichePersonHistoryColumns
      },
      {
        key: geschaeftsVorfallNavigationLinks.kautionHistory.id,
        service: new KautionService(),
        backUrl: geschaeftsVorfallNavigationLinks.kaution.hash,
        columns: KautionHistoryColumns
      }
      ,
      {
        key: geschaeftsVorfallNavigationLinks.statistikDatenHistory.id,
        service: new StatistikService(),
        backUrl: geschaeftsVorfallNavigationLinks.statistikDaten.hash,
        columns: StatistikdatenHistoryColumns
      }
    ]
  }
}
