import {css, html, LitElement} from "@isceco/widget-library2/external/lit";

class Link extends LitElement {

  static styles = css`
    a {
      cursor: pointer;
      text-decoration: none;
      color: var(--isceco-color-black-500);
    }
  `

  static get properties() {
    return {
      text: {type: String},
      resource: {type: String},
      size: {type: String}
    }
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${this.resource}`
  }


  render() {
    let linkText
    if (this.size === 'small') {
      linkText = html`<h4>${this.text}</h4>`
    } else if (this.size === 'medium') {
      linkText = html`<h3>${this.text}</h3>`
    } else if (this.size === 'big') {
      linkText = html`<h2>${this.text}</h2>`
    } else {
      linkText = html`<p>${this.text}</p>`
    }
    return html`
      <a href="${this.link}">
          ${linkText}
      </a>
    `
  }
}
customElements.define('vzavg-link', Link)
