import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import AdressverzeichnisService from '../../services/AdressverzeichnisService';

/**
 * PlzOrtInput Component
 * Renders two input fields one for plz and one for ort.
 * The two fields have a suggestion list of the addressVerzeichnissService with all the possible ort and plz.
 * When one filed changes the other one gets updated.
 */
export default class PlzOrtInput extends LitElement {

  static styles = css`
    .input-wrapper {
      display: flex;
      gap: 0.3em;
    }
  `;


  static get properties() {
    return {
      plzOrtArray: {type: Array},
      /**
       * @typdef PlzOrt
       * @property {string} ortBez
       * @property {number} plz
       * @property {number} onrp
       */
      /**@type PlzOrt **/
      value: {type: Object},
      ortValue: {type: String},
      plzValue: {type: String},
      readonly: {type: Boolean},
      required: {type: Boolean},
      i18n: {type: Array},
      onlyOrtInput: {type: Boolean},
      /**Label for the PLZ and Ort input **/
      plzLabel: {type: String},
      ortLabel: {type: String}
    }
  }

  /**
   * Returns a list for the ort dropdown with the name, value and listValueText of all items
   * @returns {{listValueText: string, name: string, value: number}[]}
   */
  get mappedOrtStrings() {
    return this.plzOrtArray
      .map(po => ({
        name: po.ortBez,
        value: po.onrp,
        listValueText: this.onlyOrtInput ? po.ortBez : `${po.plz} ${po.ortBez}`
      }))
      .sort((a, b) => {
        const ortA = a.name.toLowerCase()
        const ortB = b.name.toLowerCase()
        if (ortA < ortB) {
          return -1
        }
        if (ortB < ortA) {
          return 1
        }

        if (this.onlyOrtInput) {
          return 0;
        } else {
          const plzA = parseInt(a.listValueText.substring(0, 4))
          const plzB = parseInt(b.listValueText.substring(0, 4))
          return plzA - plzB
        }
      })
  }

  /**
   * Returns a list for the plz dropdown with the name, value and listValueText of all items
   * @returns {{listValueText: string, name: string, value: number}[]}
   */
  get mappedPlzStrings() {
    if (!this.plzOrtArray) {
      return 0
    }

    return this.plzOrtArray
      .map(po => ({name: po.plz, listValueText: `${po.plz} ${po.ortBez}`, value: po.onrp}))
      .sort((a, b) => {
        const plzA = parseInt(a.name)
        const plzB = parseInt(b.name)

        const cmpResult = plzA - plzB

        if (cmpResult === 0) {
          const ortA = a.listValueText.substring(5, a.listValueText.length).toLowerCase()
          const ortB = b.listValueText.substring(5, b.listValueText.length).toLowerCase()
          if (ortA < ortB) {
            return -1
          }
          if (ortB < ortA) {
            return 1
          }
          return 0
        }

        return cmpResult
      })
  }

  /**
   * returns the identifier of the plzOrt Item
   * @returns {number}
   */
  get valueOnRp() {
    return this.value?.onrp
  }

  /**
   * validates the field
   * @returns {boolean}
   */
  validate = () => {
    if (this.readonly) {
      return true
    }
    const plzElement = this.shadowRoot.querySelector('#plz')
    const validatePlz = isEmpty(plzElement) ? true : plzElement.validate()

    const ortElement = this.shadowRoot.querySelector('#ort')
    const validateOrt = isEmpty(ortElement) ? true : ortElement.validate()

    return validatePlz && validateOrt
  }

  /**
   * implementation of getValue for the FormElement
   * @returns {plzOrt}
   */
  getValue = () => this.value


  constructor() {
    super()
    this.adressverzeichnisService = new AdressverzeichnisService()
    this.plzOrtArray = []

  }

  connectedCallback() {
    super.connectedCallback()
    this._updateValueBasedOnPlzOrt()
  }

  updated(changedProperties) {
    if (changedProperties.has('ortValue') || changedProperties.has('plzValue')) {
      this._updateValueBasedOnPlzOrt()
    }
  }

  render() {
    return html`
      <div class="input-wrapper">
        ${!this.onlyOrtInput ? this._renderPLZInput() : html``}
        ${this._renderOrtInput()}
      </div>
    `;
  }

  _renderPLZInput() {
    return html`
      <vzavg-searchable-text-input
        id="plz"
        name="plz"
        .items="${this.mappedPlzStrings}"
        .value="${this.valueOnRp}"
        .label="${this.plzLabel && this.plzLabel.length ? this.plzLabel : this.i18n.translate('geschaeftsvorfall.betrieb.plz')} ${this.required ? ' *' : ''}"
        @change="${e => this._handleValueChange(e)}"
        @click="${e => {
          e.stopPropagation()
          this._closeOptions('ort')
        }}"
        style="max-width: 7em"
        ?readonly="${this.readonly}"
        ?required="${this.required}"
      ></vzavg-searchable-text-input>
    `
  }

  _renderOrtInput() {
    return html`
      <vzavg-searchable-text-input
        id="ort"
        name="ort"
        .value="${this.valueOnRp}"
        .label="${this.ortLabel && this.ortLabel.length ? this.ortLabel : this.i18n.translate('geschaeftsvorfall.betrieb.ort')} ${this.required ? ' *' : ''}"
        @change="${e => this._handleValueChange(e)}"
        style="flex:1; min-width: 5em"
        @click="${e => {
          e.stopPropagation()
          this._closeOptions('plz')
        }}"
        ?readonly="${this.readonly}"
        ?required="${this.required}"
        .items="${this.mappedOrtStrings}"
      ></vzavg-searchable-text-input>
    `
  }

  /**
   * closes the options of the searchable text input with the given id
   *
   * @param id the id of the searchable text input element
   * @private
   */
  _closeOptions(id) {
    const el = this.shadowRoot.getElementById(id)
    el.closeOptions()
  }

  /**
   * Sets value when value changed and dispatch the change event with the new value
   * @param event
   * @private
   */
  _handleValueChange(event) {
    if (!event.detail.valid) {
      this.adressverzeichnisService.getPlzAutoComplete(event.target.id === 'plz' ? event.detail.value : '', event.target.id === 'ort' ? event.detail.value : '')
        .then(res => {
          this.plzOrtArray = res
          this.requestUpdate()
          this.updateComplete
            .then(_ => send('refreshSearchableTextInput', null, this))
        })
        .catch(_ => {
          this._showErrorMsg()
          return []
        })
    } else {
      this.value = this.plzOrtArray.find(p => p.onrp === event.detail.value)
      send('change', this.value, this)
    }
  }

  /**
   * When value is already set then find corresponding address in plzOrtArray
   * @private
   */
  _updateValueBasedOnPlzOrt() {
    if (this.plzValue && this.ortValue) {
      this.adressverzeichnisService.getPlzAutoComplete(this.plzValue, this.ortValue)
        .then(res => {
          this.plzOrtArray = res
          if (this.plzValue && this.ortValue) {
            this.value = this.plzOrtArray.find(po => po.ortBez === this.ortValue && po.plz === this.plzValue.toString())
          }
          if (!this.value) {  // necessary when the filled ort does not match the ort from AdressVerzeichniss
            this.value = this.plzOrtArray.find(po => po.plz === this.plzValue.toString())
          }
          if (this.onlyOrtInput && this.ortValue) {
            this.value = this.plzOrtArray.find(po => (po.ortBez === this.ortValue))
          }
        })
        .catch(_ => {
          this._showErrorMsg()
          return []
        })
    }
  }


}

customElements.define('vzavg-plz-ort-input', PlzOrtInput);
