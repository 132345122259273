import WebComponent from '../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit/index.js'
import BetriebSearchColumns from './Form/Result/BetriebSearchColumns.js'
import VzavgListService from '../../services/VzavgListService.js'
import './Template/SuchenTemplate.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import SuchenForm from './Form/SuchenForm.js'
import BetriebService from '../../services/BetriebService.js'
import SuchenNavigation from './Navigation/SuchenNavigation.js'
import PendenzSearchColumns from './Form/Result/PendenzSearchColumns.js'
import PendenzSuchService from '../../services/PendenzSuchService.js'
import NotizSuchService from '../../services/NotizSuchService.js'
import NotizSearchColumns from './Form/Result/NotizSearchColumns.js'
import VerantwortlichePersonSuchService from '../../services/VerantwortlichePersonSuchService.js'
import VerantwortlichePSearchColumns from './Form/Result/VerantwortlichePSearchColumns.js'
import KautionSuchService from '../../services/KautionSuchService.js'
import KautionSearchColumns from './Form/Result/KautionSearchColumns.js'
import GesuchSuchService from '../../services/GesuchSuchService.js'
import GesuchSearchColumns from './Form/Result/GesuchSearchColumns.js'
import BewilligungSuchService from '../../services/BewilligungSuchService.js'
import BewilligungSearchColumns from './Form/Result/BewilligungSearchColumns.js'
import GlobalSearchColumns from './Form/Result/GlobalSearchColumns'
import GlobalSuchService from '../../services/GlobalSuchService'


export const suchenScopes = {
  betrieb: 'betrieb',
  gesuch: 'gesuch',
  bewilligung: 'bewilligung',
  verantwortlichep: 'verantwortlichep',
  pendenz: 'pendenz',
  notiz: 'notiz',
  kaution: 'kaution',
  global: 'global'
}

export const suchenNavigationLinkMap = new Map([
  [suchenScopes.global, 'Suchen'],
  [suchenScopes.betrieb, 'Suchen_Betrieb'],
  [suchenScopes.pendenz, 'Suchen_Pendenz'],
  [suchenScopes.verantwortlichep, 'Suchen_VerantwortlicheP'],
  [suchenScopes.notiz, 'Suchen_Notiz'],
  [suchenScopes.kaution, 'Suchen_Kaution'],
  [suchenScopes.gesuch, 'Suchen_Gesuch'],
  [suchenScopes.bewilligung, 'Suchen_Bewilligung']
])

export default class Suchen extends WebComponent {

  constructor() {
    super()
    this.betriebService = new BetriebService()
    this.pendenzService = new PendenzSuchService()
    this.notizService = new NotizSuchService()
    this.verantwortlichePService = new VerantwortlichePersonSuchService()
    this.kautionService = new KautionSuchService()
    this.gesuchService = new GesuchSuchService()
    this.globalService = new GlobalSuchService()
    this.bewilligungService = new BewilligungSuchService()
  }

  get css() {
    return css`
      vzavg-frontend-suchen {
        overflow: auto;
      }

      .tile-wrapper {
        display: flex;
        gap: 1em;
        align-items: center;
        margin-bottom: 1em;
        flex-wrap: wrap;
      }
    `
  }

  get translationFile() {
    return './views/Suchen/i18n.json'
  }

  get selectedScope() {
    return [...suchenNavigationLinkMap].find(([k, v]) => v === getNavigationHash())?.[0];
  }

  async connectedCallback() {
    super.connectedCallback()
    this.betriebUrl = await this.betriebService.getBackendListUrl()
    this.pendenzUrl = await this.pendenzService.getBackendListUrl()
    this.notizUrl = await this.notizService.getBackendListUrl()
    this.kautionUrl = await this.kautionService.getBackendListUrl()
    this.gesuchUrl = await this.gesuchService.getBackendListUrl()
    this.globalUrl = await this.globalService.getBackendListUrl()
    this.verantwortlichePUrl = await this.verantwortlichePService.getBackendListUrl()

    this.bewilligungUrl = await this.bewilligungService.getBackendListUrl()
    this._initTiles()
    this.scopeChanged = () => {
      this.render()
    }
    window.addEventListener(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, this.scopeChanged)
    this.render()
  }

  disconnectedCallback() {
    window.removeEventListener(SuchenNavigation.EVENT_KEYS.SCOPE_CHANGED, this.scopeChanged)
  }

  getTemplate() {
    if(getNavigationHash().includes('Suchen')) {
      return html`
      ${this.selectedScope === suchenScopes.global ? html`` : html`
          <vzavg-frontend-suchen-template></vzavg-frontend-suchen-template>
      `}
      <isceco-title text="${this.i18n.translate('suchen.header')}"></isceco-title>
      <vzavg-frontend-suchen-navigation .tiles='${this.tiles}'></vzavg-frontend-suchen-navigation>

      ${this._getSuchenFormContent()}
    `
    }
  }

  /**
   * @typedef Tile
   * @property {String} titleKey
   * @property {String} icon class
   * @property {String} scope
   * @property {Array} columns
   * @property {Object} service
   * @property {String} url
   *
   * @returns {Array<Tile>}
   */
  _initTiles() {
    this.tiles = [
      {
        titleKey: 'suchen.global',
        icon: 'globe',
        scope: suchenScopes.global,
        columns: GlobalSearchColumns.getColumns(),
        service: new VzavgListService(this.globalUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.global)
      },
      {
        titleKey: 'suchen.betrieb',
        icon: 'building',
        scope: suchenScopes.betrieb,
        columns: BetriebSearchColumns.getColumns(),
        service: new VzavgListService(this.betriebUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.betrieb)
      },
      {
        titleKey: 'suchen.gesuch',
        icon: 'file alternate',
        disabled: false,
        scope: suchenScopes.gesuch,
        columns: GesuchSearchColumns.getColumns(),
        service: new VzavgListService(this.gesuchUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.gesuch)
      },
      {
        titleKey: 'suchen.bewilligung',
        icon: 'file alternate',
        disabled: false,
        scope: suchenScopes.bewilligung,
        columns: BewilligungSearchColumns.getColumns(),
        service: new VzavgListService(this.bewilligungUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.bewilligung)
      },
      {
        titleKey: 'suchen.verantwP',
        icon: 'user',
        disabled: false,
        scope: suchenScopes.verantwortlichep,
        columns: VerantwortlichePSearchColumns.getColumns(),
        service: new VzavgListService(this.verantwortlichePUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.verantwortlichep)
      },
      {
        titleKey: 'suchen.kaution',
        icon: 'money bill alternate outline',
        disabled: false,
        scope: suchenScopes.kaution,
        columns: KautionSearchColumns.getColumns(),
        service: new VzavgListService(this.kautionUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.kaution)
      },
      {
        titleKey: 'suchen.pendenz',
        icon: 'check square',
        scope: suchenScopes.pendenz,
        columns: PendenzSearchColumns.getColumns(),
        service: new VzavgListService(this.pendenzUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.pendenz)
      },
      {
        titleKey: 'suchen.notiz',
        icon: 'check square',
        scope: suchenScopes.notiz,
        columns: NotizSearchColumns.getColumns(),
        service: new VzavgListService(this.notizUrl),
        url: suchenNavigationLinkMap.get(suchenScopes.notiz)
      }
    ]
  }

  _getSuchenFormContent() {
    if (!AuthorizationFilter.viewAllowed(getNavigationHash())) {
      navigate({to: ''})
      return html``
    }
    return new SuchenForm(this.tiles.find(t => t.scope === this.selectedScope));

  }
}
customElements.define('vzavg-frontend-suchen', Suchen)
