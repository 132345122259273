import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../../WebComponent.js';
import './StatistikInput'
import StatistikService from '../../../services/StatistikService'
import '@isceco/widget-library2/basic-elements/Form/Form'
import GesuchService from '../../../services/GesuchService'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import {noYesInternationalFlOptions} from '../Gesuch/Gesuch'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput'
import AuthorizationFilter from "../../Authorization/AuthorizationFilter";
import {geschaeftsVorfallNavigationLinks} from "../Geschaeftsvorfall";

export default class StatistikdatenVorjahre extends WebComponent {

  static AV_FORM_ID = 'av-form'
  static PV_FORM_ID = 'pv-form'
  static YEAR_INPUT_ID = 'year-input'

  get css() {
    return css`
      .button-wrapper {
        display: flex;
        gap: 1em;
        justify-content: flex-end;
        margin-top: 2em;
      }

      .title-wrapper {
        display: flex;
        gap: 1em;
        align-items: center;
        justify-content: space-between;
      }

      .betrieb-info {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 0.5em 1em;
      }

      .form-elements {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
        gap: 1em;
      }

      isceco-form, statistik-input {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 7;
      }

      .input-wrapper {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 7;
        align-items: center;
        text-align: center;
      }

      .input-wrapper label {
        margin-right: auto;
      }

      .year-nav-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .year {
        margin-left: 1em;
        margin-right: 1em;
        font-size: 1.2em;
      }

      .berichts-jahr-grid {
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
        column-gap: 1em;
        text-align: center;
        margin-top: 1em;
      }

      .berichts-jahr-row {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 7;
        align-items: center;
        text-align: center;
      }

      .save-cancel-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
      }

    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Statistikdaten/i18n.json'
  }

  get hasData() {
    return !!this.backendData && this.backendData.length > 0
  }

  get hasAV() {
    return !!this.gesuch.arbeitsVermittlung && this.gesuch.arbeitsVermittlung !== 'no'
  }

  get hasPV() {
    return !!this.gesuch.personalVerleih && this.gesuch.personalVerleih !== 'no'
  }

  get betriebsArt() {
    let betriebsArt = ""
    if (this.hasAV) {
      const arbeitsVermittlungOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.arbeitsVermittlung).name
      betriebsArt = `${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")} (${arbeitsVermittlungOption})`
    }

    if (this.hasPV) {
      const personalverleihOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.personalVerleih).name;
      const personalverleih = `${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")} (${personalverleihOption})`
      betriebsArt = betriebsArt.length ? `${betriebsArt} + ${personalverleih}` : personalverleih;
    }
    if (!this.hasPV && !this.hasAV) {
      betriebsArt = this.i18n.translate('geschaeftsvorfall.gesuch.noAVorPV.vorjahre')
    }
    return betriebsArt
  }

  constructor() {
    super()
    this.refYear = new Date().getFullYear()
    this.allYears = []
    this.editYear = null;
  }

  connectedCallback() {
    super.connectedCallback()
    this.statistikService = new StatistikService();
    this.gesuchService = new GesuchService()
    this.backendData = {}
    this.loadData()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
  }

  loadData() {
    const betrieb = window.betrieb$.getValue()
    if(this.editYear) {
      this._cancelEdit(this.editYear)
    }
    Promise.all([this.statistikService.list(`${betrieb.id}/vorjahre?refYear=${this.refYear}`), this.gesuchService.read(betrieb.gesuchId)]).then(([statistikData, gesuch]) => {
        this.gesuch = gesuch
        this.backendData = statistikData['vorjahre']
        this._initEmptyData()
        this.allYears = statistikData['allYears']
        this.refYear = this.backendData.at(-1)?.year ?? new Date().getFullYear()
        this.render()
      }
    )
  }

  _initEmptyData() {
    for(const yearData of this.backendData) {
      if (!yearData.arbeitsvermittlung && this.hasAV) {
        yearData.arbeitsvermittlung = this._emptyAv
      }
      if (!yearData.personalverleih && this.hasPV) {
        yearData.personalverleih = this._emptyPv
      }
    }
  }

  get _emptyAv() {
    return {
      anzahlSchweizer: null,
      anzahlSchweizerinnen: null,
      anzahlAuslaender: null,
      anzahlAuslaenderinnen: null,
      onlinePlacement: false
    }
  }

  get _emptyPv() {
    return {
      anzahlSchweizer: null,
      anzahlSchweizerinnen: null,
      anzahlAuslaender: null,
      anzahlAuslaenderinnen: null,
      geleisteteEinsatzstunden: null
    }
  }


  getTemplate() {
    const mostRecentYears = this.allYears.slice(Math.max(this.allYears.length - 5, 0))
    const betrieb = window.betrieb$.getValue()
    return html`
      <div class="wrapper">
        <div class="title-wrapper">
          <h1>${this.i18n.translate('geschaeftsvorfall.statistikdaten.header')}</h1>
          <isceco-button
            id="history"
            title="${this.i18n.translate('geschaeftsvorfall.statistikdaten.button.history')}"
            icon="history"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.statistikDatenHistory.hash, id: `${betrieb.gesuchId}/${betrieb.id}`})}"
          ></isceco-button>
        </div>
        ${!this.hasData ? this.renderBetriebInfo() : html``}
        ${this.hasData ? this.renderYearNavButtons() : html``}
        ${(this.hasAV || this.hasPV) && this.hasData ? html`
            <div class="berichts-jahr-grid">
              <div class="berichts-jahr-row">
                <span></span>
                ${this.backendData.map(data => mostRecentYears.includes(data.year) ? this._getEditButton(data.year) : html`<span></span>`)}
              </div>
              <div class="berichts-jahr-row">
              <h2 style="text-align: left">${this.i18n.translate('geschaeftsvorfall.statistikdaten.berichtsjahr')}</h2>
              ${this.backendData.map(data => html `<h2>${data.year}</h2>`)}
                <div class="berichts-jahr-row">
            </div>` :
            html``
        }
        ${this.hasAV && this.hasData ? this.renderAVStatistik() : html``}
        ${this.hasPV && this.hasData ? this.renderPVStatistik() : html``}
      </div>
    `
  }

  _getEditButton(year) {
    return html`
      <isceco-button
        id="edit-statistik-${year}-btn"
        title="${this.i18n.translate('geschaeftsvorfall.statistikdaten.edit')}"
        icon="edit"
        variant="primary"
        size="small"
        @click = "${_ => this._editYear(year)}"
        ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
      ></isceco-button>
      <div id="save-cancel-${year}-btns" style="display: none" class="save-cancel-wrapper">
        <isceco-button
          id="save-statistik-${year}-btn"
          title="${this.i18n.translate('geschaeftsvorfall.statistikdaten.speichern')}"
          icon="save"
          variant="primary"
          size="small"
          @click = "${_ => this._saveYear(year)}"
          ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
        ></isceco-button>
        <isceco-button
          id="cancel-statistik-${year}-btn"
          title="${this.i18n.translate('geschaeftsvorfall.statistikdaten.cancel')}"
          icon="cancel"
          variant="primary"
          size="small"
          @click = "${_ => this._cancelEdit(year)}"
          ?disabled="${!AuthorizationFilter.hasUserWriteAccess()}"
        ></isceco-button>
      <div>
      `
  }

  _editYear(year) {
    if(this.editYear) {
      this._cancelEdit(this.editYear)
    }
    this.editYear = year;
    const editButton = document.getElementById(`edit-statistik-${year}-btn`)
    editButton.style.display = 'none'
    const saveButton = document.getElementById(`save-cancel-${year}-btns`)
    saveButton.style.display = 'flex'

    const yearInputs = document.getElementsByClassName(`statistik-data-${year}`)
    for (const input of yearInputs) {
      if (input.nodeName === 'ISCECO-NUMBER-INPUT') {
        input.removeAttribute('readonly')
      } else if (input.nodeName === 'ISCECO-CHECKBOX') {
        input.removeAttribute('disabled')
      }
    }
  }

  _saveYear(year) {
    const yearData = this.backendData.find(data => data.year === year)
    this._updateBackendDataValuesForYear(year, yearData)
    this.statistikService.update({...yearData, id: yearData.id}, window.betrieb$.getValue().id)
      .then(() => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.statistikdaten.create.success.title'),
          this.i18n.translate('geschaeftsvorfall.statistikdaten.create.success.message'),
          'success'
        )
        this._disableEdit(year)
    })
  }

  _updateBackendDataValuesForYear(year, yearData) {
    if (this.hasAV) {
      const avKeys = Object.keys(yearData.arbeitsvermittlung ?? this._emptyAv)
      for (const key of avKeys) {
        yearData.arbeitsvermittlung[key] = document.getElementById(`av-${key}-${year}`).value
      }
    }
    if (this.hasPV) {
      const pvKeys = Object.keys(yearData.personalverleih ?? this._emptyPv)
      for (const key of pvKeys) {
        yearData.personalverleih[key] = document.getElementById(`pv-${key}-${year}`).value
      }
    }
  }

  _cancelEdit(year) {
    this._resetValues(year)
    this._disableEdit(year)
  }

  _resetValues(year) {
    const avAttributes = Object.entries(this.backendData.find(data => data.year === year).arbeitsvermittlung ?? this._emptyAv)
    for (const [key, value] of avAttributes) {
      this._resetValue(`av-${key}-${year}`, value)
    }

    const pvAttributes = Object.entries(this.backendData.find(data => data.year === year).personalverleih ?? this._emptyPv)
    for (const [key, value] of pvAttributes) {
      this._resetValue(`pv-${key}-${year}`, value)
    }
  }

  _resetValue(id, value) {
    const inputElement = document.getElementById(id)
    inputElement.value = value
    inputElement.shadowRoot.querySelector('input').value = value
  }

  _disableEdit(year) {
    this.editYear = null
    const editButton = document.getElementById(`edit-statistik-${year}-btn`)
    editButton.style.display = 'block'
    const saveButton = document.getElementById(`save-cancel-${year}-btns`)
    saveButton.style.display = 'none'

    const yearInputs = document.getElementsByClassName(`statistik-data-${year}`)
    for (const input of yearInputs) {
      if (input.nodeName === 'ISCECO-NUMBER-INPUT') {
        input.setAttribute('readonly', 'true')
      } else if (input.nodeName === 'ISCECO-CHECKBOX') {
        input.setAttribute('disabled', 'true')
      }
    }
  }


  getStatistikRow(value, key, year, changeFunction, disabled = false) {
    return html`
      <isceco-number-input id="${key}-${year}"
                           class="statistik-data-${year}"
                           value="${value}"
                           maximum-fraction-digits="${0}"
                           readonly
                           ?disabled="${disabled}"
                           @change="${changeFunction}"
      ></isceco-number-input>
    `
  }

  renderBetriebInfo() {
    return html`
      <div class="betrieb-info">
        <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.email')}</span>
        <span>${window.betrieb$.getValue().email}</span>
        <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.betriebsart')}</span>
        <span>${this.betriebsArt}</span>
      </div>
    `
  }

  renderYearNavButtons() {
    return html`
      <div class="year-nav-wrapper">
        <isceco-button variant="secondary" icon="angle left" size="small"
                       @click="${_ => this._backward()}"
                       ?disabled="${this.isBackwardDisabled}">
        </isceco-button>
        <div class="year"></div>
        <isceco-button variant="secondary" icon="angle right" size="small"
                       @click="${_ => this._forward()}"
                       ?disabled="${this.isForwardDisabled}">
        </isceco-button>
      </div>
    `
  }

  renderAVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")}</h2>

      <isceco-form>
        <div slot="form-elements" class="form-elements">
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung?.anzahlSchweizer, "av-anzahlSchweizer", data.year, _ => this._updateTotal(data.year, 'av')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung?.anzahlSchweizerinnen, "av-anzahlSchweizerinnen", data.year, _ => this._updateTotal(data.year, 'av')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung?.anzahlAuslaender, "av-anzahlAuslaender", data.year, _ => this._updateTotal(data.year, 'av')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung?.anzahlAuslaenderinnen, "av-anzahlAuslaenderinnen", data.year, _ => this._updateTotal(data.year, 'av')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVermittletePersonen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.arbeitsvermittlung?.total, "av-total", data.year, _ => {}, true))}
          </div>
          <div class="input-wrapper">
            <label style="max-width: 20em; text-align: left">${this.i18n.translate('geschaeftsvorfall.statistikdaten.av.online.placement')}</label>
            ${this.backendData.map(data => html`
              <isceco-checkbox id="av-onlinePlacement-${data.year}" disabled ?value="${data.arbeitsvermittlung?.onlinePlacement}" style="justify-self: center" class="statistik-data-${data.year}"></isceco-checkbox>`)}
          </div>
        </div>
      </isceco-form>
    `
  }

  renderPVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")}</h2>

      <isceco-form>
        <div slot="form-elements" class="form-elements">
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.anzahlSchweizer, "pv-anzahlSchweizer", data.year, _ => this._updateTotal(data.year, 'pv')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.anzahlSchweizerinnen, "pv-anzahlSchweizerinnen", data.year, _ => this._updateTotal(data.year, 'pv')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.anzahlAuslaender, "pv-anzahlAuslaender", data.year, _ => this._updateTotal(data.year, 'pv')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.anzahlAuslaenderinnen, "pv-anzahlAuslaenderinnen", data.year, _ => this._updateTotal(data.year, 'pv')))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVerliehenePersonen')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.total, "pv-total", data.year, _ => {}, true))}
          </div>
          <div class="input-wrapper">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.geleisteteEinsatzstunden')}</label>
            ${this.backendData.map(data => this.getStatistikRow(data.personalverleih?.geleisteteEinsatzstunden, "pv-geleisteteEinsatzstunden", data.year, _ => {}))}
          </div>
        </div>
      </isceco-form>
    `
  }

  _updateTotal(year, prefix) {
    const total = this._getInputNumber(`${prefix}-anzahlSchweizer-${year}`) + this._getInputNumber(`${prefix}-anzahlSchweizerinnen-${year}`) + this._getInputNumber(`${prefix}-anzahlAuslaender-${year}`) + this._getInputNumber(`${prefix}-anzahlAuslaenderinnen-${year}`)
    this._resetValue(`${prefix}-total-${year}`, total)
  }

  _getInputNumber(id) {
    return Number(document.getElementById(id).value) ?? 0
  }

  _backward() {
    const newRefYearIndex = this.allYears.indexOf(this.refYear) - 1
    this.refYear = this.allYears[newRefYearIndex]
    this.loadData()
  }

  get isBackwardDisabled() {
    return this.allYears.indexOf(this.refYear) < 5
  }

  _forward() {
    const newRefYearIndex = this.allYears.indexOf(this.refYear) +1
    this.refYear = this.allYears[newRefYearIndex]
    this.loadData()
  }

  get isForwardDisabled() {
    return this.allYears.indexOf(this.refYear) === this.allYears.length - 1
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-statistikdaten-vorjahre', StatistikdatenVorjahre)
