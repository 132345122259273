import {html, css} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import VzavgListService from '../../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../../components/DefaultMobileItem/DefaultMobileItem.js'
import KastColumns from "./KastColumns";
import KastService from "./KastService";
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";
import ExportService from "../../../../services/ExportService";


export default class Kast extends WebComponent {

  get translationFile() {
    return '../views/Administration/Stammdatenpflege/i18n.json'
  }

  constructor() {
    super()
    this.kastService = new KastService()
    this.exportService = new ExportService(`kast/export`)
  }

  connectedCallback() {
    super.connectedCallback()

    this.columns = [
      KastColumns.KAST_COLUMN(),
      KastColumns.BEZEICHNUNG_COLUMN(),
      KastColumns.STRASSE_COLUMN(),
      KastColumns.PLZ_COLUMN(),
      KastColumns.ORT_COLUMN(),
      KastColumns.KANTON_COLUMN(),
      KastColumns.TELEFON_COLUMN(),
      KastColumns.EMAIL_COLUMN(),
      KastColumns.WEB_COLUMN(),
      KastColumns.DETAIL_COLUMN({callback: this._rowActionMenu}),
      KastColumns.USER_COLUMN({callback: this._rowActionMenu})
    ]

    this.kastService.getBackendListUrl()
      .then(url => this.url = url)
      .then(_ => this.render())
  }

  get css() {
    return css`
      .download-button {
        display: flex;
        justify-content: end;
        margin-top: 1em;
      }

      .download-dialog-input {
        width: calc(100% - 40px);
        display: inline-block;
      }
    `
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('stammdatenpflege.kast.header')}">
        <isceco-button
          id="stammdatenpflege-add-kast-btn"
          title="${this.i18n.translate('stammdatenpflege.kast.create.button')}"
          icon="plus"
          variant="primary"
          size="small"
          @click="${_ => navigate({to: 'Administration_Stammdatenpflege_KastEdit'})}"
          ?disabled="${!AuthorizationFilter.hasSuperRole()}"
        ></isceco-button>
      </isceco-title>

      <isceco-list
        id="kast_list"
        .listService="${new VzavgListService(this.url)}"
        order="asc"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
      ></isceco-list>

      <isceco-button
        class="download-button" icon="cloud download alternate" text="${this.i18n.translate('stammdatenpflege.kast.download.button')}"
        @click="${() => this._openDownloadDialog()}"
      ></isceco-button>
      <isceco-dialog id="export-dialog"
                       hidden
                       header="${this.i18n.translate('stammdatenpflege.kast.download.header')}"
                       description="${this.i18n.translate('stammdatenpflege.kast.download.description')}"
                       confirm-button="${this.i18n.translate('stammdatenpflege.kast.download.confirm')}"
                       Cancel-button="${this.i18n.translate('stammdatenpflege.kast.download.cancel')}"
                       @submit="${e => this._download(e)}"
        >
            <isceco-text-input
            id="export-filename"
            class="download-dialog-input"
            maxlength="30"
          ></isceco-text-input>
          .xlsx
        </isceco-dialog>
    `
  }


  _rowActionMenu = (row, action) => {
    switch (action) {
      case 'details':
        navigate({to: 'Administration_Stammdatenpflege_KastEdit', id: row.id})
        break
      case 'users':
        navigate({to: 'Administration_Stammdatenpflege_Benutzer', id: row.id})
        break
      default:
        break
    }
  }

  _openDownloadDialog() {
    const dialog = document.querySelector(`#export-dialog`)
    dialog.querySelector(`#export-filename`).value = this._getFilenameSuggestion()
    dialog.removeAttribute('hidden')
  }

  _getFilenameSuggestion() {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
    return `${formattedDate}_KAST`
  }


  _download(e) {
    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector(`#export-filename`).getValue())
      this.exportService.getExport(filename, '')
        .then(response => response.blob())
        .then(blob => download(`${filename}.xlsx`, blob));
    }
    e.target.setAttribute('hidden', '')
  }

}
customElements.define('vzavg-frontend-stammdatenpflege-kast', Kast)
