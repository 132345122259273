import WebComponent from "../../../../WebComponent";
import {html} from "@isceco/widget-library2/external/lit";
import BenutzerColumns from "./BenutzerColumns";
import VzavgListService from "../../../../services/VzavgListService";
import DefaultMobileItem from "../../../../components/DefaultMobileItem/DefaultMobileItem";
import BenutzerServce from "./BenutzerServce";
import KastService from "../kast/KastService";
import {List} from "@isceco/widget-library2/basic-elements/List/List";
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";

export default class Benutzer extends WebComponent {

  get translationFile() {
    return '../views/Administration/Stammdatenpflege/i18n.json'
  }

  constructor() {
    super();
    this.kastService = new KastService()
    this.benutzerService = new BenutzerServce()
  }

  connectedCallback() {
    super.connectedCallback()

    this.columns = [
      BenutzerColumns.NAME_COLUMN(),
      BenutzerColumns.VORNANAME_COLUMN(),
      BenutzerColumns.POSITION_COLUMN(),
      BenutzerColumns.TELEFON_COLUMN(),
      BenutzerColumns.EMAIL_COLUMN(),
      BenutzerColumns.HIERARCHIE_COLUMN(),
      BenutzerColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})
    ]

    this.kastId = getNavigationId()
    this.kastName = ''

    const kastNamePromise = this.kastService.read(this.kastId)
      .then(kastResponse => this.kastName = kastResponse.kast)
      .catch(_ => {
        showAlert('', this.i18n.translate('stammdatenpflege.kast.edit.error'))
      })

    const listUrlPromise = this.benutzerService.getBackendListUrl()
      .then(url => this.url = url)

    Promise.all([kastNamePromise, listUrlPromise])
      .then(_ => this.render())
  }

  getTemplate() {
    return html`
      <div>
        <isceco-button
          id="kast-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('stammdatenpflege.back.to.list')}"
          @click="${_ => this._backToKastList()}"
        ></isceco-button>
      </div>
      <isceco-title text="${this.i18n.translate('stammdatenpflege.benutzerzuordung.header')} ${this.kastName}">
        <isceco-button
          id="stammdatenpflege-add-benutzer-btn"
          title="${this.i18n.translate('stammdatenpflege.benutzer.create.button')}"
          icon="plus"
          variant="primary"
          size="small"
          @click="${_ => navigate({to: 'Administration_Stammdatenpflege_BenutzerEdit', params: {kastId: this.kastId}})}"
          ?disabled="${!AuthorizationFilter.hasSuperRole()}"
        ></isceco-button>
      </isceco-title>

      <isceco-list
        id="benutzer-list"
        .listService="${new VzavgListService(this.url)}"
        update-event="${List.EVENT_KEYS.RELOAD_TRIGGERED}"
        order="asc"
        .defaultFilters="${{kastId: this.kastId}}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
      ></isceco-list>
    `
  }

  _backToKastList() {
    navigate({to: 'Administration_Stammdatenpflege_Kast'})
  }

  _rowActionMenu = (row, action) => {
    switch (action) {
      case 'edit':
        navigate({to: 'Administration_Stammdatenpflege_BenutzerEdit', id: row.id, params: {kastId: this.kastId}})
        break
      case 'delete':
        this._delete(row.id)
        break
      default:
        break
    }
  }

  _delete(benutzerId) {
    this.benutzerService.delete(benutzerId)
      .then(_ => {
        showAlert('', this.i18n.translate('stammdatenpflege.benutzer.delete.success.message'), 'success')
        send(List.EVENT_KEYS.RELOAD_TRIGGERED)
      });
  }
}
customElements.define('vzavg-frontend-stammdatenpflege-benutzer', Benutzer)
