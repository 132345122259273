import BaseService from '../BaseService.js'

export default class GesuchService extends BaseService {

  constructor() {
    super('gesuch')
  }

  async readBetriebChecklist(gesuchId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/betrieb/checklists`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async readGesuchChecklist(gesuchId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/checklists`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async readHsrsProposal(gesuchId, uid) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/adresse/${uid}/revisionstelle`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async readAdresseChecklist(gesuchId, adresseId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/adresse/${adresseId}/checklists`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async getCountByState(subResource, stateCode) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${subResource}/${stateCode}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async getBetriebsartVorlage(betriebId, personalVerlieh, arbeitsVermittlung, branchen, gemeinnuetzigkeit) {
    const params = []
    if (personalVerlieh) {
      params.push(`pv=${personalVerlieh}`)
    }
    if (arbeitsVermittlung) {
      params.push(`av=${arbeitsVermittlung}`)
    }
    if (branchen) {
      for (const branche of branchen) {
        params.push(`bc=${branche}`)
      }
    }
    if (gemeinnuetzigkeit) {
      params.push(`gnk=${gemeinnuetzigkeit}`)
    }

    await this.retrieveBackendUrl()

    let url = `${this.backendUrl}/betriebsart-vorschlag/${betriebId}`

    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    return this.checkError(
      fetch(url, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async getKautionSum(gesuchId) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/kaution/sum`, {
        method: 'GET',
        cache: 'reload',
        headers: {
          'Authorization': 'Bearer ' + window.keycloak.token
        }
      })
    )
  }

  async readKautionChecklist(gesuchId, kautionId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/kaution/${kautionId}/checklists`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }

  async deleteKaution(gesuchId, kautionId) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/kaution/${kautionId}`, {
        method: 'DELETE',
        cache: 'reload',
        headers: {
          'Authorization': 'Bearer ' + window.keycloak.token
        }
      })
    )
  }

  async getCurrentGesuchForBetrieb(betriebId) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/current-gesuch/${betriebId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader(),
      })
    )
  }

  async getPreviousGesuch(betriebId) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/previous-gesuch/${betriebId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader(),
      })
    )
  }

  async createOrGetDokument(commandSuffix = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${commandSuffix}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader(),
      }).then(this._resetChangesAndReturn)
    )
  }

}

