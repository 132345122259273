import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import Options from "../../../../common/Options";

export default class BetriebHistoryColumns {

  static getColumns(i18n) {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new StringColumn({
        key: 'kanton',
        text: 'geschaeftsvorfall.audit.kanton',
        sortable: false
      }),
      new StringColumn({
        key: 'land',
        text: 'geschaeftsvorfall.audit.land',
        sortable: false
      }),
      new GenericColumn({
        key: 'in_abklaerung',
        text: 'geschaeftsvorfall.audit.betrieb.bewilligungspflicht.in.clarification',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      }),
      new GenericColumn({
        key: 'bewilligungspflicht',
        text: 'geschaeftsvorfall.audit.betrieb.bewilligungspflicht',
        sortable: false,
        cell: new SelectCell({items: [
            { value: 'yes', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.bewilligungspflicht.yes')},
            { value: 'no', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.bewilligungspflicht.no')}
          ]})
      }),
      new GenericColumn({
        key: 'berufsverband',
        text: 'geschaeftsvorfall.audit.betrieb.berufsverband',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      }),
      new GenericColumn({
        key: 'za_verbot',
        text: 'geschaeftsvorfall.audit.betrieb.zaVerbot',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      }),
      new StringColumn({
        key: 'untern_register_id',
        text: 'geschaeftsvorfall.audit.uid',
        sortable: false
      }),
      new StringColumn({
        key: 'firmenbezeichnung',
        text: 'geschaeftsvorfall.audit.betrieb.firmenbezeichnung',
        sortable: false
      }),
      new StringColumn({
        key: 'adresszusatz',
        text: 'geschaeftsvorfall.audit.adresszusatz',
        sortable: false
      }),
      new StringColumn({
        key: 'strasse',
        text: 'geschaeftsvorfall.audit.strasse',
        sortable: false
      }),
      new StringColumn({
        key: 'plz',
        text: 'geschaeftsvorfall.audit.plz',
        sortable: false
      }),
      new StringColumn({
        key: 'ort',
        text: 'geschaeftsvorfall.audit.ort',
        sortable: false
      }),
      new StringColumn({
        key: 'telefon',
        text: 'geschaeftsvorfall.audit.telefon',
        sortable: false
      }),
      new StringColumn({
        key: 'email',
        text: 'geschaeftsvorfall.audit.email',
        sortable: false
      }),
      new StringColumn({
        key: 'web',
        text: 'geschaeftsvorfall.audit.web',
        sortable: false
      }),
      new StringColumn({
        key: 'marken',
        text: 'geschaeftsvorfall.audit.betrieb.marken',
        sortable: false
      }),
      new GenericColumn({
        key: 'portal_user',
        text: 'geschaeftsvorfall.audit.betrieb.portal.user',
        sortable: false,
        cell: new SelectCell({items: [
            { value: 'yes', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.portal.user.yes')},
            { value: 'no', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.portal.user.no')}
          ]})
      }),
      new StringColumn({
        key: 'anzahl_zweigniederlassungen',
        text: 'geschaeftsvorfall.audit.betrieb.anzahl.zweigniederlassungen',
        sortable: false
      }),
      new StringColumn({
        key: 'korrespondenz_sprache',
        text: 'geschaeftsvorfall.audit.sprache',
        sortable: false
      }),
      new StringColumn({
        key: 'dms_link',
        text: 'geschaeftsvorfall.audit.betrieb.dms',
        sortable: false
      })
    ]
  }
}
