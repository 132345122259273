import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../common/list/DefaultActionCell.js'

export default class CockpitGesucheColumns {

  static CREATION_TIME_COLUMN() {
    return new GenericColumn({
      key: 'creationTime',
      text: 'cockpit.gesuche.list.creationtime',
      sortable: true,
      cell: new StringCell()
    })
  }

  static KANTON_COLUMN() {
    return new GenericColumn({
      key: 'codeKanton',
      text: 'cockpit.gesuche.list.kanton',
      sortable: true,
      cell: new StringCell()
    })
  }

  static BETRIEB_COLUMN() {
    return new GenericColumn({
      key: 'firma',
      text: 'cockpit.gesuche.list.betrieb',
      sortable: true,
      cell: new StringCell()
    })
  }

  static AENDERUMGSGRUND_COLUMN() {
    return new GenericColumn({
      key: 'aenderungsgruende',
      text: 'cockpit.gesuche.list.aenderungsgrund',
      sortable: true,
      cell: new StringCell()
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      sortable: false,
      filter: null,
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'detail',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('cockpit.gesuche.list.actions.detail'),
            size: 'small'
          }
        ]
      })
    })
  }

}
