import WebComponent from "../../../../WebComponent";
import {html, css} from "@isceco/widget-library2/external/lit";
import KastService from "../kast/KastService";
import VzavgPattern from "../../../../common/VzavgPattern";
import BenutzerServce from "./BenutzerServce";
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";

export default class BenutzerEdit extends WebComponent {

  get translationFile() {
    return '../views/Administration/Stammdatenpflege/i18n.json'
  }

  constructor() {
    super();
    this.kastService = new KastService()
    this.benutzerService = new BenutzerServce()
  }

  connectedCallback() {
    super.connectedCallback()
    this.benutzerId = getNavigationId()
    this.kastId = getNavigationParams().get('kastId')
    this._getBenutzerAndRender()
  }

  get css() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        min-width: 16em;
        gap: 0.25em;
      }
    `
  }

  getTemplate() {
    return html`
      ${this.kastId ? html`
      <div>
        <isceco-button
          id="benutzer-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('stammdatenpflege.back.to.list')}"
          @click="${_ => this._backToBenutzerList()}"
        ></isceco-button>
        <isceco-dialog
          hidden
          id="benutzer-confirm-change-dialog"
          description="${this.i18n.translate('stammdatenpflege.confirm.changes.dialog.description')}"
          confirm-button="${this.i18n.translate('stammdatenpflege.confirm.changes.button.confirm')}"
          cancel-button="${this.i18n.translate('stammdatenpflege.confirm.changes.button.cancel')}"
          @submit="${e => this._closeConfirmChangeDialog(e)}"
        ></isceco-dialog>
      </div>` : html``}
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('stammdatenpflege.benutzer.header')}">
        <isceco-button
          id="benutzer-speichern"
          variant="primary"
          icon="save"
          title="${this.i18n.translate('stammdatenpflege.benutzer.create.button')}"
          @click="${_ => this._save()}"
          ?disabled="${!AuthorizationFilter.hasSuperRole()}"
        ></isceco-button>
      </isceco-title>
      <isceco-form id="benutzer-form">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>
    `
  }

  _renderFormFields() {
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-dropdown
            id="kastId"
            name="kastId"
            label="${this.i18n.translate('stammdatenpflege.kast')} *"
            value="${this.backendData.kastId}"
            .items="${this.kasts}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-dropdown>
          <isceco-text-input
            id="vorname"
            name="vorname"
            value="${this.backendData.vorname}"
            label="${this.i18n.translate('stammdatenpflege.benutzer.vorname')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="name"
            name="name"
            value="${this.backendData.name}"
            label="${this.i18n.translate('stammdatenpflege.benutzer.name')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="position"
            name="position"
            value="${this.backendData.position}"
            label="${this.i18n.translate('stammdatenpflege.benutzer.position')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-number-input
            id="hierarchiestufe"
            name="hierarchiestufe"
            value="${this.backendData.hierarchiestufe}"
            label="${this.i18n.translate('stammdatenpflege.benutzer.hierarchie')} *"
            @change="${e => this._updateValue(e)}"
            required
            maximum-fraction-digits=0
            min=1
            max=99
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-number-input>
          <isceco-text-input
            id="alteUserId"
            name="alteUserId"
            value="${this.backendData.alteUserId}"
            label="${this.i18n.translate('stammdatenpflege.benutzer.alteUserId')}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
        </div>
        <div class="flex-column">
          <isceco-text-input
            id="telefon"
            name="telefon"
            value="${this.backendData.telefon}"
            label="${this.i18n.translate('stammdatenpflege.kast.telefon')} *"
            pattern="${VzavgPattern.simpleTelefonPattern}"
            pattern-error-text="${this.i18n.translate('stammdatenpflege.kast.telefon.pattern.error')}"
            placeholder="+41"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="email"
            name="email"
            value="${this.backendData.email}"
            label="${this.i18n.translate('stammdatenpflege.kast.email')} *"
            pattern="${VzavgPattern.emailPattern}"
            pattern-error-text="${this.i18n.translate('stammdatenpflege.kast.email.pattern.error')}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
        </div>
      </div>
    `
  }

  _getBenutzerAndRender() {
    this.backendData = {}
    this.backendData.kastId = this.kastId

    const promises = []
    promises.push(this._fetchKasts().then(kasts => this.kasts = kasts))

    if (this.benutzerId) {
      promises.push(this.benutzerService.read(this.benutzerId)
        .then(benutzerResponse => {
          if (!isNoDataFound(benutzerResponse)) {
            this.backendData = benutzerResponse
          }
        })
        .catch(_ => {
          showAlert('', this.i18n.translate('stammdatenpflege.benutzer.edit.error'))
        }))
    }

    Promise.all(promises)
      .then(_ => this.render())

  }

  async _fetchKasts() {
    return this.kastService.list().then(kasts => {
      return kasts.map(kast => ({
        value: kast.id,
        name: kast.kast
      }));
    }).catch(_ => {
      showAlert('Kast-Service kast unavailable', 'Request to backend could not be sent', 'error')
      return []
    })
  }

  _backToBenutzerList() {
    if (this.hasChanges) {
      const dialog = document.getElementById('benutzer-confirm-change-dialog')
      dialog.hidden = false
    } else {
      navigate({to: 'Administration_Stammdatenpflege_Benutzer', id: this.kastId})
    }
  }

  _closeConfirmChangeDialog(e) {
    e.target.hidden = true;
    if (e.detail.confirmed) {
      navigate({to: 'Administration_Stammdatenpflege_Benutzer', id: this.kastId})
    }
  }

  _updateValue(event) {
    const key = event.target.id;
    if (this.backendData[key] !== event.detail.value) {
      this.hasChanges = true;
      this.backendData[key] = event.detail.value
    }
  }

  _save() {
    const form = document.getElementById('benutzer-form');
    if (!form.validate()) {
      return;
    }

    this._createOrUpdate(form.getValues());
  }

  _createOrUpdate(formData) {
    if (this.backendData.id) {
      formData.id = this.backendData.id
      this.benutzerService.update(formData)
        .then(_ => {
          showAlert(
            this.i18n.translate('stammdatenpflege.benutzer.update.success.title'),
            this.i18n.translate('stammdatenpflege.benutzer.update.success.message'),
            'success'
          );
          navigate({to: 'Administration_Stammdatenpflege_Benutzer', id: this.backendData.kastId})
        })
    } else {
      this.benutzerService.create(formData)
        .then(_ => {
          showAlert(
            this.i18n.translate('stammdatenpflege.benutzer.create.success.title'),
            this.i18n.translate('stammdatenpflege.benutzer.create.success.message'),
            'success'
          );
          navigate({to: 'Administration_Stammdatenpflege_Benutzer', id: this.backendData.kastId})
        })
    }
  }

}
customElements.define('vzavg-frontend-stammdatenpflege-benutzer-edit', BenutzerEdit)
