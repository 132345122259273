import FileInputService from '@isceco/widget-library2/basic-elements/FileInput/FileInputService.js'
import ConfigUtil from './ConfigUtil.js'

export default class VzavgFileInputService extends FileInputService {

  constructor(url, modalId, currentVersion) {
    super(url);
    this.modalId = modalId
    this.configUtil = new ConfigUtil()
    this.dokId = null
    this.dokIdVersion = null
    this.currentVersion = currentVersion
  }

  setUrl(url) {
    this.url = url
  }

  setDokId(dokId, dokIdVersion) {
    this.dokId = dokId
    this.dokIdVersion = dokIdVersion
  }

  async upload(file) {
    if(this.dokId !== null && this.modalId !== undefined && this.currentVersion === this.dokIdVersion) {
      this.dokId = null
      this.file = file
      const modalDialog = document.querySelector(this.modalId)
      modalDialog.removeAttribute("hidden")
      modalDialog.fileUploadCallBackObject = this
      return {
        json() {
          return Promise.resolve({'status': '200'})
        }
      }
    }

    await this.configUtil.updateToken();
    const form = new FormData();
    form.append('fileName', file.name);
    form.append('file', file);
    return fetch(this.url, this.getOptions(form));
  }

  async dialogCallBackUpload(alsNotizSpeichern) {
    await this.configUtil.updateToken();
    const form = new FormData();
    form.append('fileName', this.file.name);
    form.append('file', this.file);
    form.append('alsNotizSpeichern', alsNotizSpeichern);
    return fetch(this.url, this.getOptions(form));
  }

}
