import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog'
import WebComponent from '../../WebComponent.js'
import '../../components/Betrieb/BetriebInfo.js'
import './Navigation/GeschaeftsvorfallNavigation.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import Adresse from './Adresse/Adresse.js'
import AdresseEdit from './Adresse/AdresseEdit.js'
import BewilligungEdit from './Bewilligung/BewilligungEdit.js'
import Betrieb from './Betrieb/Betrieb.js'
import Gesuch from './Gesuch/Gesuch.js'
import Uebersicht from './Uebersicht/Uebersicht.js'
import VerantwortlichePerson from './VerantwortlichePerson/VerantwortlichePerson.js'
import VerantwortlichePersonEdit from './VerantwortlichePerson/VerantwortlichePersonEdit.js'
import Kaution from './Kaution/Kaution.js'
import Dokumente from './Dokumente/Dokumente.js'
import DokumenteEdit from './Dokumente/DokumenteEdit.js'
import Entscheid from './Entscheid/Entscheid.js'
import Bewilligung from './Bewilligung/Bewilligung.js'
import Statistikdaten from './Statistikdaten/Statistikdaten.js'
import Notiz from './Notiz/Notiz.js'
import NotizEdit from './Notiz/NotizEdit.js'
import Pendenz from './Pendenz/Pendenz.js'
import PendenzEdit from './Pendenz/PendenzEdit.js'
import History from './History/History.js'
import TechnischeInfos from './TechnischeInfos/TechnischeInfos.js'
import GesuchService from '../../services/GesuchService.js'
import KautionEdit from './Kaution/KautionEdit.js'
import BetriebService from "../../services/BetriebService.js"
import '../../components/CheckList/CheckListOk.js'
import AuditedViews from './Audit/AuditedViews.js'
import Audit from './Audit/Audit.js'


export const geschaeftsVorfallNavigationLinks = {
  uebersicht: {
    id: 'uebersicht',
    hash: 'Geschaeftsvorfall_Uebersicht'
  },
  betrieb: {
    id: 'betrieb',
    hash: 'Geschaeftsvorfall_Betrieb'
  },
  betriebHistory: {
    id: 'betrieb-history',
    hash: 'Geschaeftsvorfall_BetriebHistory'
  },
  gesuch: {
    id: 'gesuch',
    hash: 'Geschaeftsvorfall_Gesuch'
  },
  gesuchHistory: {
    id: 'gesuch-history',
    hash: 'Geschaeftsvorfall_GesuchHistory'
  },
  adresse: {
    id: 'adresse',
    hash: 'Geschaeftsvorfall_Adresse'
  },
  adresseEdit: {
    id: 'adresse-edit',
    hash: 'Geschaeftsvorfall_AdresseEdit'
  },
  adresseHistory: {
    id: 'adresse-history',
    hash: 'Geschaeftsvorfall_AdresseHistory'
  },
  verantwortlichePerson: {
    id: 'verantwortliche-person',
    hash: 'Geschaeftsvorfall_VerantwortlichePerson'
  },
  verantwortlichePersonEdit: {
    id: 'verantwortliche-person-edit',
    hash: 'Geschaeftsvorfall_VerantwortlichePersonEdit'
  },
  verantwortlichePersonHistory: {
    id: 'verantwortliche-person-history',
    hash: 'Geschaeftsvorfall_VerantwortlichePersonHistory'
  },
  kaution: {
    id: 'kaution',
    hash: 'Geschaeftsvorfall_Kaution'
  },
  kautionEdit: {
    id: 'kaution-edit',
    hash: 'Geschaeftsvorfall_KautionEdit'
  },
  kautionHistory: {
    id: 'kaution-history',
    hash: 'Geschaeftsvorfall_KautionHistory'
  },
  dokumente: {
    id: 'dokumente',
    hash: 'Geschaeftsvorfall_Dokumente'
  },
  dokumenteEdit: {
    id: 'dokumente-edit',
    hash: 'Geschaeftsvorfall_DokumenteEdit'
  },
  entscheid: {
    id: 'entscheid',
    hash: 'Geschaeftsvorfall_Entscheid'
  },
  separation: {
    id: 'separation',
    hash: 'Geschaeftsvorfall_Separation'
  },
  bewilligung: {
    id: 'bewilligung',
    hash: 'Geschaeftsvorfall_Bewilligung'
  },
  bewilligungEdit: {
    id: 'bewilligungEdit',
    hash: 'Geschaeftsvorfall_BewilligungEdit'
  },
  statistikDaten: {
    id: 'statistikdaten',
    hash: 'Geschaeftsvorfall_Statistikdaten'
  },
  statistikDatenHistory: {
    id: 'statistikdaten-history',
    hash: 'Geschaeftsvorfall_StatistikdatenHistory'
  },
  notiz: {
    id: 'notiz',
    hash: 'Geschaeftsvorfall_Notiz'
  },
  notizEdit: {
    id: 'notiz-edit',
    hash: 'Geschaeftsvorfall_NotizEdit'
  },
  pendenz: {
    id: 'pendenz',
    hash: 'Geschaeftsvorfall_Pendenz'
  },
  pendenzEdit: {
    id: 'pendenz-edit',
    hash: 'Geschaeftsvorfall_PendenzEdit'
  },
  history: {
    id: 'history',
    hash: 'Geschaeftsvorfall_History'
  },
  techInfos: {
    id: 'technische-infos',
    hash: 'Geschaeftsvorfall_TechnischeInfos'
  },
  documentViewer: {
    id: 'document-viewer',
    hash: 'Geschaeftsvorfall_DocumentViewer'
  },
  snapshots: {
    id: 'snapshots',
    hash: 'Geschaeftsvorfall_Snapshots'
  },
}

export const geschaeftsVorfallNavigationLinksHelper = {
  next: () => {
    const activeNavItems = Array.from(document.querySelector("vzavg-vertical-tab-navigation").shadowRoot.querySelectorAll("nav div.desktop a:not(.disabled)"))
    const activeNavItemIdx = activeNavItems.findIndex(el => el.classList.contains('active'))
    const nextNavItem = activeNavItemIdx === activeNavItems.length - 1 ? activeNavItems[activeNavItemIdx] : activeNavItems[activeNavItemIdx + 1]
    const linkId = nextNavItem.dataset.linkId

    for (const [key, value] of Object.entries(geschaeftsVorfallNavigationLinks)) {
      if (value.id === linkId) {
        return geschaeftsVorfallNavigationLinks[key]
      }
    }

    return ""
  },
  previous: () => {
    const activeNavItems = Array.from(document.querySelector("vzavg-vertical-tab-navigation").shadowRoot.querySelectorAll("nav div.desktop a:not(.disabled)"))
    const activeNavItemIdx = activeNavItems.findIndex(el => el.classList.contains('active'))
    const previousNavItem = activeNavItemIdx === 0 ? activeNavItems[activeNavItemIdx] : activeNavItems[activeNavItemIdx - 1]
    const linkId = previousNavItem.dataset.linkId

    for (const [key, value] of Object.entries(geschaeftsVorfallNavigationLinks)) {
      if (value.id === linkId) {
        return geschaeftsVorfallNavigationLinks[key]
      }
    }

    return ""
  }
}

export default class Geschaeftsvorfall extends WebComponent {

  constructor() {
    super()
    this.isClosedForKanton = false;
    this.isClosedForSeco = false;
    this.hasOpenSecoGesuch = false;
    this.hasOpenKantonGesuch = false;
  }

  get css() {
    return css`
      vzavg-frontend-geschaeftsvorfall {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      div {
        text-align: left;
      }
      .info-text-wrapper {
        background-color: var(--isceco-color-yellow-100);
        padding: 0.5em;
        margin-top: 0.5em;
        color: var(--isceco-color-yellow-700);
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/i18n.json'
  }

  connectedCallback() {
    window.hasChanges = false
    super.connectedCallback()
    document.addEventListener('beforeunload', e => this._confirmLeave(e))
    this.gesuchId = getNavigationId()
    // this is the initial case when we create a new gesuch, the gesuch is realy created in tab Betrieb with the save button
    if(isNewGesuch()) {
      const navigationParams = getNavigationParams()
      const betriebId = navigationParams.get('betriebId')
      if(betriebId) {
        this.service = new BetriebService()
        this.service.read(betriebId)
          .then(betrieb => {
            window.betrieb$.setValue(betrieb)
            sessionStorage.setItem('betriebId', betriebId)
            this.render()
          })
      } else {
        this.render()
      }
      return
    }

    this.service = new GesuchService()
    Promise.all([
      this.service.read(this.gesuchId, 'betrieb'),
      this.service.read(this.gesuchId),
      this.service.createOrGetDokument(this.gesuchId+'/dokument/geschaeftsvorfall/detail')])
        .then(([betrieb, gesuch, dokumentId]) => {
          if (!isNoDataFound(betrieb)) {
            window.betrieb$.setValue(betrieb)
          } else {
            window.betrieb$.setValue(null)
          }

          this.isClosedForKanton = gesuch.closedForKanton === undefined ? false : gesuch.closedForKanton
          this.isClosedForSeco = gesuch.closedForSeco === undefined ? false : gesuch.closedForSeco
          this.hasOpenSecoGesuch = !!gesuch.hasOpenSecoGesuch
          this.hasOpenKantonGesuch = !!gesuch.hasOpenKantonGesuch
          this.hasAenderungsgesuch = !!gesuch.hasAenderungsgesuch

          if(dokumentId > 0) {
            // calculate dokument status
            this.service.read(this.gesuchId, `dokument/geschaeftsvorfall/status/${dokumentId}`).then(_ => {
              this.render()
            })
          } else {
            this.render()
          }
        })

    // set default view
    if (getNavigationHash() === 'Geschaeftsvorfall') {
      navigate({to: geschaeftsVorfallNavigationLinks.uebersicht.hash})
    }
  }

  _getInfoText() {
    if(this.hasOpenSecoGesuch && AuthorizationFilter.hasKantonRole()) {
      return this.i18n.translate('geschaeftsvorfall.kanton.gesuch.info')
    }
    if(this.hasOpenKantonGesuch && AuthorizationFilter.hasSecoRole()) {
      return this.i18n.translate('geschaeftsvorfall.seco.gesuch.info')
    }
    return ''
  }

  disconnectedCallback() {
    window.betrieb$.setValue(null)
    document.removeEventListener('beforeunload', e => this._confirmLeave(e))
  }

  getTemplate() {
    return html`
      <vzavg-betrieb-info></vzavg-betrieb-info>
      ${this._getInfoText() !== "" ? html`<div class="info-text-wrapper">${this._getInfoText()}</div>`: html``}
      <div style="display: flex; overflow: auto">
        <div style="flex-grow: 0; margin-top: 30px;">
          <vzavg-frontend-geschaeftsvorfall-navigation></vzavg-frontend-geschaeftsvorfall-navigation>
        </div>
        <div id="geschaeftsvorfallContent" style="flex-grow: 1; margin-top: 30px; margin-left: 25px;">
          ${this.getContent()}
        </div>
        <isceco-dialog
          id="save-dialog"
          hidden
          header="${this.i18n.translate('dialog.leave.confirm.header')}"
          description="${this.i18n.translate('dialog.leave.confirm.description')}"
          confirm-button="${this.i18n.translate('geschaeftsvorfall.dialog.confirm')}"
          cancel-button="${this.i18n.translate('geschaeftsvorfall.dialog.cancel')}"
          @submit="${e => this._closeConfirmDialog(e)}"
        ></isceco-dialog>
      </div>
    `
  }

  getContent() {  //NOSONAR
    // check the rights and redirect to default view in verwaltung if not ok
    if (!AuthorizationFilter.viewAllowed(getNavigationHash())) {
      navigate({to: ''})
      return html``
    }

    switch (getNavigationHash()) {
      case geschaeftsVorfallNavigationLinks.uebersicht.hash:
        return new Uebersicht()
      case geschaeftsVorfallNavigationLinks.betrieb.hash:
        return new Betrieb(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.betriebHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key ===  geschaeftsVorfallNavigationLinks.betriebHistory.id))
      case geschaeftsVorfallNavigationLinks.gesuch.hash:
        return new Gesuch(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.gesuchHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key ===  geschaeftsVorfallNavigationLinks.gesuchHistory.id))
      case geschaeftsVorfallNavigationLinks.adresse.hash:
        return new Adresse(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.adresseEdit.hash:
        return new AdresseEdit(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.adresseHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key ===  geschaeftsVorfallNavigationLinks.adresseHistory.id))
      case geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash:
        return new VerantwortlichePerson(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash:
        return new VerantwortlichePersonEdit(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.verantwortlichePersonHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key ===  geschaeftsVorfallNavigationLinks.verantwortlichePersonHistory.id))
      case geschaeftsVorfallNavigationLinks.kaution.hash:
        return new Kaution(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.kautionEdit.hash:
        return new KautionEdit(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.kautionHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key === geschaeftsVorfallNavigationLinks.kautionHistory.id))
      case geschaeftsVorfallNavigationLinks.dokumente.hash:
        return new Dokumente(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.dokumenteEdit.hash:
        return new DokumenteEdit(this.isClosedForKanton, this.isClosedForSeco)
      case geschaeftsVorfallNavigationLinks.entscheid.hash:
        return new Entscheid()
      case geschaeftsVorfallNavigationLinks.bewilligung.hash:
        return new Bewilligung()
      case geschaeftsVorfallNavigationLinks.bewilligungEdit.hash:
        return new BewilligungEdit()
      case geschaeftsVorfallNavigationLinks.statistikDaten.hash:
        return new Statistikdaten()
      case geschaeftsVorfallNavigationLinks.statistikDatenHistory.hash:
        return new Audit(AuditedViews.getViews().find(v => v.key ===  geschaeftsVorfallNavigationLinks.statistikDatenHistory.id))
      case geschaeftsVorfallNavigationLinks.notiz.hash:
        return new Notiz()
      case geschaeftsVorfallNavigationLinks.notizEdit.hash:
        return new NotizEdit()
      case geschaeftsVorfallNavigationLinks.pendenz.hash:
        return new Pendenz()
      case geschaeftsVorfallNavigationLinks.pendenzEdit.hash:
        return new PendenzEdit()
      case geschaeftsVorfallNavigationLinks.history.hash:
        return new History()
      case geschaeftsVorfallNavigationLinks.techInfos.hash:
        return new TechnischeInfos()
      default:
        break
    }
    // default
    return html`Default`
  }

  _confirmLeave(e) {
    const dialog = document.getElementById('save-dialog')
    dialog.hidden = false
    this.leavingTo = e.detail.leavingTo
  }

  async _closeConfirmDialog(event) {
    event.target.hidden = true
    if (!event.detail.confirmed || (event.detail.confirmed && await this._saveContent())) {
      window.hasChanges = false
      navigate(this.leavingTo)
    }
  }

  async _saveContent() {
    const nodesWithSave = Array.from(document.getElementById('geschaeftsvorfallContent').childNodes)
      .filter(elem => elem.save instanceof Function)
    if (nodesWithSave.length === 0) {
      return Promise.resolve(true);
    } else if (nodesWithSave.length === 1) {
      return nodesWithSave[0].save()
    }
    console.error('multiple elements with save found!!!') // NOSONAR
    return Promise.resolve(false)
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall', Geschaeftsvorfall)
