import {html, LitElement} from '@isceco/widget-library2/external/lit'
import ModalDialog from '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog'
import DropdownMultiselectGroupCss from './DropdownMultiselectGroupCss.js'

// Do not take it as it is in the Widget lib, it would be better if the items would be an object with the groups etc but overkill for our case
export default class DropdownMultiselectGroup extends LitElement {

  static get styles() {
    return [DropdownMultiselectGroupCss]
  }

  static get properties() {
    return {
      disabled: {type: Boolean},
      inline: {type: Boolean},
      label: {type: String},
      helpText: {attribute: 'help-text', type: String | Function},
      placeholder: {type: String},
      placeholderEmpty: {attribute: 'placeholder-empty', type: String},
      required: {type: Boolean},
      i18n: {type: Array},
      items: {type: Array},
      readonly: {type: Boolean},
      notFocusable: {attribute: 'not-focusable', type: Boolean},
      value: {type: Array},
      title: {type: String},
      group: {type: Object}
    }
  }

  constructor() {
    super()
    this.optionsOpened = false
    this.message = ''
    this.notFocusable = false
    this.value = []
    this.valueChanged = false
    this.focusedValue = null
    this.itemsFiltered = []
  }

  connectedCallback() {
    super.connectedCallback()
    if (!this.value) {
      this.value = []
    }
    this.value.forEach(key => {
      const found = this.items?.filter(item => item.value === key)
      if (found.length > 0) {
        found[0].selected = true
      }
    })

    const groupItem = {
      name: this.i18n.translate(this.group.nameKey),
      selected: false,
      value: 'group1'
    }
    this.focusedValue = ''
    this.itemsFiltered = [groupItem, ...this.items]

    this.group.selected = this._allGroupMembersAreSelected();
  }

  updated(changedProperties) {
    const updatedProperties = changedProperties.has('items') ||
      changedProperties.has('required') || changedProperties.has('value')
    const shouldCheck = this.valueChanged || this._getSelected().length > 0
    if (shouldCheck && updatedProperties) {
      this.requestUpdate()
      this.updateComplete.then(_ => this.validate())
    }
  }

  render() {
    return this.readonly ? this.renderReadonly() : this.renderComponent()
  }

  renderComponent() {
    return html`
      <style>
        @import '${iscecoWidgetLibrary.iconCss}';
      </style>
      <div class="${this.inline ? 'inline' : ''}">
        <div class="label-wrapper" @click="${() => this._toggleSelect()}">
          <label>
            ${this.label}
          </label>
          ${this.renderInfo()}
        </div>
        <div class="select-container">
          <div class="dropdown ${this.optionsOpened ? 'open' : 'closed'} ${this.disabled ? 'disabled' : ''}">
            <div class="field"
                 @keydown="${e => this._handleKeyDown(e)}"
                 @click="${() => this._toggleSelect()}">
              <div class="field-content">
                ${this._renderSelected()}
                <input
                  type="text"
                  class="filter"
                  title="${this.title ?? ' '}"
                  ?disabled="${this.disabled}"
                  @keyup="${e => this._handleKeyup(e)}"
                />
              </div>
              <div class="field-icon">
                <i class="icon angle down"></i>
              </div>
            </div>
            <div class="options">
              <ul class="list">${this._renderSelectOptions()}</ul>
            </div>
          </div>
        </div>
      </div>
      <p id="message">
        <i class="exclamation triangle icon"></i>
        ${this.message}
      </p>
    `
  }

  renderReadonly() {
    const selectedItems = this._getSelected()
    let name = selectedItems.map(x => this.i18n ? this.i18n.translate(x.name) : x.name).join(' | ')

    // Wenn kein Wert ausgewählt ist, wird der Platzhalter-Text angezeigt
    if (isEmpty(name) && this.placeholderEmpty) {
      name = this.placeholderEmpty
    }

    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <div class="readonly ${this.inline ? 'inline' : ''}">
        <div class="label-wrapper">
          <span>${this.label}</span>
          ${this.renderInfo()}
        </div>
        <p>
          ${name}<br/>
        </p>
      </div>
    `
  }

  renderInfo() {
    if (isEmpty(this.helpText)) {
      return html``
    } else {
      const helpText = this.helpText instanceof Function ? this.helpText() : this.helpText
      return html`
        <isceco-popup direction="right">
          <i slot="wrapper" class="info circle icon"></i>
          <div slot="content">${helpText}</div>
        </isceco-popup>
      `
    }
  }

  _hide = e => {
    if (!e.composedPath().includes(this)) {
      this._closeOptions()
    }
  }

  _closeOptions() {
    if (!this.optionsOpened) {
      return
    }
    this.shadowRoot.querySelector('input.filter').value = ''
    this.optionsOpened = false
    window.removeEventListener('click', this._hide)
    window.removeEventListener('scroll', this._setDropdownPosition)
    window.removeEventListener('resize', this._setDropdownPosition)
    window.addEventListener(ModalDialog.EVENT_KEYS.SCROLL, this._setDropdownPosition)
    this.requestUpdate()
  }

  _openOptions() {
    if (this.optionsOpened) {
      return
    }
    this._focusInput()
    this.optionsOpened = true
    this.focusedValue = ''
    this.itemsFiltered = this.items
    this._removeFocus()
    this._setDropdownPosition()
    window.addEventListener('click', this._hide)
    window.addEventListener('scroll', this._setDropdownPosition)
    window.addEventListener('resize', this._setDropdownPosition)
    window.removeEventListener(ModalDialog.EVENT_KEYS.SCROLL, this._setDropdownPosition)
    this.requestUpdate()
  }

  _toggleSelect() {
    if (!this.disabled) {
      if (this.optionsOpened) {
        this._closeOptions()
      } else {
        this._openOptions()
      }
    }
  }

  // avoid options going off-screen at the bottom
  _calculatePosition = (fieldRect, items) => {
    const nItems = this.itemsFiltered ? this.itemsFiltered.length : 0
    const itemHeight = items.getBoundingClientRect().height > 0 ? items.getBoundingClientRect().height : Math.min(300, nItems * 50)
    const maxYPos = window.innerHeight - itemHeight
    return (fieldRect.bottom < window.innerHeight && fieldRect.bottom > maxYPos) ? maxYPos : fieldRect.bottom
  }

  _setDropdownPosition = () => {
    const fieldRect = this.shadowRoot.querySelector('.field').getBoundingClientRect()
    const items = this.shadowRoot.querySelector('.list')
    items.style.width = `${fieldRect.width}px`
    items.style.top = `${this._calculatePosition(fieldRect, items)}px`
    items.style.left = `${fieldRect.left}px`
  }

  _renderSelected() {
    let selectedItems = this._getSelected()

    if (selectedItems.length <= 0) {
      return html`
        <div class="placeholder">${this.placeholder}</div>`
    }

    if (this._allGroupMembersAreSelected() && selectedItems.length === this.group.members.length) {
      this._removeElementsOfTheGroup(selectedItems)
      const groupItem = {name: this.group.nameKey, value: this.group.name}
      selectedItems = [groupItem, ...selectedItems]
    }

    return html`
      ${(selectedItems.map(item => {
        const name = this._getOptionName(item.name)
        const remove = this._renderRemoveIcon(item)
        return html`
          <div class="tag" contenteditable="false">
            <div class="tag-text">${name}</div>
            ${remove}
          </div>
        `
      }))}`
  }

  _removeElementsOfTheGroup(selectedItems) {
    for (const member of this.group.members) {
      for (let i = 0; i < selectedItems.length; i++) {
        const selectedValue = selectedItems[i].value
        if (member === selectedValue) {
          selectedItems.splice(i, 1)
        }
      }
    }
  }

  _renderRemoveIcon(item) {
    return item.disabled ? '' : html`
      <div @click="${e => this._setSelection(e, item)}" class="tag-remove-button">
        <i class="icon x"></i>
      </div>
    `
  }

  _renderSelectOptions() {
    const options = []
    const name = this.i18n.translate(this.group.nameKey)

    if (this.group) {
      options.push(html`
        <li
          title="${name}"
          value="${name}"
          @mouseenter="${() => {
            this.focusedValue = name
            this._focusValue()
          }}"
        >
          <isceco-checkbox
            label="${name}"
            ?value="${this.group.selected}"
            ?disabled="${this.group.disabled}"
            @change="${e => {
              this._setSelection(e, this.group)
              this._focusInput()
              this._handleGroupChange(e)
            }}"
            @click="${e => e.stopPropagation()}"
            not-focusable="true"
            style="padding: 0.5em 0"
          ></isceco-checkbox>
        </li>
      `)
    }

    this.itemsFiltered?.forEach(item => {
      const groupClass = this._isMemberOfGroup(item.value) ? 'group-member' : ''
      const itemName = this._getOptionName(item.name)
      options.push(html`
        <li
          title="${itemName}"
          value="${item.value}"
          @mouseenter="${() => {
            this.focusedValue = item.value
            this._focusValue()
          }}"
          class="${groupClass}"
        >
          <isceco-checkbox
            label="${itemName}"
            ?value="${item.selected}"
            ?disabled="${item.disabled}"
            @change="${e => {
              this._setSelection(e, item)
              this._focusInput()
            }}"
            @click="${e => e.stopPropagation()}"
            not-focusable="true"
          ></isceco-checkbox>
        </li>
      `)
    })
    return options
  }

  _handleGroupChange(e) {
    if (e.detail.value) {
      this._selectGroupMembers()
    } else {
      this._deselectGroupMembers()
    }
    this._change()
  }

  _selectGroupMembers() {
    this._updateGroupMembersValue(true)
  }

  _deselectGroupMembers() {
    this._updateGroupMembersValue(false)
    this._change()
  }

  /**
   * @param {boolean} value
   * @private
   */
  _updateGroupMembersValue(value) {
    for (const item of this.items) {
      if (this.group.members.includes(item.value)) {
        item.selected = value
      }
    }
    for (const itemFiltered of this.itemsFiltered) {
      if (this.group.members.includes(itemFiltered.value)) {
        itemFiltered.selected = value
      }
    }
    this._updateValueFromItem()
  }

  _getOptionName(key) {
    return this.i18n ? this.i18n.translate(key) : key
  }

  _setSelection(event, option) {
    event.stopPropagation()
    option.selected = event.detail?.value

    if (option.name === 'geschaeftsvorfall.gesuch.branchen.group'){
      if (this._allGroupMembersAreSelected()){
        this._deselectGroupMembers()
        this.group.selected = false
      } else {
        this._selectGroupMembers()
        this.group.selected = true
      }
    }
    // update this.value from this.items
    this._updateValueFromItem()

    // when deselecting one member, the group is deselected
    if (this._isMemberOfGroup(option.value)) {
      if (!option.selected) {
        this.group.selected = false
      }

      if (option.selected && this._allGroupMembersAreSelected()) {
        this.group.selected = true
      }
    }

    this._change()
  }

  _updateValueFromItem() {
    this.value.length = 0
    this.items?.filter(item => item.selected)
      .map(item => item.value)
      .forEach(value => this.value.push(value))
  }

  /**
   * @param element {string}
   * @returns {boolean} true if the element is part of the members of group
   * @private
   */
  _isMemberOfGroup(element) {
    return this.group.members.includes(element)
  }

  /**
   * @returns {boolean} true if all the members are in this.value
   * @private
   */
  _allGroupMembersAreSelected() {
    for (const member of this.group.members) {
      if (!this.value.includes(member)) {
        return false
      }
    }
    return true
  }

  _getSelected() {
    this.items?.map(item => item.selected = (item.selected || this.value.includes(item.value)))
    return this.items?.filter(item => item.selected) ?? []
  }

  validate = () => {
    if (this.readonly) {
      return true
    }

    const valid = !this.required || this._getSelected().length > 0
    if (valid) {
      this.classList.remove('error')
      this.message = ''
    } else {
      this.classList.add('error')
      this.message = translateText(this._translations(), 'empty')
    }
    this.requestUpdate()
    return valid
  }

  getValue = () => {
    const value = this._getSelected().map(o => o.value)
    if (value.length === 0) {
      return undefined
    } else {
      return value
    }
  }

  hasChanges = () => this.valueChanged

  _change() {
    const valid = this.validate()
    this.valueChanged = true

    // part of LitElement
    this.requestUpdate()
    send('change', {
      valid: valid,
      value: this.getValue()
    }, this)
  }

  _translations() {
    return {
      de: {
        empty: 'Wert darf nicht leer gelassen werden.'
      },
      fr: {
        empty: 'La valeur ne doit pas être laissée vide.'
      },
      it: {
        empty: 'Il valore non deve essere lasciato vuoto.'
      },
      en: {
        empty: 'Value must not be left empty.'
      }
    }
  }

  // focus the (hidden) text input, so the user can filter the options
  _focusInput() {
    this.shadowRoot.querySelector('input.filter').focus()
  }

  // open / close dropdown
  _handleKeyDown(e) {
    switch (e.code) {
      case 'Escape':
        e.preventDefault()
        this._closeOptions()
        return
      case 'Tab':
        this._closeOptions()
        return
      case 'Enter':
        e.preventDefault()
        this._toggleSelect()
        return
      case 'Space':
        e.preventDefault()
        this._setFocusedValue()
        break
      case 'ArrowDown':
        this._updateFocus(this._nextValue)
        break
      case 'ArrowUp':
        this._updateFocus(this._previousValue)
        break
      default:
        break
    }

    // any key other than Escape, Tab and Enter opens the dropdown
    if (!this.optionsOpened) {
      e.preventDefault()
      this._openOptions()
    }
  }

  // if toggle = true -> click checkbox
  // else enable checkbox, or do nothing if already enabled
  _setFocusedValue = () => {
    if (!this.optionsOpened) {
      return
    }
    this.shadowRoot.querySelector(`li[value="${this.focusedValue}"] > isceco-checkbox`)?.toggle()
  }

  // update filtered items list
  _handleKeyup(e) {
    if (['Space', 'Escape', 'Tab', 'Enter', 'ArrowDown', 'ArrowUp'].includes(e.code)) {
      return
    }
    this.itemsFiltered = this.items
      .filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase()))
    this.focusedValue = ''
    this._removeFocus()
    this.requestUpdate()
  }

  // update the focused item, func is either _nextValue or _previousValue
  _updateFocus = func => {
    if (this.optionsOpened) {
      this.focusedValue = func()
      this._focusValue(true)
    }
  }

  // get index in the current item list of the focused item
  _indexOfValue = () => this.itemsFiltered?.findIndex(item => item.value === this.focusedValue)

  // get the value of the next item
  _nextValue = () => this.itemsFiltered?.[Math.min(this._indexOfValue() + 1, this.itemsFiltered.length - 1)].value

  // get the value of the previous item
  _previousValue = () => this.itemsFiltered?.[Math.max(this._indexOfValue(this.focusedValue) - 1, 0)].value

  // remove focus
  _removeFocus = () => {
    this.shadowRoot.querySelectorAll(`li.focus`).forEach(element => element.classList.remove('focus'))
  }

  // redraw the focus: unfocus previous, focus new, scroll to focused
  _focusValue = () => {
    this._removeFocus()
    let element
    if (isEmpty(this.focusedValue)) {
      if (this.placeholder) {
        element = this.shadowRoot.querySelector(`li[value]`)
      }
    } else {
      element = this.shadowRoot.querySelector(`li[value="${this.focusedValue}"]`)
    }
    element?.classList.add('focus')
    this._scrollTo(element)
  }

  // make the element fully visible in the options
  _scrollTo = element => {
    if (!element) {
      return
    }
    const options = this.shadowRoot.querySelector('.options > ul')
    if (options.clientHeight === options.scrollHeight) {
      return
    }
    const elementTop = element.offsetTop
    const elementBottom = elementTop + element.clientHeight
    const optionsTop = options.scrollTop
    const optionsBottom = optionsTop + options.clientHeight
    if (elementTop < optionsTop) {
      options.scrollTop -= (optionsTop - elementTop)
    } else if (elementBottom > optionsBottom) {
      options.scrollTop += (elementBottom - optionsBottom)
    }
  }
}
customElements.define('vzavg-dropdown-multiselect', DropdownMultiselectGroup)
