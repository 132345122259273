import {html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class BetriebsartUebersicht extends TranslationElement {

  static get properties() {
    return {
      betriebsart: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.betriebsart?.length) {
      return html`
        ${this._renderTitel()}
        <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.betriebsart.no.betriebsart')}</p>
      `
    }
    return html`
      ${this._renderTitel()}
      <p>${this.betriebsart}</p>
    `
  }


  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.betriebsart.title')}"
        resource="${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BetriebsartUebersicht/i18n.json'
  }


}
customElements.define('betriebsart-uebersicht', BetriebsartUebersicht)
