import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import Options from "../../../../common/Options";
import DateColumn from "@isceco/widget-library2/basic-elements/List/column/DateColumn";

export default class VerantwortlichePersonHistoryColumns {

  static getColumns(i18n) {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new StringColumn({
        key: 'soz_vers_nr',
        text: 'geschaeftsvorfall.audit.person.sozVersNr',
        sortable: false
      }),
      new GenericColumn({
        key: 'has_no_soz_vers_nr',
        text: 'geschaeftsvorfall.audit.person.noSozVersNr',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      }),
      new GenericColumn({
        key: 'gender',
        text: 'geschaeftsvorfall.audit.person.gender',
        sortable: false,
        cell: new SelectCell({items: Options.gender})
      }),
      new StringColumn({
        key: 'name',
        text: 'geschaeftsvorfall.audit.name',
        sortable: false
      }),
      new StringColumn({
        key: 'vorname',
        text: 'geschaeftsvorfall.audit.person.vorname',
        sortable: false
      }),
      new DateColumn({
        key: 'geb_datum',
        text: 'geschaeftsvorfall.audit.person.gebDatum',
        sortable: false
      }),
      new StringColumn({
        key: 'nationalitaet',
        text: 'geschaeftsvorfall.audit.person.nationalitaet',
        sortable: false
      }),
      new StringColumn({
        key: 'heimatort',
        text: 'geschaeftsvorfall.audit.person.heimatort',
        sortable: false
      }),
      new GenericColumn({
        key: 'has_aufenthaltsbewilligung',
        text: 'geschaeftsvorfall.audit.person.aufenthaltsbewilligung',
        sortable: false,
        cell: new SelectCell({items: Options.trueFalse})
      }),
      new StringColumn({
        key: 'email',
        text: 'geschaeftsvorfall.audit.email',
        sortable: false
      }),
      new DateColumn({
        key: 'taetig_von',
        text: 'geschaeftsvorfall.audit.person.taetigVon',
        sortable: false
      }),
      new DateColumn({
        key: 'taetig_bis',
        text: 'geschaeftsvorfall.audit.person.taetigBis',
        sortable: false
      }),
      new GenericColumn({
        key: 'is_vollzeit',
        text: 'geschaeftsvorfall.audit.person.beschaeftigungsGrad',
        sortable: false,
        cell: new SelectCell({items: [
            {
              value: 'true',
              name: i18n.translate('geschaeftsvorfall.audit.person.vollzeit')
            },
            {
              value: 'false',
              name: i18n.translate('geschaeftsvorfall.audit.person.teilzeit')
            }]
        })
      }),
      new StringColumn({
        key: 'teilzeitgrund',
        text: 'geschaeftsvorfall.audit.person.teilzeitgrund',
        sortable: false
      }),
      new DateColumn({
        key: 'sperrdatum_von',
        text: 'geschaeftsvorfall.audit.person.sperrdatumVon',
        sortable: false
      }),
      new DateColumn({
        key: 'sperrdatum_bis',
        text: 'geschaeftsvorfall.audit.person.sperrdatumBis',
        sortable: false
      })
    ]
  }
}
