export default class Dates {

  static getToday() {
    const now = new Date()
    now.setUTCHours(0,0,0, 0)
    return now
  }

  static isAfter(date1, date2) {
    if (!this.isValidDate(date1) || !this.isValidDate(date2)) {
      return false
    }
    return date1 > date2
  }

  static isSameOrBefore(date1, date2) {
    if (!this.isValidDate(date1) || !this.isValidDate(date2)) {
      return false
    }
    return date1 <= date2
  }

  static isBetween(date, start, end) {
    if (!this.isValidDate(date) || (!this.isValidDate(start) && !this.isValidDate(end))) {
      return false
    }

    if (this.isValidDate(start) && this.isAfter(start, date)) {
      return false
    }

    if (this.isValidDate(end)) {
      return !this.isAfter(date, end)
    }
    return true
  }

  static isValidDate(date) {
    return date instanceof Date && !isNaN(date)
  }

}
