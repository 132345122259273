import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'

class GesuchAdresseDiffDialog extends LitElement {

  static get properties() {
    return {
      ourGesuchAdresse: {type: Object},
      officialGesuchAdresse: {type: Object},
      changedFields: {type: Set},
      callback: {type: Function},
      i18n: {type: Object}
    }
  }

  static get styles() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }

      .changed {
        background-color: var(--isceco-color-yellow-100);
      }
    `
  }

  render() {
    return html`
      <isceco-dialog
        hidden
        id="isceco-diff-dialog"
        size="large"
        header="${this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.diff.header')}"
        description="${this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.diff.description')}"
        confirm-button="${this.i18n.translate('button.confirm')}"
        cancel-button="${this.i18n.translate('button.cancel')}"
        @submit="${e => this._close(e)}">
        <div class="flex">
          <div class="flex-column">
            ${this._renderGesuchAdresseInfo(this.ourGesuchAdresse, false, this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.diff.title.our'))}
          </div>
          <div class="flex-column">
            ${this._renderGesuchAdresseInfo(this.officialGesuchAdresse, true, this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.diff.title.official'))}
            <isceco-checkbox
              id="overwrite-checkbox"
              label="${this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.diff.overwrite')}"
            ></isceco-checkbox>
          </div>
        </div>
      </isceco-dialog>
    `
  }

  _close(e) {
    const overwrite = this.shadowRoot.querySelector('#overwrite-checkbox')
    const diffResult = {
      confirmed: e.detail.confirmed,
      overwrite: overwrite.value ? overwrite.value : false
    }
    if (diffResult.confirmed && diffResult.overwrite) {
      diffResult.newAdresse = this.officialGesuchAdresse
      diffResult.changedFields = this.changedFields
    }

    this.callback(diffResult)
    this.shadowRoot.querySelector('#isceco-diff-dialog')
      .setAttribute('hidden', '')

    // cleanup fields
    this.ourGesuchAdresse = {}
    this.officialGesuchAdresse = {}
    this.changedFields = new Set()
  }

  _renderGesuchAdresseInfo(gesuchAdresse, markChange, title) {
    if (!gesuchAdresse) {
      return html``
    }

    return html`
      <div class="flex-column">
        <isceco-title
          id="title-gesuchadresse"
          text="${title}"
          size="small">
        </isceco-title>
        <isceco-text-input
          id="unternRegisterId"
          name="unternRegisterId"
          value="${gesuchAdresse.uid}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.untern.register.id')}"
          class="${this._getClasses(markChange, 'unternRegisterId')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="firmenbezeichnung"
          name="firmenbezeichnung"
          value="${gesuchAdresse.firmenbezeichnung}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.firmenbezeichnung')}"
          class="${this._getClasses(markChange, 'firmenbezeichnung')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="adresszusatz"
          name="adresszusatz"
          value="${gesuchAdresse.adresszusatz}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.adresszusatz')}"
          class="${this._getClasses(markChange, 'adresszusatz')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="postfach"
          name="postfach"
          value="${gesuchAdresse.postfach}"
          label="${this.i18n.translate('geschaeftsvorfall.adresse.postfach')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="strasse"
          name="strasse"
          value="${gesuchAdresse.strasse}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.strasse')}"
          class="${this._getClasses(markChange, 'strasse')}"
          style="flex:1; min-width: 6em;"
          readonly
        ></isceco-text-input>
        <div class="flex" style="gap: 0.3em">
          <isceco-text-input
            id="plz"
            name="plz"
            value="${gesuchAdresse.plz}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.plz')}"
            class="${this._getClasses(markChange, 'plz')}"
            style="max-width: 5em"
            readonly
          ></isceco-text-input>
          <isceco-text-input
            id="ort"
            name="ort"
            value="${gesuchAdresse.ort}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.ort')}"
            class="${this._getClasses(markChange, 'ort')}"
            style="flex:1; min-width: 6em"
            readonly
          ></isceco-text-input>
        </div>
      </div>
    `;
  }

  _getClasses(markChange, fieldName) {
    return markChange && this.changedFields.has(fieldName) ? 'changed' : ''
  }
}

customElements.define('vzavg-gesuchadresse-diff-dialog', GesuchAdresseDiffDialog)
