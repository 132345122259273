import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'
import I18n from '../../../../i18n.js'

export default class NotizFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    Promise.all([stammdatenService.getOrganisation(), stammdatenService.getNotizKategorien()])
      .then(([kantone, notizKategorien]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.categories = notizKategorien.map(item => ({name: item.bezeichnung, value: item.code}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  get betriebStatus() {
    return [
      {name: this.i18n.translate('suchen.aktiv'), value: true},
      {name: this.i18n.translate('suchen.inaktiv'), value: false}
    ]
  }

  set values(val) {
    this._values = val
  }

  async connectedCallback() {
    super.connectedCallback()
    this._refresh = () => this.render()

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.kanton')}"
          id="kanton"
          name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betriebStatus')}"
          id="betriebIsActive"
          name="betriebIsActive"
          .items="${this.betriebStatus}"
          .value="${this.values?.betriebIsActive}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.eingangsdatumVon')}"
          id="eingangsdatumVon"
          name="eingangsdatumVon"
          .value="${this.values?.eingangsdatumVon}"
          .max="${isEmpty(this.values?.eingangsdatumVon) ? '' : this.values?.eingangsdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.eingangsdatumBis')}"
          id="eingangsdatumBis"
          name="eingangsdatumBis"
          .value="${this.values?.eingangsdatumBis}"
          .min="${isEmpty(this.values?.eingangsdatumBis) ? '' : this.values?.eingangsdatumVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.notiz.kategorie')}"
          id="kategorie"
          name="kategorie"
          .items="${this.categories}"
          .value="${this.values?.kategorie}"
        ></isceco-dropdown>
      </div>
    `
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key.includes('Von') || key.includes('Bis')) {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
        const date = new Date(event.detail.value)
        if (date?.getFullYear() > 1980) {
          this.reload()
        }
      }
    } else {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
      }
    }
  }

  get css() {
    return css`
      .filter-wrapper > * {
        min-width: 20em;
      }
    `
  }
}
customElements.define('vzavg-frontend-suche-notiz-filter', NotizFilter)
