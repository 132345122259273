import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'

export default class RechteColumns {

  static EMAIL_COLUMN() {
    return new GenericColumn({
      key: 'email',
      text: 'rechte.list.email',
      sortable: false,
      cell: new StringCell()
    })
  }

  static KANTON_COLUMN() {
    return new GenericColumn({
      key: 'codeKanton',
      text: 'rechte.list.kanton',
      sortable: true,
      cell: new StringCell()
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      sortable: false,
      filter: null,
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'entfernen',
            icon: 'minus',
            variant: 'primary',
            title: i18n.translate('rechte.list.actions.entfernen'),
            size: 'small'
          }
        ]
      })
    })
  }

}
