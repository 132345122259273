import WebComponent from "../../../../WebComponent";
import {html, css} from "@isceco/widget-library2/external/lit";
import KastService from "./KastService";
import VzavgPattern from "../../../../common/VzavgPattern";
import StammdatenService from "../../../../services/StammdatenService";
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";

export default class KastEdit extends WebComponent {

  get translationFile() {
    return '../views/Administration/Stammdatenpflege/i18n.json'
  }

  constructor() {
    super()
    this.kastService = new KastService()
    this.stammdatenService = new StammdatenService()
    this.kantone = []
  }

  connectedCallback() {
    super.connectedCallback()

    this.kastId = getNavigationId()

    this._getKastAndRender()
  }

  get css() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        min-width: 16em;
        gap: 0.25em;
      }
    `
  }

  getTemplate() {
    return html`
      <div>
        <isceco-button
          id="kast-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('stammdatenpflege.back.to.list')}"
          @click="${_ => this._backToKastList()}"
        ></isceco-button>
        <isceco-dialog
          hidden
          id="kast-confirm-change-dialog"
          description="${this.i18n.translate('stammdatenpflege.confirm.changes.dialog.description')}"
          confirm-button="${this.i18n.translate('stammdatenpflege.confirm.changes.button.confirm')}"
          cancel-button="${this.i18n.translate('stammdatenpflege.confirm.changes.button.cancel')}"
          @submit="${e => this._closeConfirmChangeDialog(e)}"
        ></isceco-dialog>
      </div>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('stammdatenpflege.kast.header')}">
        <isceco-button
          id="kast-speichern"
          variant="primary"
          icon="save"
          title="${this.i18n.translate('stammdatenpflege.kast.create.button')}"
          @click="${_ => this._save()}"
          ?disabled="${!AuthorizationFilter.hasSuperRole()}"
        ></isceco-button>
      </isceco-title>
      <isceco-form id="kast-form">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>
    `
  }

  _renderFormFields() {
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-text-input
            id="kast"
            name="kast"
            value="${this.backendData.kast}"
            label="${this.i18n.translate('stammdatenpflege.kast')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="bezeichnung"
            name="bezeichnung"
            value="${this.backendData.bezeichnung}"
            label="${this.i18n.translate('stammdatenpflege.kast.bezeichnung')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="strasse"
            name="strasse"
            value="${this.backendData.strasse}"
            label="${this.i18n.translate('stammdatenpflege.kast.strasse')} *"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <vzavg-plz-ort-input
            id="plzOrtKast"
            name="plzOrtKast"
            .i18n="${this.i18n}"
            .ortValue="${this.backendData.ort}"
            .plzValue="${this.backendData.plz}"
            .plzLabel="${this.i18n.translate('stammdatenpflege.kast.plz')}"
            .ortLabel="${this.i18n.translate('stammdatenpflege.kast.ort')}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></vzavg-plz-ort-input>
          <isceco-dropdown
            id="codeKanton"
            name="codeKanton"
            label="${this.i18n.translate('stammdatenpflege.kast.kanton')} *"
            value="${this.backendData.codeKanton}"
            .items="${this.kantone}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-dropdown>
        </div>
        <div class="flex-column">
          <isceco-text-input
            id="telefon"
            name="telefon"
            value="${this.backendData.telefon}"
            label="${this.i18n.translate('stammdatenpflege.kast.telefon')} *"
            pattern="${VzavgPattern.simpleTelefonPattern}"
            pattern-error-text="${this.i18n.translate('stammdatenpflege.kast.telefon.pattern.error')}"
            placeholder="+41"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="email"
            name="email"
            value="${this.backendData.email}"
            label="${this.i18n.translate('stammdatenpflege.kast.email')} *"
            pattern="${VzavgPattern.emailPattern}"
            pattern-error-text="${this.i18n.translate('stammdatenpflege.kast.email.pattern.error')}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
          <isceco-text-input
            id="web"
            name="web"
            value="${this.backendData.web}"
            label="${this.i18n.translate('stammdatenpflege.kast.web')} *"
            placeholder="www."
            pattern="${VzavgPattern.urlPattern}"
            pattern-error-text="${this.i18n.translate('stammdatenpflege.kast.web.pattern.error')}"
            @change="${e => this._updateValue(e)}"
            required
            ?readonly="${!AuthorizationFilter.hasSuperRole()}"
          ></isceco-text-input>
        </div>
      </div>
    `
  }

  _getKastAndRender() {
    this.backendData = {}

    const promises = []
    promises.push(this._fetchKantone().then(kantone => this.kantone = kantone))

    if (this.kastId) {
      promises.push(this.kastService.read(this.kastId)
        .then(kastResponse => {
          if (!isNoDataFound(kastResponse)) {
            this.backendData = kastResponse
          }
        })
        .catch(_ => {
          showAlert('', this.i18n.translate('stammdatenpflege.kast.edit.error'))
        }))
    }

    Promise.all(promises)
      .then(_ => this.render())

  }

  async _fetchKantone() {
    return this.stammdatenService.getOrganisation().then(kantone => {
      return kantone.map(kanton => ({
        value: kanton.code,
        name: kanton.bezeichnung === undefined ? '' : kanton.bezeichnung
      }));
    }).catch(_ => {
      showAlert('Stammdaten-Service Kantone unavailable', 'Request to backend could not be sent', 'error')
      return []
    })
  }

  _backToKastList() {
    if (this.hasChanges) {
      const dialog = document.getElementById('kast-confirm-change-dialog')
      dialog.hidden = false
    } else {
      navigate({to: 'Administration_Stammdatenpflege_Kast'})
    }
  }

  _closeConfirmChangeDialog(e) {
    e.target.hidden = true;
    if (e.detail.confirmed) {
      navigate({to: 'Administration_Stammdatenpflege_Kast'})
    }
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key === 'plzOrtAdresse') {
      if (this.backendData['plz'] !== event.detail.plz || this.backendData['ort'] !== event.detail.ortBez) {
        this.hasChanges = true;
        this.backendData['plz'] = event.detail.plz
        this.backendData['ort'] = event.detail.ortBez
      }
    } else {
      if (this.backendData[key] !== event.detail.value) {
        this.hasChanges = true;
        this.backendData[key] = event.detail.value
      }
    }
  }

  _save() {
    const form = document.getElementById('kast-form');
    if (!form.validate()) {
      return;
    }

    const formData = form.getValues()
    formData.plz = formData.plzOrtKast?.plz
    formData.ort = formData.plzOrtKast?.ortBez
    delete formData.plzOrtKast

    this._createOrUpdate(formData);
  }

  _createOrUpdate(formData) {
    if (this.backendData.id) {
      formData.id = this.backendData.id
      this.kastService.update(formData)
        .then(_ => {
          showAlert(
            this.i18n.translate('stammdatenpflege.kast.update.success.title'),
            this.i18n.translate('stammdatenpflege.kast.update.success.message'),
            'success'
          );
          navigate({to: 'Administration_Stammdatenpflege_Kast'})
        })
    } else {
      this.kastService.create(formData)
        .then(_ => {
          showAlert(
            this.i18n.translate('stammdatenpflege.kast.create.success.title'),
            this.i18n.translate('stammdatenpflege.kast.create.success.message'),
            'success'
          );
          navigate({to: 'Administration_Stammdatenpflege_Kast'})
        })
    }
  }
}
customElements.define('vzavg-frontend-stammdatenpflege-kast-edit', KastEdit)
