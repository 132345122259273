import {html, LitElement} from '@isceco/widget-library2/external/lit'
import VerticalNavigationCss from './VerticalTabNavigationCss.js'

export class VerticalTabNavigation extends LitElement {

  static get styles() {
    return [VerticalNavigationCss]
  }

  static get properties() {
    return {
      updateEvent: {type: String, attribute: 'update-event'},
      items: {type: Array}
    }
  }

  constructor() {
    super()
    this.items = []
  }

  connectedCallback() {
    super.connectedCallback()

    this._updateListener = () => this._reload()
    document.addEventListener(this.updateEvent, this._updateListener)
  }

  render() {
    return html`
      <style>
        @import '${iscecoWidgetLibrary.iconCss}'
      </style>
      <nav>
        <button class="hamburger-menu" @click="${() => this._triggerMobileMenu()}">
          <i class="bars icon"></i>
        </button>
        <div class="desktop">
          ${this.items.map(item => this._renderLink(item))}
        </div>
        <div class="mobile">
          <div>
            ${this.items.map(item => this._renderLink(item))}
          </div>
        </div>
      </nav>
    `
  }

  _triggerMobileMenu() {
    const mobileDIV = this.shadowRoot.querySelector('.mobile > div')
    const hamburgerMenu = this.shadowRoot.querySelector('.hamburger-menu > i')
    mobileDIV.classList.toggle('show')
    if (mobileDIV.classList.contains('show')) {
      hamburgerMenu.className = 'close icon'
    } else {
      hamburgerMenu.className = 'bars icon'
    }
  }

  _renderLink(item) {
    const icon = item.icon ? html`<i class="icon ${item.icon}"></i>` : ''
    if (item.isSeparation) {
      return html`
        <p style="cursor: none; padding: 7px; background-color: #F6F6F6">
        </p>
      `
    } else {
      return html`
      <a class="${item.isWorkflow ? 'workflow' : 'betrieb'} ${this._getActive(item)} ${this._getStatus(item)} ${this._getDisabled(item)}"
         @click="${() => this._navigate(item)}" data-link-id="${item.url.id}">
        ${icon}
        ${item.text}
      </a>
    `
    }
  }

  _navigate(item) {
    if (item.disabled) {
      return
    }
    const mobileDisplay = this.shadowRoot.querySelector('.mobile')
    if (getComputedStyle(mobileDisplay).display !== 'none') {
      this._triggerMobileMenu()
    }

    navigate({to: item.url.hash, id: item.selectedItemId ? item.selectedItemId : getNavigationId()})
  }

  _reload() {
    this.requestUpdate();
  }

  _getActive(item) {
    // this section (with selectedItemId) can be used in same mechanism as in example of SnapshotsNavigation
    if(item.selectedItemId) {
      const selectedItemIdFromUrl = Number(getNavigationIds().split('/')[2])
      if(item.active && !selectedItemIdFromUrl) {
        return 'active'
      }
      return item.selectedItemId === selectedItemIdFromUrl ? 'active' : ''
    }

    if (getNavigationHash() === '' && item.active) {
      return 'active'
    }
    if (item.suburls !== undefined
      && item.suburls.length !== null
      && item.suburls.map(e => e.hash).indexOf(getNavigationHash()) >= 0) {
      return 'active'
    }
    return item.url.hash === getNavigationHash() ? 'active' : ''
  }

  _getStatus(item) {
    return item.status ? item.status : ''
  }

  _getDisabled(item) {
    return item.disabled ? 'disabled' : ''
  }
}

customElements.define('vzavg-vertical-tab-navigation', VerticalTabNavigation)
