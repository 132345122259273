import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class VerantwortlichePersonenUebersicht extends TranslationElement {


  static get styles() {
    return css`
      ul {
        list-style: none;
        padding-left: 0
      }
    `
  }

  static get properties() {
    return {
      verantwortlichePersonen: {type: Array},
      gesuchId: {type: String}
    }
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/VerantwortlichePersonenUebersicht/i18n.json'
  }

  render() {
    if(!this.verantwortlichePersonen?.length) {
      return html`
        ${this._renderTitel()}
        <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.verantwortlichePerson.no.verantwortlichePersonen')}</p>
      `

    }
    return html`
      ${this._renderTitel()}
      <ul>
        ${this.verantwortlichePersonen.map(p => this._renderVerantwortlichePerson(p))}
      </ul>
    `
  }

  _renderVerantwortlichePerson(person) {
    return html`
        <li><a href="${this._getPersonLink(person.id)}">${person.vorname} ${person.name}</a></li>
    `
  }

  _getPersonLink(personId) {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash}/${this.gesuchId}/${personId}`
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.verantwortlichePerson.title')}"
        resource="${geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }
}
customElements.define('verantwortlichepersonen-uebersicht', VerantwortlichePersonenUebersicht)
