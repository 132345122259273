import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/VerticalNavigation/VerticalNavigation.js'
import WebComponent from '../../../../WebComponent.js'
import Navigation from '../../../Navigation/Navigation.js'
import I18n from '../../../../i18n.js'

export default class SnapshotsNavigation extends WebComponent {

  static get EVENT_KEYS() {
    return {
      _RELOAD_TRIGGERED: 'geschaeftsvorfall.snapshot.update-nav',
    }
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Snapshots/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.betriebId = sessionStorage.getItem('betriebId')

    this.navigationListener = _ => this.reload();
    this._changeLanguage = _ => {
      this.reload()
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)

    this.metadatas = JSON.parse(this.getAttribute('datas'))
    this.render()
  }

  disconnectedCallback() {
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)
  }

  getTemplate() {
    const allItems = []
    let count = 0
    this.metadatas.forEach((metadata, _) => {
      allItems[count] = {
        text: formatDate(metadata.bestaetigungsdatum) + ' ' + this.i18n.translate(metadata.isSeco ? 'navigation.vertical.seco' : 'navigation.vertical.kanton'),
        url: {
          hash: 'Geschaeftsvorfall_Snapshots/'+  this.gesuchId
        },
        selectedItemId: metadata.snapshotId
      }
      count++
    })
    allItems[0].active = true;

    return html`
        <vzavg-vertical-tab-navigation
          .items="${allItems}"
          update-event="${SnapshotsNavigation.EVENT_KEYS._RELOAD_TRIGGERED}">
        </vzavg-vertical-tab-navigation>
    `
  }
}
customElements.define('vzavg-frontend-snapshots-navigation', SnapshotsNavigation)
