import {css, html, LitElement} from '@isceco/widget-library2/external/lit'


export default class BetriebInfo extends LitElement {

  static get properties() {
    return {
      text: { type: String, attribute: false }
    }
  }

  static get styles() {
    return css`
        div {
          margin-left: 5px;
          background-color: white;
        }
    `
  }

  constructor() {
    super()
    this.text = ''
  }

  connectedCallback() {
    super.connectedCallback()
    window.betrieb$.subscribe(b => {
      if(b) {
        let plzOrt = (`${b.plz ?? ''} ${b.ort ?? ''}`).trim()
        plzOrt = plzOrt === '' ? null : plzOrt
        const strings = [b.firmenbezeichnung, b.strasse, plzOrt, b.codeKanton]
        this.text = strings.filter(Boolean).join(', ')
      } else {
        this.text = ''
      }
    })

  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
      <div>
        ${this.text}
      </div>
    `
  }

}

customElements.define('vzavg-betrieb-info', BetriebInfo)
