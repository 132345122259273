import {html, LitElement} from '@isceco/widget-library2/external/lit';
import AdressverzeichnisService from '../../services/AdressverzeichnisService';
import '@isceco/widget-library2/basic-elements/Form/Form.js'

export default class CountryInput extends LitElement {
  static get properties() {
    return {
      countries: {type: Array},
      value: {type: String},
      readonly: {type: Boolean},
      required: {type: Boolean},
      label: {type: String},
      placeholder : {String},
      i18n: {type: Array}
    }
  }

  constructor() {
    super()
    this.adressverzeichnisService = new AdressverzeichnisService()
    this.plzOrtArray = []
  }

  connectedCallback() {
    super.connectedCallback()
    this._fetchCountries()
  }

  /**
   * validates the field
   * @returns {boolean}
   */
  validate = () => {
    if (this.readonly) {
      return true
    }
    return !this.required || !isEmpty(this.value)
  }

  /**
   * implementation of getValue for the FormElement
   * @returns {String}
   */
  getValue = () => this.value


  render() {
    return this.countries?.length ? html`
      <isceco-dropdown
        id="land"
        name="land"
        placeholder="${this.placeholder}"
        .items="${this.countries}"
        label="${this.label}"
        value="${this.value}"
        @change="${e => this._handleValueChange(e)}"
        @click="${e => e.stopPropagation()}"
        ?readonly="${this.readonly}"
        ?required="${this.required}"
        ?disabled="${false}"
      ></isceco-dropdown>
    ` : html``
  }

  /**
   * fetches the country Items of the adressverzeichnisService and sets countriesArray
   * @private
   */
  _fetchCountries() {
    this.countries = this.adressverzeichnisService.countries.map(country => ({name: country, value: country}))
    this.render()
  }

  /**
   * Sets value when value changed and dispatch the change event with the new value
   * @param event
   * @private
   */
  _handleValueChange(event) {
    this.value = event.detail.value
    send('valueChanged', {value: this.value}, this)
  }

}

customElements.define('vzavg-country-input', CountryInput);
