import {css} from '@isceco/widget-library2/external/lit'

export default css`
  :host {
    font-family: var(--isceco-font);
    display: block;
  }

  :host(.error) .field {
    outline: 2px solid var(--isceco-color-red-500);
  }

  :host(:not(.error)) #message {
    display: none;
  }

  :host(.changed) .field {
    background-color: var(--isceco-color-yellow-100);
  }

  #message {
    margin: 8px 0 0;
    font-weight: normal;
    color: var(--isceco-color-red-500);
    font-size: 0.875rem;
  }

  .label-wrapper {
    display: block;
    margin-bottom: 4px;
  }

  label {
    font-weight: bold;
    color: var(--isceco-color-black-500);
  }

  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .inline > .select-container {
    flex: 1;
    margin: 0;
  }

  .inline > .label-wrapper {
    margin-right: var(--isceco-space-200);
    margin-bottom: 0;
  }

  /* dropdown */

  .select-container {
    margin-top: 4px;
    position: relative;
  }

  .field {
    font: inherit;
    box-shadow: none;
    padding: 0 48px 0 10px;
    height: 48px;
    border: 1px solid var(--isceco-color-grey-700);
    color: var(--isceco-color-grey-900);
    border-radius: 4px;
    background-color: var(--isceco-color-white-500);
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }

  .field::placeholder {
    color: var(--isceco-color-grey-300);
    opacity: 1;
  }

  .field:hover {
    border-color: var(--isceco-color-blue-500);
  }

  .field:active, .field:focus, .field:focus-visible {
    border-color: var(--isceco-color-blue-500);
    outline: 2px solid var(--isceco-color-blue-500);
  }

  .field:disabled {
    border-color: var(--isceco-color-grey-300);
    color: var(--isceco-color-grey-300);
    cursor: not-allowed;
  }

  .field-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--isceco-color-blue-500);
    transition: all .5s ease-in-out;
  }

  .field-icon.arrow > i {
    margin: 0;
    font-size: 1.5rem;
  }

  .field-icon.clear > i {
    margin: 0;
    color: var(--isceco-color-grey-700);
    cursor: pointer;
  }

  .field-disabled {
    color: var(--isceco-color-grey-700);
    pointer-events: none;
  }

  .dropdown.open .field-icon {
    transform: scaleY(-1);
  }

  .dropdown.closed > .options {
    display: none;
  }

  .options > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 300px;
    width: max-content;
    border-radius: 4px;
    background-color: var(--isceco-color-white-500);
    z-index: 90;
  }

  .options > ul > li {
    padding: 10px;
    min-height: 48px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .options > ul > li.focus {
    cursor: pointer;
    background-color: var(--isceco-color-blue-100);
  }

  .options > ul > li[value=""] {
    color: var(--isceco-color-grey-300)
  }

  /* readonly */

  .readonly > .label-wrapper > span {
    color: var(--isceco-color-black-500);
    font-weight: bold;
  }

  .readonly > p {
    flex-grow: 1;
    color: var(--isceco-color-black-500);
    margin: 0;
    min-height: 48px;
    padding-top: 10px;
    box-sizing: border-box;
  }

  .readonly.inline > p {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
`
