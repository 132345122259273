import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import DefaultActionCell from "../../../../common/list/DefaultActionCell";
import LinkCell from "@isceco/widget-library2/basic-elements/List/cell/LinkCell";

export default class KastColumns {
  static KAST_COLUMN() {
    return new StringColumn({
      key: 'kast',
      text: 'stammdatenpflege.kast',
      sortable: false
    })
  }

  static BEZEICHNUNG_COLUMN() {
    return new StringColumn({
      key: 'bezeichnung',
      text: 'stammdatenpflege.kast.bezeichnung',
      sortable: false
    })
  }

  static STRASSE_COLUMN() {
    return new StringColumn({
      key: 'strasse',
      text: 'stammdatenpflege.kast.strasse',
      sortable: false
    })
  }

  static PLZ_COLUMN() {
    return new StringColumn({
      key: 'plz',
      text: 'stammdatenpflege.kast.plz',
      sortable: false
    })
  }

  static ORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'stammdatenpflege.kast.ort',
      sortable: false
    })
  }

  static KANTON_COLUMN() {
    return new StringColumn({
      key: 'kanton',
      text: 'stammdatenpflege.kast.kanton',
      sortable: false
    })
  }

  static TELEFON_COLUMN() {
    return new StringColumn({
      key: 'telefon',
      text: 'stammdatenpflege.kast.telefon',
      sortable: false
    })
  }

  static EMAIL_COLUMN() {
    return new StringColumn({
      key: 'email',
      text: 'stammdatenpflege.kast.email',
      sortable: false
    })
  }

  static WEB_COLUMN() {
    return new GenericColumn({
      key: 'web',
      text: 'stammdatenpflege.kast.web',
      sortable: false,
      cell: new LinkCell({
        url: row => this._formatUrlWithHttp(row.web),
        target: '_blank'
      })
    })
  }

  static _formatUrlWithHttp(url) {
    if (!url || url.startsWith('https://') || url.startsWith('http://')) {
      return url
    }

    return 'https://' + url
  }

  static DETAIL_COLUMN(configuration) {
    return new GenericColumn({
      key: 'detail',
      text: 'stammdatenpflege.kast.detail',
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'details',
            icon: 'edit',
            variant: 'primary',
            title: i18n.translate('stammdatenpflege.kast.detail'),
            size: 'small'
          }
        ]
      })
    })
  }

  static USER_COLUMN(configuration) {
    return new GenericColumn({
      key: 'user',
      text: 'stammdatenpflege.kast.user',
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'users',
            icon: 'edit',
            variant: 'primary',
            title: i18n.translate('stammdatenpflege.kast.user'),
            size: 'small'
          }
        ]
      })
    })
  }
}
