import {css, html, LitElement} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'
import VzavgFormation from '../../common/VzavgFormation'

class BetriebDiffDialog extends LitElement {

  static get properties() {
    return {
      existingBetrieb: { type: Object },
      newBetriebValues: { type: Object },
      changedFields: { type: Set },
      callback: { type: Function },
      i18n: { type: Object }
    }
  }

  static get styles() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }

      .changed {
        background-color: var(--isceco-color-yellow-100);
      }
    `
  }

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
        <isceco-dialog
          hidden
          id="isceco-diff-dialog"
          size="large"
          header="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.header')}"
          description="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.description')}"
          confirm-button="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.confirm')}"
          cancel-button="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.cancel')}"
          @submit="${e => this._close(e)}">
          <div class="flex">
            <div class="flex-column">${this._renderBetriebInfo(this.existingBetrieb, false, this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.title.existing'))}</div>
            <div class="flex-column">
              ${this._renderBetriebInfo(this.newBetriebValues, true, this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.title.new'))}
              <isceco-checkbox
                id="overwrite-checkbox"
                label="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.diff.overwrite')}"
              ></isceco-checkbox>
            </div>
          </div>
        </isceco-dialog>
    `
  }

  _close(e) {
    const overwrite = this.shadowRoot.querySelector('#overwrite-checkbox')
    const diffResult = {
      confirmed: e.detail.confirmed,
      overwrite: overwrite.value ? overwrite.value : false
    }
    if (diffResult.confirmed && !diffResult.overwrite) {
      diffResult.betrieb = this.existingBetrieb
    }

    this.callback(diffResult)
    this.shadowRoot.querySelector('#isceco-diff-dialog')
      .setAttribute('hidden','')

    // cleanup fields
    this.existingBetrieb = {}
    this.newBetriebValues = {}
    this.changedFields = new Set()
  }

  _renderBetriebInfo(betrieb, markChange, title) {
    if (betrieb) {
      return html`
        <div class="flex-column">
          <isceco-title id="title-betrieb"
                        text="${title}"
                        size="small">
          </isceco-title>
          <isceco-text-input
            id="unternRegisterId"
            name="unternRegisterId"
            value="${VzavgFormation.formatUID(betrieb.uid)}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.untern.register.id')}"
            class="${this._getClasses(markChange, 'unternRegisterId')}"
            readonly
          ></isceco-text-input>
          <isceco-text-input
            id="firmenbezeichnung"
            name="firmenbezeichnung"
            value="${betrieb.firmenbezeichnung}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.firmenbezeichnung')}"
            class="${this._getClasses(markChange, 'firmenbezeichnung')}"
            readonly
          ></isceco-text-input>
          <isceco-text-input
            id="adresszusatz"
            name="adresszusatz"
            value="${betrieb.adresszusatz}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.adresszusatz')}"
            class="${this._getClasses(markChange, 'adresszusatz')}"
            readonly
          ></isceco-text-input>
          <div class="flex" style="gap: 0.3em">
            <isceco-text-input
              id="plz"
              name="plz"
              value="${betrieb.plz}"
              label="${this.i18n.translate('geschaeftsvorfall.betrieb.plz')}"
              class="${this._getClasses(markChange, 'plz')}"
              style="max-width: 5em"
              readonly
            ></isceco-text-input>
            <isceco-text-input
              id="ort"
              name="ort"
              value="${betrieb.ort}"
              label="${this.i18n.translate('geschaeftsvorfall.betrieb.ort')}"
              class="${this._getClasses(markChange, 'ort')}"
              style="flex:1; min-width: 6em"
              readonly
            ></isceco-text-input>
          </div>
          <isceco-text-input
            id="strasse"
            name="strasse"
            value="${betrieb.strasse}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.strasse')}"
            class="${this._getClasses(markChange, 'strasse')}"
            style="flex:1; min-width: 6em;"
            readonly
          ></isceco-text-input>
          <isceco-text-input
            id="land"
            name="land"
            value="${betrieb.land}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.land')}"
            class="${this._getClasses(markChange, 'land')}"
            readonly
          ></isceco-text-input>
        </div>
      `;
    }
    return html``
  }

  _getClasses(markChange, fieldName) {
    return markChange && this.changedFields.has(fieldName) ? 'changed' : ''
  }
}
customElements.define('vzavg-betrieb-diff-dialog', BetriebDiffDialog);
