import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button'

export default class KautionCurrencyCell {

  _getFormatedAmount(value) {
    const formatter = new Intl.NumberFormat('de-CH')
    return formatter.format(value)
  }

  render(i18n, cell) {
    return html`
      <span>${this._getFormatedAmount(cell)}</span>
    `
  }


}
