import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn'
import StringCell from '@isceco/widget-library2/basic-elements/List/cell/StringCell'
import DateCell from '@isceco/widget-library2/basic-elements/List/cell/DateCell'
import DefaultActionCell from '../../../../common/list/DefaultActionCell'
import {geschaeftsVorfallNavigationLinks} from '../../../Geschaeftsvorfall/Geschaeftsvorfall'
import UidCell from '../../../../components/Betrieb/UidCell'

export default class BewilligungSearchColumns {
  static getColumns() {
    return [
      {
        column: new GenericColumn({
          key: 'kanton',
          text: 'suchen.result.column.kanton',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'typ',
          text: 'suchen.result.column.typ',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'firmenbezeichnung',
          text: 'suchen.result.column.firmenbezeichnung',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },

      {
        column: new GenericColumn({
          key: 'strasse',
          text: 'suchen.result.column.strasse',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'plz',
          text: 'suchen.result.betrieb.column.plz',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'ort',
          text: 'suchen.result.column.ort',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'unternRegisterId',
          text: 'suchen.result.betrieb.column.uid',
          sortable: true,
          cell: new UidCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'bereich',
          text: 'suchen.result.column.bereich',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'erstbewilligungsdatum',
          text: 'suchen.result.bewilligung.column.erstbewilligungsdatum',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'aenderungsdatum',
          text: 'suchen.result.bewilligung.column.aenderungsdatum',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'entzugAufhebungsdatum',
          text: 'suchen.result.bewilligung.column.entzugAufhebungsdatum',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'grund',
          text: 'suchen.result.bewilligung.column.grund',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'inRechtskraftErwachsen',
          text: 'suchen.result.bewilligung.column.inRechtskraftErwachsen',
          sortable: true,
          cell: new DateCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'telefonnummer',
          text: 'suchen.result.column.telefonnummer',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'email',
          text: 'suchen.result.column.email',
          sortable: true,
          cell: new StringCell()
        }),
        alwaysDisplayed: false,
        defaultDisplayed: true
      },
      {
        column: new GenericColumn({
          key: 'aktionen',
          text: '',
          weight: 0.5,
          align: 'right',
          cell: new DefaultActionCell({
            callback: (row, action) => {
              if (action === 'edit') {
                navigate({to: geschaeftsVorfallNavigationLinks.bewilligungEdit.hash, id: `${row.gesuchId}/${row.id}`})
              }
            },
            buttons: (i18n, row) => {
              const buttons = []

              buttons.push(
                {
                  action: 'edit',
                  icon: 'clipboard',
                  variant: 'primary',
                  title: i18n.translate('suchen.result.betrieb.column.edit')
                }
              )

              return buttons
            }
          })
        }),
        alwaysDisplayed: true,
        defaultDisplayed: true
      }
    ]
  }
}
