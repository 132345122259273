import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import TranslationElement from '../../../../common/TranslationElement'

export default class BestaetigungsdatumUebersicht extends TranslationElement {

  static get properties() {
    return {
      bestaetigungsDatum: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if(!this.bestaetigungsDatum?.length) {
      return html`
      ${this._renderTitel()}
      <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.no.date')}</p>
    `
    }
    return html`
      ${this._renderTitel()}
      <p>${this.date}</p>
    `
  }

  get date() {
    const bestaetigungsDatum = new Date(this.bestaetigungsDatum);
    const day = bestaetigungsDatum.getDate().toString().padStart(2, '0');
    const month = (bestaetigungsDatum.getMonth() + 1).toString().padStart(2, '0');
    const year = bestaetigungsDatum.getFullYear();
    return `${day}.${month}.${year}`;
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BestaetigungsdatumUebersicht/i18n.json'
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.bestaetigungsdatum.title')}"
        resource="${geschaeftsVorfallNavigationLinks.entscheid.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }
}
customElements.define('bestaetigungsdatum-uebersicht', BestaetigungsdatumUebersicht)
