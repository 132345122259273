import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js';
import {NotizIsExternCell} from './NotizExternCell.js'
import CheckboxCell from "@isceco/widget-library2/basic-elements/List/cell/CheckboxCell";




export default class NotizColumns {
  static DATUM_COLUMN() {
    return new DateColumn({
      key: 'lastModificationTime',
      text: 'geschaeftsvorfall.notiz.datum',
      sortable: true
    })
  }

  static TYP_COLUMN() {
    return new GenericColumn({
      key: 'isExtern',
      text: 'geschaeftsvorfall.notiz.typ',
      sortable: true,
      cell: new NotizIsExternCell()
    })
  }

  static KATEGORIE_COLUMN() {
    return new StringColumn({
      key: 'translatedKategorie',
      text: 'geschaeftsvorfall.notiz.kategorie',
      sortable: true
    })
  }

  static TITEL_COLUMN() {
    return new StringColumn({
      key: 'titel',
      text: 'geschaeftsvorfall.notiz.titel',
      weight: 1.5
    })
  }

  static CONTAIN_DOCS_COLUMN() {
    return new GenericColumn({
      key: 'containDocs',
      text: 'geschaeftsvorfall.notiz.contain.docs',
      weight: 1,
      cell: new CheckboxCell({
        disabled: _ => true
      }),
    })
  }

  static BENUTZER_COLUMN() {
    return new StringColumn({
      key: 'lastModifiedBy',
      text: 'geschaeftsvorfall.notiz.benutzer',
      sortable: true,
      weight: 1.5
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: (i18n, row) => {
          const buttons = []

          buttons.push({
              action: 'edit',
              icon: 'clipboard',
              variant: 'primary',
              title: i18n.translate('geschaeftsvorfall.notiz.actions.edit'),
            })
          buttons.push({
            action: 'delete',
            icon: 'minus',
            variant: 'primary',
            title: i18n.translate('geschaeftsvorfall.notiz.actions.delete'),
            disabled: row.isReadonly
          })

          return buttons
        }
      })
    })
  }
}
