import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import DateColumn from "@isceco/widget-library2/basic-elements/List/column/DateColumn.js";
import Options from "../../../../common/Options";
import {noYesInternationalFlOptions} from "../../Gesuch/Gesuch";

export default class GesuchHistoryColumns {

  static getColumns(i18n) {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new GenericColumn({
        key: 'bewilligungspflicht',
        text: 'geschaeftsvorfall.audit.betrieb.bewilligungspflicht',
        sortable: false,
        cell: new SelectCell({items: [
            { value: 'yes', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.bewilligungspflicht.yes')},
            { value: 'no', name:  i18n.translate('geschaeftsvorfall.audit.betrieb.bewilligungspflicht.no')}
          ]})
      }),
      new DateColumn({
        key: 'eingangsdatum',
        text: 'geschaeftsvorfall.audit.gesuch.eingangsdatum',
        sortable: false
      }),
      new StringColumn({
        key: 'aenderungsgruende',
        text: 'geschaeftsvorfall.audit.gesuch.aenderungsgrund',
        sortable: false
      }),
      new GenericColumn({
        key: 'arbeits_vermittlung',
        text: 'geschaeftsvorfall.audit.gesuch.arbeitsVermittlung',
        sortable: false,
        cell: new SelectCell({items: noYesInternationalFlOptions(i18n)})
      }),
      new GenericColumn({
        key: 'personal_verleih',
        text: 'geschaeftsvorfall.audit.gesuch.personalVerleih',
        sortable: false,
        cell: new SelectCell({items: noYesInternationalFlOptions(i18n)})
      }),
      new GenericColumn({
        key: 'gesuchsart',
        text: 'geschaeftsvorfall.audit.gesuch.gesuchsart',
        sortable: false,
        cell: new SelectCell({items: [
            {value: 'erstgesuch', name: i18n.translate('geschaeftsvorfall.audit.gesuch.gesuchsart.erstgesuch')},
            {value: 'aenderungsgesuch', name: i18n.translate('geschaeftsvorfall.audit.gesuch.gesuchsart.aenderungsgesuch')}
          ]})
      }),
      new StringColumn({
        key: 'branchen',
        text: 'geschaeftsvorfall.audit.gesuch.branchen',
        sortable: false
      }),
      new StringColumn({
        key: 'qualifikationen',
        text: 'geschaeftsvorfall.audit.gesuch.qualifikationen',
        sortable: false
      }),
      new StringColumn({
        key: 'betriebsart',
        text: 'geschaeftsvorfall.audit.gesuch.betriebsart',
        sortable: false
      }),
      new GenericColumn({
        key: 'provision',
        text: 'geschaeftsvorfall.audit.gesuch.provision',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      }),
      new GenericColumn({
        key: 'gemeinnuetzigkeit',
        text: 'geschaeftsvorfall.audit.gesuch.gemeinnuetzigkeit',
        sortable: false,
        cell: new SelectCell({items: Options.yesNo})
      })
    ]
  }
}
