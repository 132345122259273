import {css, html, LitElement} from '@isceco/widget-library2/external/lit'


export default class SnapshotInfo extends LitElement {

  static get properties() {
    return {
      text: { type: String, attribute: false }
    }
  }

  static get styles() {
    return css`
        div {
          margin-left: 5px;
          background-color: white;
        }
    `
  }

  constructor() {
    super()
    this.text = ''
  }

  connectedCallback() {
    super.connectedCallback()
    window.snapshot$.subscribe(s => {
      if(s) {
        const strings = [formatDate(s.bestaetigungsdatum), s.entscheidGesuch.bezeichnung, s.userEmail]
        this.text = strings.filter(Boolean).join(', ')
      } else {
        this.text = ''
      }
    })
  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}';</style>
      <div>
        ${this.text}
      </div>
    `
  }

}

customElements.define('vzavg-snapshot-info', SnapshotInfo)
