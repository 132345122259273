import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import Kast from "./kast/Kast";
import KastEdit from "./kast/KastEdit";
import Benutzer from "./benutzer/Benutzer";
import BenutzerEdit from "./benutzer/BenutzerEdit";

export default class Stammdatenpflege extends WebComponent {

  get translationFile() {
    return './views/Administration/Stammdatenpflege/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    // set default view
    if (getNavigationHash() === 'Administration_Stammdatenpflege') {
      navigate({to: 'Administration_Stammdatenpflege_Kast'});
    }
    this.render()
  }

  getTemplate() {
    return html`
      <div>
        <h1>${this.i18n.translate('stammdatenpflege.header')}</h1>
      </div>
      ${this.getContent()}
    `
  }

  getContent() {
    switch (getNavigationHash()) {
      case 'Administration_Stammdatenpflege_Kast':
        return new Kast()
      case 'Administration_Stammdatenpflege_KastEdit':
        return new KastEdit()
      case 'Administration_Stammdatenpflege_Benutzer':
        return new Benutzer()
      case 'Administration_Stammdatenpflege_BenutzerEdit':
        return new BenutzerEdit()
      default:
        break;
    }
    // default
    return html`Default`
  }


}
customElements.define('vzavg-frontend-stammdatenpflege', Stammdatenpflege)
