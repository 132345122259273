import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import ExternalBetriebSearchService from '../../services/ExternalBetriebSearchService.js'
import VzavgListService from '../../services/VzavgListService.js'
import DefaultMobileItem from '../DefaultMobileItem/DefaultMobileItem.js'
import {geschaeftsVorfallNavigationLinks} from '../../views/Geschaeftsvorfall/Geschaeftsvorfall.js'
import I18n from '../../i18n.js'
import BetriebSearchItemColumns from './BetriebSearchItemColumns.js'
import './BetriebAlreadyExistsDialog.js'


export default class BetriebSearchDialog extends LitElement {

  static SEARCH_BTN_TEXT = 'geschaeftsvorfall.dialog.betrieb.search.btn'
  static SEARCH_BTN_ID = '#betrieb-search-btn'
  static UID = 'uid'
  static TEXT = 'text'
  static BURWEB_OFFSET = 'burweb-offset'

  static IDENTIFIER = 'burweb'

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'burwebsearch-reload-triggered'
    }
  }

  static get properties() {
    return {
      callback: {type: Function},
      i18n: {type: Object},
      kanton: {type: String}
    }
  }

  static get styles() {
    return css`
      isceco-text-input > div > label {
        width: 20% !important;
      }

      isceco-text-input {
        padding-top: 10px;
      }

    `
  }

  constructor() {
    super()
    this.searchService = new ExternalBetriebSearchService()
    this.vzavgListService = new VzavgListService(this.url)
    this.searchParam = {
      uid: '',
      text: '',
      kanton: ''
    }
  }

  connectedCallback() {
    super.connectedCallback()

    this._refresh = () => {
      send(BetriebSearchDialog.EVENT_KEYS.RELOAD_TRIGGERED)
      this.requestUpdate()
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.columns = [
      BetriebSearchItemColumns.UID_COLUMN(),
      BetriebSearchItemColumns.NAME_COLUMN(),
      BetriebSearchItemColumns.STREET_COLUMN(),
      BetriebSearchItemColumns.HOUSENUMBER_COLUMN(),
      BetriebSearchItemColumns.KANTON_COLUMN(),
      BetriebSearchItemColumns.ZIP_COLUMN(),
      BetriebSearchItemColumns.TOWN_COLUMN(),
      BetriebSearchItemColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenu
      })
    ]
  }

  render() {
    return html`
      <isceco-dialog
        hidden
        id="isceco-search-dialog"
        size="large"
        @submit="${_ => this._close()}">
        <div id="diff-dialog-content">
          <isceco-text-input
            id="betrieb-search-uid"
            label="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.search.uid')}"
            type="text"
            maxlength="255"
            help-text="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.search.uid.helptext')}"
            pattern="^((CHE)?-?\\d{3}\.?\\d{3}\.?\\d{3})$"
            placeholder="CHEnnnnnnnnn, nnnnnnnnn oder CHE-nnn.nnn.nnn"
            value="${this.searchParam.uid}"
            @change="${e => this._updateValue('uid', e)}"
            @keydown="${e => this._updateUidValueAndSearch(e)}"
          ></isceco-text-input>
          <isceco-text-input
            id="betrieb-search-name"
            label="${this.i18n.translate('geschaeftsvorfall.dialog.betrieb.search.name')}"
            type="text"
            maxlength="255"
            value="${this.searchParam.name}"
            @change="${e => this._updateValue('text', e)}"
            @keydown="${e => this._updateNameValueAndSearch(e)}"
          ></isceco-text-input>
          <div id="bur-search-errormsg" style="color: red"></div>
          <isceco-button
            id="betrieb-search-btn"
            text="${this.i18n.translate(BetriebSearchDialog.SEARCH_BTN_TEXT)}"
            style="margin-top: 5px"
            @click="${_ => this._search()}" variant="secondary">
          </isceco-button>
          <br>
          <isceco-list
            id="betrieb-list"
            max="5"
            order="desc"
            .listService="${this.vzavgListService}"
            identifier="${BetriebSearchDialog.IDENTIFIER}"
            update-event="${BetriebSearchDialog.EVENT_KEYS.RELOAD_TRIGGERED}"
            .columns="${this.columns}"
            .i18n="${this.i18n}"
            .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
          </isceco-list>
        </div>

      </isceco-dialog>
      <vzavg-betrieb-already-exists-dialog
        betriebUrl="${geschaeftsVorfallNavigationLinks.betrieb.hash}"
      ></vzavg-betrieb-already-exists-dialog>
    `
  }

  _close() {
    this.shadowRoot.querySelector('#isceco-search-dialog')
      .setAttribute('hidden', '')
    this.searchParam = {
      uid: '',
      text: '',
      kanton: ''
    }
    this.vzavgListService.setUrl('')
    this._cleanupCurrentSearch()
    this._refresh()
  }

  _updateValue(key, event) {
    this.searchParam[key] = event.detail.value
  }

  _validParams() {
    const uidTextInput = this.shadowRoot.querySelector('#betrieb-search-uid')
    const nameTextInput = this.shadowRoot.querySelector('#betrieb-search-name')

    return (uidTextInput.value.length > 3 && uidTextInput.validate()) ||
      (nameTextInput.value.length > 0 && nameTextInput.validate())
  }

  _search() {
    const errorMsg = this.shadowRoot.querySelector('#bur-search-errormsg')
    if (!this._validParams()) {
      errorMsg.innerHTML = this.i18n.translate('geschaeftsvorfall.dialog.betrieb.search.errormsg')
      return
    } else {
      errorMsg.innerHTML = ''
    }

    this._cleanupCurrentSearch()
    this.shadowRoot.querySelector(BetriebSearchDialog.SEARCH_BTN_ID).text = this.i18n.translate('geschaeftsvorfall.dialog.betrieb.search.wait')
    const params = getNavigationParams()
    const cleanedUID = this.searchParam.uid.replaceAll('-', '').replaceAll('.', '')
    params.set(BetriebSearchDialog.UID, cleanedUID)
    params.set(BetriebSearchDialog.TEXT, this.searchParam.text)
    params.set(BetriebSearchDialog.BURWEB_OFFSET, '0')
    setNavigationParams(params.toString())
    // reset list paging by new search request
    const list = this.shadowRoot.querySelector('#betrieb-list')
    list._goToPage(1)

    const kantonSuffix = this.kanton ? `/${this.kanton}` : ''
    this.searchService.search(`/uidregister${kantonSuffix}?${getNavigationParams()}`)
      .then(result => {
        if (result.searchId) {
          this.currentSearchId = result.searchId;
          return this.searchService.getBackendListUrl(result.searchId)
        } else {
          return null
        }
      })
      .then(url => {
        this.url = url
        this.vzavgListService.setUrl(this.url)
        this._refresh()
        this.shadowRoot.querySelector(BetriebSearchDialog.SEARCH_BTN_ID).text = this.i18n.translate(BetriebSearchDialog.SEARCH_BTN_TEXT)
      })
      .catch(_ => {
        this.requestUpdate()
        this.shadowRoot.querySelector(BetriebSearchDialog.SEARCH_BTN_ID).text = this.i18n.translate(BetriebSearchDialog.SEARCH_BTN_TEXT)
      })
  }

  _cleanupCurrentSearch() {
    if (this.currentSearchId) {
      this.searchService.deleteSilently(this.currentSearchId)
    }
    this.currentSearchId = null
  }

  _rowActionMenu = (row, action) => {
    if (action === 'add') {
      const saveAndClose = () => {
        this._close()
        this.callback(row)
      }
      this.searchService.searchLocalByUid(row.uid).then(result => {
          this._openBetriebAlreadyExistsDialog(result).subscribe(submit => {
            if (submit) {
              saveAndClose()
            }
          });
        }
      ).catch(error => {
        if (error.state === 404) {
          saveAndClose()
        }
      })
    } else {
      this.requestUpdate()
    }
  }

  _openBetriebAlreadyExistsDialog = result => {
    const dialog = this.shadowRoot.querySelector('vzavg-betrieb-already-exists-dialog');
    const diffDialogContent = this.shadowRoot.querySelector('#diff-dialog-content');
    dialog.height = diffDialogContent.offsetHeight
    dialog.betriebe = result
    return dialog.open()
  }

  _updateUidValueAndSearch(e) {
    this._updateValueAndSearch(e, 'uid')
  }

  _updateNameValueAndSearch(e) {
    this._updateValueAndSearch(e, 'text')
  }

  _updateValueAndSearch(e, fieldKey){
    if (e.key !== 'Enter') {
      return
    }

    this.searchParam[fieldKey] = e.target.getValue()

    this._search()
  }
}
customElements.define('vzavg-betrieb-search-dialog', BetriebSearchDialog)
