import {html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class MarkenUebersicht extends TranslationElement {

  static get properties() {
    return {
      marken: {type: String},
      gesuchId: {type: String}
    }
  }

  render() {
    if(!this.marken?.length) {
      return html`
      ${this._renderTitel()}
      <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.marken.no.marken')}</p>
    `
    }
    return html`
      ${this._renderTitel()}
      <p>${this.marken}</p>
    `
  }


  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.marken.title')}"
        resource="${geschaeftsVorfallNavigationLinks.betrieb.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }


  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/MarkenUebersicht/i18n.json'
  }
}
customElements.define('marken-uebersicht', MarkenUebersicht)
