import {css, html} from '@isceco/widget-library2/external/lit'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall.js'
import TranslationElement from '../../../../common/TranslationElement.js'
import VzavgFormation from '../../../../common/VzavgFormation.js'
import AuthorizationFilter from '../../../Authorization/AuthorizationFilter.js'
import UebersichtService from '../../../../services/UebersichtService.js'

export default class BewilligungenUebersicht extends TranslationElement {

  static get properties() {
    return {
      bewilligungs: {type: Object},
    }
  }

  constructor() {
    super()
    this.uebersichtService = new UebersichtService()
  }

  connectedCallback() {
    super.connectedCallback()
  }

  updated(_) {
    if(this._isSnapshot()) {
      this.shadowRoot.querySelectorAll('isceco-date-input').forEach(element => {
        const pElement = element.shadowRoot.querySelector('p')
        if(pElement) {
          pElement.style.color = 'gray'
        }
      });
    }
  }

  render() {
    const kantonAvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvEingang)
    const kantonAvErstbewilligung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvErstbewilligung)
    const kantonAvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvAenderung)
    const kantonAvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvAufhebung)
    const kantonAvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonAvEntzug)

    const kantonPvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvEingang)
    const kantonPvErstbewilligung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvErstbewilligung)
    const kantonPvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvAenderung)
    const kantonPvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvAufhebung)
    const kantonPvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.kantonPvEntzug)

    const secoAvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvEingang)
    const secoAvErstbewilligung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvErstbewilligung)
    const secoAvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvAenderung)
    const secoAvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvAufhebung)
    const secoAvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.secoAvEntzug)

    const secoPvEingang = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvEingang)
    const secoPvErstbewilligung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvErstbewilligung)
    const secoPvAenderung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvAenderung)
    const secoPvAufhebung = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvAufhebung)
    const secoPvEntzug = VzavgFormation.formatDateToHuman(this.bewilligungs.secoPvEntzug)


    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('title')}"
        resource="${geschaeftsVorfallNavigationLinks.bewilligung.hash}/${getNavigationId()}"
      ></vzavg-link>
      <p>${this.bewilligungs.befreit ? this.i18n.translate('geschaeftsvorfall.uebersicht.bewilligungs.befreit') : ''}</p>

      <h4>${this.i18n.translate('kantonal')}</h4>
      <div class="grid">
        <span></span>
        <b>AV</b>
        <b>PV</b>

        <span>${this.i18n.translate('eingang')}</span>
        <span>${kantonAvEingang}</span>
        <span>${kantonPvEingang}</span>

        <span>${this.i18n.translate('erstbewilligung')}</span>
        <span>${kantonAvErstbewilligung}</span>
        <span>${kantonPvErstbewilligung}</span>

        <span>${this.i18n.translate('aenderung')}</span>
        <span>${kantonAvAenderung}</span>
        <span>${kantonPvAenderung}</span>

        <span>${this.i18n.translate('aufhebung')}</span>
        <span>${kantonAvAufhebung}</span>
        <span>${kantonPvAufhebung}</span>

        <span>${this.i18n.translate('entzug')}</span>
        <span>${kantonAvEntzug}</span>
        <span>${kantonPvEntzug}</span>
      </div>

      <h4>${this.i18n.translate('eidgenoessisch')}</h4>
      <div class="grid">
        <span></span>
        <b>AV</b>
        <b>PV</b>

        <span>${this.i18n.translate('eingang')}</span>
        <span>${secoAvEingang}</span>
        <span>${secoPvEingang}</span>

        <span>${this.i18n.translate('erstbewilligung')}</span>
        <span>${secoAvErstbewilligung}</span>
        <span>${secoPvErstbewilligung}</span>

        <span>${this.i18n.translate('aenderung')}</span>
        <span>${secoAvAenderung}</span>
        <span>${secoPvAenderung}</span>

        <span>${this.i18n.translate('aufhebung')}</span>
        <span>${secoAvAufhebung}</span>
        <span>${secoPvAufhebung}</span>

        <span>${this.i18n.translate('entzug')}</span>
        <span>${secoAvEntzug}</span>
        <span>${secoPvEntzug}</span>
      </div>

      <h4>${this.i18n.translate('fl')}</h4>
      <div class="grid">
        <span></span>
        <b>AV</b>
        <b>PV</b>

        <span class="table-label">${this.i18n.translate('erstbewilligung')}</span>
        <isceco-date-input
          id="flErstbewilligungAV"
          name="flErstbewilligungAV"
          .value="${this.bewilligungs?.flErstbewilligungAV}"
          .readonly="${!AuthorizationFilter.hasSecoRole() || this._isSnapshot()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
        <isceco-date-input
          id="flErstbewilligungPV"
          name="flErstbewilligungPV"
          .value="${this.bewilligungs?.flErstbewilligungPV}"
          .readonly="${!AuthorizationFilter.hasSecoRole() || this._isSnapshot()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>

        <span class="table-label">${this.i18n.translate('aufhebung')}</span>
        <isceco-date-input
          id="flAufhebungAV"
          name="flAufhebungAV"
          .value="${this.bewilligungs?.flAufhebungAV}"
          .readonly="${!AuthorizationFilter.hasSecoRole() || this._isSnapshot()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
        <isceco-date-input
          id="flAufhebungPV"
          name="flAufhebungPV"
          .value="${this.bewilligungs?.flAufhebungPV}"
          .readonly="${!AuthorizationFilter.hasSecoRole() || this._isSnapshot()}"
          @change="${_ => this._updateFlDates()}"
        ></isceco-date-input>
      </div>
    `
  }

  static get styles() {
    return css`
      .date {
        display: flex;
        justify-content: space-between;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 1em;
      }

      .group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2em;
      }

      .table-label {
        line-height: 2.8em;
      }
    `
  }

  _updateFlDates() {
    const bewilligungenShadowRoot = document.querySelector('bewilligungen-uebersicht').shadowRoot
    const erstbewilligungAV = bewilligungenShadowRoot.querySelector('#flErstbewilligungAV')
    const erstbewilligungPV = bewilligungenShadowRoot.querySelector('#flErstbewilligungPV')
    const aufhebungAV = bewilligungenShadowRoot.querySelector('#flAufhebungAV')
    const aufhebungPV = bewilligungenShadowRoot.querySelector('#flAufhebungPV')

    const data = {
      flErstbewilligungAV: erstbewilligungAV.getValue(),
      flErstbewilligungPV: erstbewilligungPV.getValue(),
      flAufhebungAV: aufhebungAV.getValue(),
      flAufhebungPV: aufhebungPV.getValue(),
    }

    this.gesuchId = getNavigationId()

    this.uebersichtService.updateFlDate(this.gesuchId, data)
      .then(uebersicht => {
        this.uebersicht = uebersicht
        this.render()
      })
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BewilligungenUebersicht/i18n.json'
  }

  get link() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${geschaeftsVorfallNavigationLinks.bewilligung.hash}/${getNavigationId()}`
  }

   _isSnapshot() {
    return getNavigationHash().includes('Snapshots')
  }
}
customElements.define('bewilligungen-uebersicht', BewilligungenUebersicht)
