import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import NumberCell from "@isceco/widget-library2/basic-elements/List/cell/NumberCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import Options from "../../../../common/Options";

export default class StatistikdatenHistoryColumns {

  static getColumns() {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new GenericColumn({
        key: 'year',
        text: 'geschaeftsvorfall.audit.statistikdaten.berichtsjahr',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'av_anzahl_schweizer',
        text: 'geschaeftsvorfall.audit.statistikdaten.av.schweizer',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'av_anzahl_schweizerinnen',
        text: 'geschaeftsvorfall.audit.statistikdaten.av.schweizerinnen',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'av_anzahl_auslaender',
        text: 'geschaeftsvorfall.audit.statistikdaten.av.auslaender',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'av_anzahl_auslaenderinnen',
        text: 'geschaeftsvorfall.audit.statistikdaten.av.auslaenderinnen',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'online_placement',
        text: 'geschaeftsvorfall.audit.statistikdaten.onlinePlacement',
        sortable: false,
        cell: new SelectCell({items: Options.trueFalse})
      }),
      new GenericColumn({
        key: 'pv_anzahl_schweizer',
        text: 'geschaeftsvorfall.audit.statistikdaten.pv.schweizer',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'pv_anzahl_schweizerinnen',
        text: 'geschaeftsvorfall.audit.statistikdaten.pv.schweizerinnen',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'pv_anzahl_auslaender',
        text: 'geschaeftsvorfall.audit.statistikdaten.pv.auslaender',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'pv_anzahl_auslaenderinnen',
        text: 'geschaeftsvorfall.audit.statistikdaten.pv.auslaenderinnen',
        sortable: false,
        cell: new NumberCell({digits: 0})
      })
      ,
      new GenericColumn({
        key: 'geleistete_einsatzstunden',
        text: 'geschaeftsvorfall.audit.statistikdaten.geleisteteEinsatzstunden',
        sortable: false,
        cell: new NumberCell({digits: 0})
      }),
      new GenericColumn({
        key: 'freigegeben',
        text: 'geschaeftsvorfall.audit.statistikdaten.freigegeben',
        sortable: false,
        cell: new SelectCell({items: Options.trueFalse})
      })
    ]
  }
}
