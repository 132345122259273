import {html} from '@isceco/widget-library2/external/lit'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import TranslationElement from '../../../../common/TranslationElement'

export default class ZusammenarbeitsverbotUebersicht extends TranslationElement {

  static get properties() {
    return {
      zusammenarbeitsverbot: {type: Boolean},
      gesuchId: {type: String}
    }
  }

  render() {
    const value = this.zusammenarbeitsverbot ? this.i18n.translate('yes') : this.i18n.translate('no')
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('title')}"
        resource="${geschaeftsVorfallNavigationLinks.notiz.hash}/${this.gesuchId}"
      ></vzavg-link>
      <p>${value}</p>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/ZusammenarbeitsverbotUebersicht/i18n.json'
  }
}
customElements.define('zusammenarbeitsverbot-uebersicht', ZusammenarbeitsverbotUebersicht)
