import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import './Navigation/SnapshotsNavigation.js'
import './Snapshot.js'
import SnapshotService from '../../../services/SnapshotService.js'
import '../../../components/Betrieb/BetriebInfo.js'
import '../../../components/Snapshot/SnapshotInfo.js'
import GesuchService from "../../../services/GesuchService";


export default class Snapshots extends WebComponent {

  get css() {
    return css`
          .title-content .status {
            font-size: 1.5em;
            margin-top: 0.5em;
          }
        `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Snapshots/i18n.json'
  }

  constructor() {
    super();
    this.gesuchService = new GesuchService()
    this.snapshotService = new SnapshotService()
  }

  connectedCallback() {
    super.connectedCallback()
    this.betriebId = sessionStorage.getItem('betriebId')
    this.gesuchId = getNavigationId()

    this.gesuchService.read(this.gesuchId, 'betrieb').then(betrieb => {
      if (!isNoDataFound(betrieb)) {
        window.betrieb$.setValue(betrieb)
      } else {
        window.betrieb$.setValue(null)
      }
    })

    this.snapshotService.getSnapshotsMetadata(this.betriebId).then(metadatas => {
      this.metadatas = metadatas
      this.render()
    })
  }

  getTemplate() {
    return html`
      <vzavg-betrieb-info></vzavg-betrieb-info>
      <vzavg-title text="${this.i18n.translate('geschaeftsvorfall.snapshots.infos.header')}">
        <div slot="right" class="title-content">
          <isceco-button style="margin-bottom: 8px;"
            id="historyVerlassen"
            text="${this.i18n.translate('geschaeftsvorfall.snapshots.verlassen')}"
            variant="secondary"
            @click="${() => navigate({to: geschaeftsVorfallNavigationLinks.uebersicht.hash, id: `${this.gesuchId}`})}"
          ></isceco-button>
        </div>
      </vzavg-title>
      <div style="display: flex; overflow: auto">
        <div style="flex-grow: 0; margin-top: 30px;">
          <vzavg-frontend-snapshots-navigation datas="${JSON.stringify(this.metadatas)}"></vzavg-frontend-snapshots-navigation>
        </div>
        <div id="snapshotContent" style="flex-grow: 1; margin-top: 10px; margin-left: 25px;">
          <vzavg-snapshot-info></vzavg-snapshot-info>
          <vzavg-frontend-geschaeftsvorfall-snapshot id="${this.metadatas[0].snapshotId}">
          </vzavg-frontend-geschaeftsvorfall-snapshot>
        </div>
      </div>
    `
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-snapshots', Snapshots)
