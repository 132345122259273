import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import TranslationElement from '../../../../common/TranslationElement.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall.js'

export default class SonderpreisUebersicht extends TranslationElement {
  static get properties() {
    return {
      gesuchId: {type: String},
      value: {type: String}
    }
  }

  constructor() {
    super()
    this.value = 'no'
  }

  render() {
    return html`
      ${this._renderTitle()}
      <p>${this.i18n.translate(this.value ? this.value : 'no')}</p>
    `
  }

  _renderTitle() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.sonderpreis.title')}"
        resource="${geschaeftsVorfallNavigationLinks.entscheid.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/SonderpreisUebersicht/i18n.json'
  }
}
customElements.define('sonderpeis-uebersicht', SonderpreisUebersicht)
