import BaseService from '../BaseService'

export default class SnapshotService extends BaseService {
  constructor() {
    super('snapshot')
  }

  async getCount(betriebId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/snapshots/count/betrieb/${betriebId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async getSnapshotsMetadata(betriebId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/snapshots-metadata/betrieb/${betriebId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async getSnapshot(snapshotId, betriebId) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${snapshotId}/betrieb/${betriebId}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }
}
