import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button'
import {geschaeftsVorfallNavigationLinks} from '../../../Geschaeftsvorfall/Geschaeftsvorfall'

export default class GlobalSearchLinkCell {

  render(i18n, cell, row) {
    return html`
      <a href="${this._getLink(row)}" target="_blank"> ${row.typ} ${i18n.translate("suchen.result.open")} <i class="icon external alternate"></i></a>
    `
  }

  _getLink(row) {
    const baseUrl = location.origin + location.pathname + location.search
    switch (row.typeKey) {
      case "betrieb":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.betrieb.hash}/${row.gesuchId}`
      case "adresse":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.adresseEdit.hash}/${row.gesuchId}/${row.id}`
      case "verantwortlichep":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash}/${row.gesuchId}/${row.id}`
      case "kaution":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.kautionEdit.hash}/${row.gesuchId}/${row.id}`
      case "notiz":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.notizEdit.hash}/${row.gesuchId}/${row.id}`
      case "pendenz":
        return `${baseUrl}#${geschaeftsVorfallNavigationLinks.pendenzEdit.hash}/${row.gesuchId}/${row.id}`
      default:
        return ""
    }
  }
}
