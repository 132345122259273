import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import DateColumn from '@isceco/widget-library2/basic-elements/List/column/DateColumn.js';
import VerantwortlichePersonPrioCell from './VerantwortlichePersonPrioCell';
import VerantwortlichePersonTaetigBisCell from './VerantwortlichePersonTaetigBisCell';
import DefaultStatusCell from '../../../common/list/DefaultStatusCell.js';


export default class VerantwortlichePersonColumns {

  static STATUS_COLUMN() {
    return new GenericColumn({
      key: 'status',
      text: 'geschaeftsvorfall.verantwortliche.person.status',
      cell: new DefaultStatusCell(),
      align: 'center'
    })
  }

  /**
   * Returns Prio Column
   * @param {int} maxPrio
   * @returns {GenericColumn}
   * @constructor
   */
  static PRIO_COLUMN(maxPrio) {
    return new GenericColumn({
      key: 'prio',
      text: 'geschaeftsvorfall.verantwortliche.person.prio',
      cell: new VerantwortlichePersonPrioCell(maxPrio),
      weight: 2
    })
  }

  static NAME_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'geschaeftsvorfall.verantwortliche.person.name',
      weight: 2
    })
  }

  static VORNAME_COLUMN() {
    return new StringColumn({
      key: 'vorname',
      text: 'geschaeftsvorfall.verantwortliche.person.vorname',
      weight: 2
    })
  }

  static GEB_DATUM_COLUMN() {
    return new DateColumn({
      key: 'gebDatum',
      text: 'geschaeftsvorfall.verantwortliche.person.gebdatum',
      weight: 2
    })
  }

  static TAETIG_VON_COLUMN() {
    return new DateColumn({
      key: 'taetigVon',
      text: 'geschaeftsvorfall.verantwortliche.person.tvon',
      weight: 2
    })
  }

  static TAETIG_BIS_COLUMN(configuration) {
    const {hasWriteAccess, personObject} = configuration
    return new GenericColumn({
      key: 'taetigBis',
      text: 'geschaeftsvorfall.verantwortliche.person.tbis',
      weight: 2,
      cell: new VerantwortlichePersonTaetigBisCell({
        hasWriteAccess,
        personObject
      })
    })
  }

  static PERSON_STATUS_COLUMN() {
    return new StringColumn({
      key: 'personStatus',
      text: 'geschaeftsvorfall.verantwortliche.person.pstatus'
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      weight: 0.5,
      align: 'right',
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons = []

          buttons.push(
            {
              action: 'edit',
              icon: 'clipboard',
              variant: 'primary',
              title: i18n.translate('geschaeftsvorfall.verantwortliche.person.actions.edit')
            },
            {
              action: 'history',
              icon: 'history',
              variant: 'primary',
              title: i18n.translate('geschaeftsvorfall.verantwortliche.person.actions.history')
            }
            )

          return buttons
        }
      })
    })
  }
}
