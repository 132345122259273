import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import Options from "../../../../common/Options";
import DateColumn from "@isceco/widget-library2/basic-elements/List/column/DateColumn.js";

export default class KautionHistoryColumns {

  static getColumns() {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new StringColumn({
        key: 'kautionsart',
        text: 'geschaeftsvorfall.audit.kaution.kautionsart',
        sortable: false
      }),
      new StringColumn({
        key: 'urkunden_nr',
        text: 'geschaeftsvorfall.audit.kaution.urkundenNr',
        sortable: false
      }),
      new StringColumn({
        key: 'kautions_betrag',
        text: 'geschaeftsvorfall.audit.kaution.kautionsbetrag',
        sortable: false
      }),
      new DateColumn({
        key: 'kautions_datum',
        text: 'geschaeftsvorfall.audit.kaution.kautionsdatum',
        sortable: false
      }),
      new DateColumn({
        key: 'befrist_bis',
        text: 'geschaeftsvorfall.audit.kaution.befristet.bis',
        sortable: false
      }),
      new DateColumn({
        key: 'blockiert_bis',
        text: 'geschaeftsvorfall.audit.kaution.blockiert.bis',
        sortable: false
      }),
      new DateColumn({
        key: 'sperrfrist',
        text: 'geschaeftsvorfall.audit.kaution.sperrfrist',
        sortable: false
      }),
      new GenericColumn({
        key: 'kaution_durch_hauptsitz',
        text: 'geschaeftsvorfall.audit.kaution.kautionDurchHauptsitz',
        sortable: false,
        cell: new SelectCell({items: Options.trueFalse})
      }),
      new StringColumn({
        key: 'untern_register_id',
        text: 'geschaeftsvorfall.audit.uid',
        sortable: false
      }),
      new StringColumn({
        key: 'name',
        text: 'geschaeftsvorfall.audit.name',
        sortable: false
      }),
      new StringColumn({
        key: 'adresszusatz',
        text: 'geschaeftsvorfall.audit.adresszusatz',
        sortable: false
      }),
      new StringColumn({
        key: 'strasse',
        text: 'geschaeftsvorfall.audit.strasse',
        sortable: false
      }),
      new StringColumn({
        key: 'plz',
        text: 'geschaeftsvorfall.audit.plz',
        sortable: false
      }),
      new StringColumn({
        key: 'ort',
        text: 'geschaeftsvorfall.audit.ort',
        sortable: false
      })
    ]
  }
}
