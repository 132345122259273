import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import {List} from '@isceco/widget-library2/basic-elements/List/List'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem'
import GesuchService from '../../../services/GesuchService';
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall/Geschaeftsvorfall'
import '@isceco/widget-library2/basic-elements/Form/Form'
import './Filter/BetriebFilter.js'
import './Filter/PendenzFilter.js'
import './Filter/NotizFilter.js'
import './Filter/VerantwortlichePFilter.js'
import './Filter/KautionFilter.js'
import './Filter/GesuchFilter.js'
import './Filter/BewilligungFilter.js'
import {suchenScopes} from '../Suchen.js'
import ExportService from '../../../services/ExportService.js'


export default class SuchenForm extends WebComponent {

  static FILTER_SEARCH_FORM = 'filter-search-form'

  static SAVE_DIALOG_ID = 'save-dialog'
  static SAVE_FILENAME_ID = 'save-filename'


  constructor(tile) {
    super();
    this.service = tile.service
    this.columns = tile.columns
    this.selectedScope = tile.scope
    this.selectedColumns = this.columns.filter(col => col.defaultDisplayed && !col.alwaysDisplayed).map(column => column.column.key)

    this.searchParam = {
      suchbegriff: undefined
    }
    this.areExtendedFiltersCollapsed = true
    this.searchFormChanged = true

    this.gesuchService = new GesuchService()
    this.exportService = new ExportService(`search/${this.selectedScope}/export`)

  }

  get searchDisabled() {
    return this.selectedScope === suchenScopes.global && this.searchParam.suchbegriff === '' || !this.searchParam.suchbegriff
  }

  get translationFile() {
    return './views/Suchen/Form/i18n.json'
  }

  get translationExtendedFilterButton() {
    return this.areExtendedFiltersCollapsed ? 'suchen.extended.searchCriteria.enable' : 'suchen.extended.searchCriteria.disable'
  }

  get iconExtendedFilterButton() {
    return this.areExtendedFiltersCollapsed ? 'caret down' : 'caret up'
  }

  get css() {
    return css`
      .search-field-wrapper {
        display: flex;
        gap: 1em;
        justify-content: space-between;
        margin-bottom: 2em;
        flex-wrap: wrap;
      }

      .search-field-wrapper isceco-text-input {
        flex: 1;
      }

      .search-field-wrapper isceco-button {
        align-self: flex-end;
      }


      .extended-filters-wrapper {
        display: flex;
        margin-bottom: 1em;
        flex-direction: column;
      }

      #show-extended-filters {
        align-self: flex-end;
      }

      .filter-wrapper {
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        align-items: center;
      }

      .list-information-wrapper {
        display: flex;
        gap: 1em;
        align-items: flex-end;
        margin-bottom: 1em;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .list-information-wrapper span {
        color: var(--isceco-color-blue-500);
        font-weight: bold;
        white-space: nowrap;
      }

      .save-dialog-input {
        width: calc(100% - 40px);
        display: inline-block;
      }

      .save-button {
        display: flex;
        justify-content: end;
        margin-top: 1em;
      }
    `
  }

  async connectedCallback() {
    super.connectedCallback()
    this.translationLoaded.then(_ => {
      this.columnsSettings = this.columns.filter(col => !col.alwaysDisplayed).map(column => ({
        value: column.column.key,
        name: this.i18n.translate(column.column.text)
      }))
    })

    this._getFilterFromUrl()
    document.addEventListener(List.EVENT_KEYS.LIST_UPDATED, this._updateDataSize)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(List.EVENT_KEYS.LIST_UPDATED, this._updateDataSize)
  }

  getTemplate() {
    return html`
      <div class="search-field-wrapper">
        <isceco-text-input
          id="betrieb-search-input"
          label="${this.i18n.translate('suchen.suchbegriff')}"
          type="text"
          value="${this.searchParam.suchbegriff}"
          maxlength="255"
          @change="${e => this._updateValue(e)}"
          @input="${_ => this._handleFormChange()}"
          @keydown="${e => this._handleInputKeydownEvent(e)}"
        ></isceco-text-input>
        <isceco-button
          id="betrieb-search-start-button"
          text="${this.i18n.translate('suchen.button')}"
          @click="${() => this._searchList()}"
        ></isceco-button>
        ${this.selectedScope === suchenScopes.betrieb ?
          html`
            <isceco-button
              id="gesuch-add-btn"
              title="${this.i18n.translate('suchen.gesuch.add.button')}"
              variant="primary"
              @click="${_ => this._addBetriebAndGesuch()}"
              id="gesuch-add-button"
              ?disabled="${isEmpty(sessionStorage.getItem('userRechtCodeKanton')) && AuthorizationFilter.hasKantonRole()}"
              text="${this.i18n.translate('suchen.gesuch.erfassen.label')}"
            ></isceco-button>
          ` : html``}
      </div>
      ${this._renderExtendedFilters()}
      ${this._renderResults()}
    `
  }

  _renderExtendedFilters() {
    return this.selectedScope !== suchenScopes.global ?
      html`
        <div class="extended-filters-wrapper">
          <isceco-button
            id="show-extended-filters"
            name="showExtendedFilters"
            variant="tertiary" size="small"
            text="${this.i18n.translate(this.translationExtendedFilterButton)}"
            icon="${this.iconExtendedFilterButton}"
            icon=""
            @click="${_ => {
              this.areExtendedFiltersCollapsed = !this.areExtendedFiltersCollapsed
              this.searchFormChanged = true
              this.render()
            }}"></isceco-button>
          <!--we need to hide to keep the previous selection-->
          <div ?hidden="${this.areExtendedFiltersCollapsed}">
            <isceco-form
              include-hidden="${true}"
              id="${SuchenForm.FILTER_SEARCH_FORM}"
              @click="${_ => this._checkForExtendedSearchChanges()}">
              <div slot="form-elements" class="filter-wrapper">
                ${this._getFilterFields()}
              </div>
            </isceco-form>
          </div>
        </div>
      ` : html``
  }

  _getFilterFromUrl() {
    this.areExtendedFiltersCollapsed = true
    this.searchFormChanged = true
    const urlSearchParams = new URLSearchParams(
      window.location.hash.includes('?') ? window.location.hash.split('?').pop() : ''
    );
    const params = Object.fromEntries(urlSearchParams.entries());

    // Convert "true" and "false" strings to boolean values
    const paramsWithBooleans = Object.fromEntries(
      Object.keys(params).map(key => {
        if (["true", "false"].includes(params[key])) {
          return [key, "true" === params[key]]
        }
        return [key, this._getArrayIfNeeded(key, params)]
      })
    )

    delete paramsWithBooleans.max
    delete paramsWithBooleans.order
    delete paramsWithBooleans.offset

    if (paramsWithBooleans && Object.keys(paramsWithBooleans).length > 0) {
      if (paramsWithBooleans.hasOwnProperty('suchbegriff')) {
        this.searchParam.suchbegriff = paramsWithBooleans.suchbegriff
        delete paramsWithBooleans.suchbegriff
      }
      if (Object.keys(paramsWithBooleans).length > 0) {
        this.areExtendedFiltersCollapsed = false
      }
    }
    this.selectedFilterTemplate = {filters: paramsWithBooleans}
    if (!this.areExtendedFiltersCollapsed || this.searchParam.suchbegriff !== undefined) {
      this._searchList()
    }
  }

  _getArrayIfNeeded(key, params) {
    switch (key) {
      case 'grund':
        return params[key].split(',')
      case 'branche':
        return params[key].split(',')
      case 'qualifikationen':
        return params[key].split(',')
      default:
        return params[key]
    }
  }

  _getFilterFields() {
    switch (this.selectedScope) {
      case suchenScopes.betrieb:
        return html`
          <vzavg-frontend-suche-betrieb-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-betrieb-filter>`
      case suchenScopes.gesuch:
        return html`
          <vzavg-frontend-suche-gesuch-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-gesuch-filter>`
      case suchenScopes.kaution:
        return html`
          <vzavg-frontend-suche-kaution-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-kaution-filter>`
      case suchenScopes.pendenz:
        return html`
          <vzavg-frontend-suche-pendenz-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-pendenz-filter>`
      case suchenScopes.notiz:
        return html`
          <vzavg-frontend-suche-notiz-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-notiz-filter>`
      case suchenScopes.bewilligung:
        return html`
          <vzavg-frontend-suche-bewilligung-filter
            .values="${this.selectedFilterTemplate?.filters}"
          ></vzavg-frontend-suche-bewilligung-filter>
        `
      case suchenScopes.verantwortlichep:
        return html`
          <vzavg-frontend-suche-verantwortliche-person-filter
            .values="${this.selectedFilterTemplate?.filters}"></vzavg-frontend-suche-verantwortliche-person-filter>
        `
      default:
        return html``
    }
  }

  _updateDataSize = e => {
    this.dataSize = e.detail.dataSize
    this.render()
  }

  _updateSelectedColumns(e) {
    this.selectedColumns = e.detail.value ?? []
    this.render()
  }

  _updateValue(event) {
    this.searchParam.suchbegriff = event.detail.value
    this.render()
  }

  _handleInputKeydownEvent(e) {
    if (this.selectedScope === suchenScopes.global && e.key === 'Enter') {
      this.searchParam.suchbegriff = e.target.value
      this._searchList()
    }
  }

  _searchList() {
    const form = document.getElementById(SuchenForm.FILTER_SEARCH_FORM)
    const formValues = form?.getValues()
    const values = this.areExtendedFiltersCollapsed ? {
      ...this._createNewObjectWithNullProperties(formValues)
    } : formValues
    const suchbegriff = this.searchParam.suchbegriff
    this.searchParam = {...values}
    this.searchParam.suchbegriff = suchbegriff ? suchbegriff : null
    if (this.searchParam.suchbegriff === null && this.selectedScope === suchenScopes.global) {
      return;
    }
    this.searchFormChanged = false
    this.render()

    send(List.EVENT_KEYS.RELOAD_TRIGGERED, {params: this.searchParam})
  }

  _createNewObjectWithNullProperties(obj) {
    const newObj = {}
    if (!obj) {
      return newObj
    }
    for (const prop in obj) {
      newObj[prop] = null
    }
    return newObj
  }

  _addBetriebAndGesuch() {
    navigate({to: geschaeftsVorfallNavigationLinks.betrieb.hash, id: '0'})
  }

  _renderResults() {
    return html`
      <isceco-title text="${this.i18n.translate("suchen.result.title")}" size="medium"></isceco-title>
      <div ?hidden="${!this.searchFormChanged}">
        ${this.i18n.translate("suchen.result.help")}
      </div>
      <div class="result" ?hidden="${this.searchFormChanged}">
        <div class="list-information-wrapper">
          ${
            this.selectedScope ? html`
              <isceco-dropdown-multiselect
                label="${this.i18n.translate('suchen.result.settings')}"
                id="columnsSettings"
                name="columnsSettings"
                .items="${this.columnsSettings}"
                .value="${this.selectedColumns}"
                @change="${e => this._updateSelectedColumns(e)}"
              ></isceco-dropdown-multiselect>
            ` : html``
          }
          <span>${this.dataSize ?? 0} Einträge gefunden</span>
        </div>
        <isceco-list
          id="search-result-list"
          max="10"
          order="desc"
          .i18n="${this.i18n}"
          .listService="${this.service}"
          .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
          .columns="${this.columns
            .filter(col => col.alwaysDisplayed || this.selectedColumns?.includes(col.column.key))
            .map(col => col.column)}"
        ></isceco-list>
        ${this.selectedScope !== suchenScopes.global ? html`
          <isceco-button
            class="save-button" icon="cloud download alternate" text="CSV"
            @click="${() => this._openSaveDialog()}"
          ></isceco-button>
        ` : html``}
        <isceco-dialog id="${SuchenForm.SAVE_DIALOG_ID}"
                       hidden
                       header="${this.i18n.translate('suchen.result.export.header')}"
                       description="${this.i18n.translate('suchen.result.export.description')}"
                       confirm-button="${this.i18n.translate('suchen.result.export.confirm')}"
                       Cancel-button="${this.i18n.translate('suchen.result.export.cancel')}"
                       @submit="${e => this._exportCsv(e)}"
        >
          <isceco-text-input
            id="${SuchenForm.SAVE_FILENAME_ID}"
            class="save-dialog-input"
            maxlength="30"
          ></isceco-text-input>
          .csv
        </isceco-dialog>
      </div>
    `
  }

  _handleFormChange() {
    this.searchFormChanged = true
    this.render()
  }

  _checkForExtendedSearchChanges() {
    if (!this.searchFormChanged) {
      const form = document.getElementById(SuchenForm.FILTER_SEARCH_FORM)
      this.searchFormChanged = form.hasChanges()
      if (this.searchFormChanged) {
        this.render()
      }
    }
  }

  _exportCsv(e) {

    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector(`#${SuchenForm.SAVE_FILENAME_ID}`).getValue())
      let uriParameters = window.location.hash.split('?').pop()
      let filterName = document.querySelector('#search-template-filters')?.valueText
      const filterId = document.querySelector('#search-template-filters')?.value
      if (filterId && !filterName){
        filterName = document.querySelector('#search-template-name-input').value
      }
      if (filterName) {
        uriParameters = `${uriParameters}&filterName=${filterName}`
      }

      this.exportService.getExport(filename, uriParameters)
        .then(response => response.blob())
        .then(blob => download(`${filename}.csv`, blob));
    }
    e.target.setAttribute('hidden', '')
  }

  _openSaveDialog() {
    const dialog = document.querySelector(`#${SuchenForm.SAVE_DIALOG_ID}`)
    dialog.querySelector(`#${SuchenForm.SAVE_FILENAME_ID}`).value = this._getFilenameSuggestion()
    dialog.removeAttribute('hidden')
  }

  _getFilenameSuggestion() {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
    const scope = this.selectedScope === '' ? '' : `_${this.selectedScope}`
    return `${formattedDate}${scope}`
  }


}
customElements.define('vzavg-frontend-suchen-form', SuchenForm)
