import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import DateTimeCell from "@isceco/widget-library2/basic-elements/List/cell/DateTimeCell";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import DateColumn from "@isceco/widget-library2/basic-elements/List/column/DateColumn";
import SelectCell from "@isceco/widget-library2/basic-elements/List/cell/SelectCell";
import Options from "../../../../common/Options";

export default class AdresseHistoryColumns {

  static getColumns() {
    return [
      new GenericColumn({
        key: 'lastModificationTime',
        text: 'geschaeftsvorfall.audit.history.list.timestamp',
        sortable: false,
        cell: new DateTimeCell()
      }),
      new StringColumn({
        key: 'lastModifiedBy',
        text: 'geschaeftsvorfall.audit.history.list.editor',
        sortable: false
      }),
      new StringColumn({
        key: 'adresseTyp',
        text: 'geschaeftsvorfall.audit.adresse.typ',
        sortable: false
      }),
      new GenericColumn({
        key: 'rs_von_hs_uebernehmen',
        text: 'geschaeftsvorfall.audit.adresse.rsVonHsUebernehmen',
        sortable: false,
        cell: new SelectCell({items: Options.trueFalse})
      }),
      new StringColumn({
        key: 'untern_register_id',
        text: 'geschaeftsvorfall.audit.uid',
        sortable: false
      }),
      new StringColumn({
        key: 'firmenbezeichnung',
        text: 'geschaeftsvorfall.audit.betrieb.firmenbezeichnung',
        sortable: false
      }),
      new StringColumn({
        key: 'strasse',
        text: 'geschaeftsvorfall.audit.strasse',
        sortable: false
      }),
      new StringColumn({
        key: 'adresszusatz',
        text: 'geschaeftsvorfall.audit.adresszusatz',
        sortable: false
      }),
      new StringColumn({
        key: 'postfach',
        text: 'geschaeftsvorfall.audit.postfach',
        sortable: false
      }),
      new StringColumn({
        key: 'plz',
        text: 'geschaeftsvorfall.audit.plz',
        sortable: false
      }),
      new StringColumn({
        key: 'ort',
        text: 'geschaeftsvorfall.audit.ort',
        sortable: false
      }),
      new StringColumn({
        key: 'telefon',
        text: 'geschaeftsvorfall.audit.telefon',
        sortable: false
      }),
      new StringColumn({
        key: 'email',
        text: 'geschaeftsvorfall.audit.email',
        sortable: false
      }),
      new StringColumn({
        key: 'web',
        text: 'geschaeftsvorfall.audit.web',
        sortable: false
      }),
      new DateColumn({
        key: 'von',
        text: 'geschaeftsvorfall.audit.adresse.gueltigVon',
        sortable: false
      }),
      new DateColumn({
        key: 'bis',
        text: 'geschaeftsvorfall.audit.adresse.gueltigBis',
        sortable: false
      })
    ]
  }
}
