import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Card/Card.js'
import {List} from '@isceco/widget-library2/basic-elements/List/List.js'
import WebComponent from '../../WebComponent.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import CockpitService from '../../services/CockpitService.js'
import GesuchService from '../../services/GesuchService.js'
import DefaultMobileItem from '../../components/DefaultMobileItem/DefaultMobileItem.js'
import VzavgListService from '../../services/VzavgListService.js'
import CockpitGesucheColumns from './CockpitGesucheColumns.js'
import CockpitPendenzenColumns from './CockpitPendenzenColumns.js'
import I18n from '../../i18n.js';
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall/Geschaeftsvorfall.js'
import PendenzService from '../../services/PendenzService.js'
import KautionService from '../../services/KautionService.js'
import CockpitKautionenColumns from './CockpitKautionenColumns.js'
import './CockpitStatistikdaten/CockpitStatistikdaten.js'
import BetriebService from "../../services/BetriebService.js"

export default class Cockpit extends WebComponent {

  static IDENTIFIER_GESUCHE_COCKPIT = 'gesuchecockpit'
  static TRANSLATION_KEY_COCKPIT_CARDS_SHOW = 'cockpit.cards.show'

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'cockpit-reload-triggered'
    }
  }

  get translationFile() {
    return './views/Cockpit/i18n.json'
  }

  get css() {
    return css`
      vzavg-frontend-cockpit {
        overflow: auto;
        padding: 2px;
      }

      div {
        text-align: left;
      }

      .cardContainer {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
      }

      .card {
        border-radius: 20px !Important;
      }

      a {
        cursor: pointer;
      }
    `
  }

  constructor() {
    super();
    this.service = new CockpitService()
    this.gesuchService = new GesuchService()
    this.pendenzService = new PendenzService()
    this.kautionService = new KautionService()
    this.betriebService = new BetriebService()

    this.pendenzen = {
      dueInDaysValue: 5,
      total: {
        count: 0
      },
      user: {
        count: 0
      },
      dueInDays: {
        count: 0
      }
    }

    this.kautionen = {
      sperrfristDueInDaysValue: 5,
      ablaufDueInDaysValue: 60,
      sperrfrist: {
        count: 0
      },
      ablauf: {
        count: 0
      },
      zuPruefende: {
        count: 0
      }
    }
  }

  connectedCallback() {
    super.connectedCallback()

    this._refresh = () => {
      send(Cockpit.EVENT_KEYS.RELOAD_TRIGGERED)
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.addEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)

    this.isKanton = AuthorizationFilter.hasKantonRole()
    this.isSeco = AuthorizationFilter.hasSecoRole()

    this.columnsGesucheKanton = [
      CockpitGesucheColumns.CREATION_TIME_COLUMN(),
      CockpitGesucheColumns.BETRIEB_COLUMN(),
      CockpitGesucheColumns.AENDERUMGSGRUND_COLUMN(),
      CockpitGesucheColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuGesuche
      })
    ]

    this.columnsGesucheSeco = [
      CockpitGesucheColumns.CREATION_TIME_COLUMN(),
      CockpitGesucheColumns.KANTON_COLUMN(),
      CockpitGesucheColumns.BETRIEB_COLUMN(),
      CockpitGesucheColumns.AENDERUMGSGRUND_COLUMN(),
      CockpitGesucheColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuGesuche
      })
    ]

    this.columnsPendenzen = [
      CockpitPendenzenColumns.TERMIN_COLUMN(),
      CockpitPendenzenColumns.EINGANGSDATUM_COLUMN(),
      CockpitPendenzenColumns.BETRIEB_COLUMN(),
      CockpitPendenzenColumns.BETREFF_COLUMN(),
      CockpitPendenzenColumns.SENDER_COLUMN(),
      CockpitPendenzenColumns.EMPFAENGER_COLUMN(),
      CockpitPendenzenColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuPendenzen
      })
    ]

    this.columnsKautionenSperrfrist = [
      CockpitKautionenColumns.KAUTIONSDATUM_COLUMN(),
      CockpitKautionenColumns.BETRIEB_COLUMN(),
      CockpitKautionenColumns.KAUTIONSGEBER_COLUMN(),
      CockpitKautionenColumns.KAUTIONSART_COLUMN(),
      CockpitKautionenColumns.KAUTIONSBETRAG_COLUMN(),
      CockpitKautionenColumns.SPERRFRIST_COLUMN(),
      CockpitKautionenColumns.KAUTIONSSTATUS_COLUMN(),
      CockpitKautionenColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuKautionen
      })
    ]

    this.columnsKautionenAblauf = [
      CockpitKautionenColumns.KAUTIONSDATUM_COLUMN(),
      CockpitKautionenColumns.BETRIEB_COLUMN(),
      CockpitKautionenColumns.KAUTIONSGEBER_COLUMN(),
      CockpitKautionenColumns.KAUTIONSART_COLUMN(),
      CockpitKautionenColumns.KAUTIONSBETRAG_COLUMN(),
      CockpitKautionenColumns.BEFRIST_BIS_COLUMN(),
      CockpitKautionenColumns.KAUTIONSSTATUS_COLUMN(),
      CockpitKautionenColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuKautionen
      })
    ]

    this.columnsKautionenZuPruefende = [
      CockpitKautionenColumns.KAUTIONSKANTON_COLUMN(),
      CockpitKautionenColumns.BETRIEB_COLUMN(),
      CockpitKautionenColumns.KAUTIONSORT_COLUMN(),
      CockpitKautionenColumns.KAUTIONSUMME_COLUMN(),
      CockpitKautionenColumns.BENOETIGTEKAUTIONSUMME_COLUMN(),
      CockpitKautionenColumns.AKTIONEN_COLUMN({
        callback: this._rowActionMenuZuPruefendeKautionen
      })
    ]

    let stateOpen = 'kantonOffen'
    let stateInBearbeitung = 'kantonInBearbeitung'
    if (this.isSeco) {
      stateOpen = 'secoOffen'
      stateInBearbeitung = 'secoInBearbeitung'
    }

    Promise.all(
      [
        this.gesuchService.getCountByState('status', stateOpen),
        this.gesuchService.getCountByState('status', stateInBearbeitung),
        this.gesuchService.getBackendListUrl(),
        this.pendenzService.getBackendListUrl(),
        this.kautionService.getBackendListUrl(),
        this.betriebService.getBackendListUrl()
      ]
    ).then((
      [
        resGesuchCountByStateOffen,
        resGesuchCountByStateInBearbeitung,
        resGesuchBackendListUrl,
        resPendenzServiceListUrl,
        resKautionServiceListUrl,
        resBetriebServiceListUrl
      ]
    ) => {
      this.gesucheInBearbeitung = resGesuchCountByStateInBearbeitung.count
      this.gesuchePendent = resGesuchCountByStateOffen.count
      this.gesucheUrl = resGesuchBackendListUrl
      this.pendenzUrl = resPendenzServiceListUrl
      this.kautionUrl = resKautionServiceListUrl
      this.betriebUrl = resBetriebServiceListUrl

      this.render()

    }).catch(_ => {
      this.reload()
    })

  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(List.EVENT_KEYS.LIST_UPDATED, this._listUpdated)
  }

  getTemplate() {
    const gesuchTranslation = this.i18n.translate('cockpit.header.gesuche')
    return html`

      <!-- News -->

      <isceco-title text=${this.i18n.translate('cockpit.header.news')}></isceco-title>
      <div class="cardContainer">
        <isceco-card class="card"
                     text="${this.i18n.translate('cockpit.header.news.card.anzahl')}">
          <p>content</p>
          <a href="#News">${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}</a>
        </isceco-card>
      </div>

      <!-- Pendenzen -->

      ${this._getPendenzenTemplate()}

      <!-- Gesuche -->

      <isceco-title text=${gesuchTranslation}>

      </isceco-title>
      <div class="cardContainer">
        <isceco-card
          id="card-gesuche-inbearbeitung"
          class="card"
          text="${gesuchTranslation} ${this.gesucheInBearbeitung}">
          <p>${this.i18n.translate('cockpit.gesuche.in.bearbeitung')}</p>
          <isceco-link
            id="link-gesuche-inbearbeitung"
            icon="hand point down"
            text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
            @click="${_ => this._showHideList('gesucheListDiv')}">
          </isceco-link>
          <div id="gesucheListDiv" hidden>
            <isceco-list
              id="gesuche-list-in-bearbeitung"
              max="10"
              order="asc"
              .listService="${new VzavgListService(this.getInbearbeitungGesuchUrl())}"
              identifier="${Cockpit.IDENTIFIER_GESUCHE_COCKPIT}"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${AuthorizationFilter.hasSecoRole() ? this.columnsGesucheSeco : this.columnsGesucheKanton}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>
        <isceco-card
          id="card-gesuche-pendent"
          class="card"
          text="${gesuchTranslation} ${this.gesuchePendent}">
          <p>${this.i18n.translate('cockpit.gesuche.pendent')}</p>
          <isceco-link
            id="link-gesuche-pendent"
            icon="hand point down"
            text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
            @click="${_ => this._showHideList('gesucheListDivPendent')}">
          </isceco-link>
          <div id="gesucheListDivPendent" hidden>
            <isceco-list
              id="gesuche-list-pendent"
              max="10"
              order="asc"
              .listService="${new VzavgListService(this.getPendentGesuchUrl())}"
              identifier="${Cockpit.IDENTIFIER_GESUCHE_COCKPIT}"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${AuthorizationFilter.hasSecoRole() ? this.columnsGesucheSeco : this.columnsGesucheKanton}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>
      </div>

      ${this.isKanton ? this._getKautionenTemplate() : html``}

      <vzavg-cockpit-statistikdaten></vzavg-cockpit-statistikdaten>
    `
  }

  _getPendenzenTemplate() {
    return html`
      <isceco-title text=${this.i18n.translate('cockpit.header.pendenzen')}></isceco-title>
      <div class="cardContainer">

        <isceco-card id="card-pendenzen-total" class="card"
                     text="${this.i18n.translate('cockpit.header.pendenzen.card.total')} ${this.pendenzen.total.count}">
          <p>
            ${this.pendenzen.total.oldest ? `${this.i18n.translate('cockpit.pendenzen.total.oldest')} ${formatDate(this.pendenzen.total.oldest)}` : ""}
          </p>
          <isceco-link id="link-pendenzen-total" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('pendenzenListDivTotal')}">
          </isceco-link>
          <div id="pendenzenListDivTotal" hidden>
            <isceco-list
              id="pendenzen-list-total"
              max="10"
              order="asc"
              .listService="${new VzavgListService(this.pendenzUrl)}"
              identifier="total"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsPendenzen}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>

        <isceco-card id="card-pendenzen-user" class="card"
                     text="${this.i18n.translate('cockpit.header.pendenzen.card.user')} ${this.pendenzen.user.count}">
          <p>
            ${this.pendenzen.user.oldest ? `${this.i18n.translate('cockpit.pendenzen.total.oldest')} ${formatDate(this.pendenzen.user.oldest)}` : ""}</p>
          <isceco-link id="link-pendenzen-user" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('pendenzenListDivUser')}">
          </isceco-link>
          <div id="pendenzenListDivUser" hidden>
            <isceco-list
              id="pendenzen-list-user"
              max="10"
              order="asc"
              .listService="${new VzavgListService(`${this.pendenzUrl}?user=true`)}"
              identifier="user"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsPendenzen}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>

        </isceco-card>

        <isceco-card id="card-pendenzen-dueindays" class="card"
                     text="${this.i18n.translate('cockpit.header.pendenzen.card.anzahl')} ${this.pendenzen.dueInDays.count}">
          <p>${this.i18n.translate('cockpit.pendenzen.total.dueInDays')}</p>
          <isceco-link id="link-pendenzen-dueindays" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('pendenzenListDivDueInDays')}">
          </isceco-link>
          <div id="pendenzenListDivDueInDays" hidden>
            <isceco-list
              id="pendenzen-list-dueindays"
              max="10"
              order="asc"
              .listService="${new VzavgListService(`${this.pendenzUrl}?dueInDays=${this.pendenzen.dueInDaysValue}`)}"
              identifier="dueInDays"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsPendenzen}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>

        </isceco-card>

      </div>
    `
  }

  _getKautionenTemplate() {
    return html`
      <isceco-title text=${this.i18n.translate('cockpit.header.kautionen')}></isceco-title>
      <div class="cardContainer">

        <isceco-card id="card-kautionen-sperrfrist" class="card"
                     text="${this.i18n.translate('cockpit.kautionen.expiration.lockout')} ${this.kautionen.sperrfrist.count}">
          <p>
            ${this.i18n.translate('cockpit.kautionen.expiration.lockout.5.days')}
          </p>
          <isceco-link id="link-kautionen-sperrfrist" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('kautionenListDivSperrfrist')}">
          </isceco-link>
          <div id="kautionenListDivSperrfrist" hidden>
            <isceco-list
              id="kautionen-list-sperrfrist"
              max="10"
              order="asc"
              .listService="${new VzavgListService(`${this.kautionUrl}?sperrfristDueInDays=${this.kautionen.sperrfristDueInDaysValue}`)}"
              identifier="sperrfrist"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsKautionenSperrfrist}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>

        <isceco-card id="card-kautionen-ablauf" class="card"
                     text="${this.i18n.translate('cockpit.kautionen.expiration')} ${this.kautionen.ablauf.count}">
          <p>
            ${this.i18n.translate('cockpit.kautionen.expiration.60.days')}
          </p>
          <isceco-link id="link-kautionen-ablauf" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('kautionenListDivAblauf')}">
          </isceco-link>
          <div id="kautionenListDivAblauf" hidden>
            <isceco-list
              id="kautionen-list-ablauf"
              max="10"
              order="asc"
              .listService="${new VzavgListService(`${this.kautionUrl}?ablaufDueInDays=${this.kautionen.ablaufDueInDaysValue}`)}"
              identifier="ablauf"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsKautionenAblauf}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>

        <isceco-card
          id="card-kautionen-zupruefende"
          class="card"
          text="${this.i18n.translate('cockpit.kautionen.zu.prufende.kautionen')} ${this.kautionen.zuPruefende.count}">
          <isceco-link id="link-kautionen-zupruefende" icon="hand point down"
                       text="${this.i18n.translate(Cockpit.TRANSLATION_KEY_COCKPIT_CARDS_SHOW)}"
                       @click="${_ => this._showHideList('kautionenListDivZuPruefende')}">
          </isceco-link>
          <div id="kautionenListDivZuPruefende" hidden>
            <isceco-list
              id="kautionen-list-zupruefende"
              max="10"
              order="asc"
              .listService="${new VzavgListService(`${this.betriebUrl}/zuPruefendeKautionen`)}"
              identifier="ablauf"
              update-event="${Cockpit.EVENT_KEYS.RELOAD_TRIGGERED}"
              .columns="${this.columnsKautionenZuPruefende}"
              .i18n="${this.i18n}"
              .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}">
            </isceco-list>
          </div>
        </isceco-card>
      </div>
    `
  }

  getInbearbeitungGesuchUrl() {
    let inBearbeitungState = 'kantonInBearbeitung'
    if (AuthorizationFilter.hasSecoRole()) {
      inBearbeitungState = 'secoInBearbeitung'
    }
    return `${this.gesucheUrl}?${Cockpit.IDENTIFIER_GESUCHE_COCKPIT}-STATE=${inBearbeitungState}`;
  }

  getPendentGesuchUrl() {
    let openState = 'kantonOffen'
    if (AuthorizationFilter.hasSecoRole()) {
      openState = 'secoOffen'
    }
    return `${this.gesucheUrl}?${Cockpit.IDENTIFIER_GESUCHE_COCKPIT}-STATE=${openState}`;
  }

  _showHideList(id) {
    if (document.getElementById(id).getAttribute('hidden') === '') {
      document.getElementById(id).removeAttribute('hidden')
    } else {
      document.getElementById(id).setAttribute('hidden', '')
    }
    if (id === 'gesucheListDiv') {
      document.getElementById('card-gesuche-inbearbeitung').scrollIntoView()
    } else if (id?.startsWith('pendenzenListDiv')) {
      const postfix = id.split("Div")[1].toLowerCase()
      document.getElementById(`card-pendenzen-${postfix}`).scrollIntoView()
    } else if (id?.startsWith('kautionenListDiv')) {
      const postfix = id.split("Div")[1].toLowerCase()
      document.getElementById(`card-kautionen-${postfix}`).scrollIntoView()
    } else {
      document.getElementById('card-gesuche-pendent').scrollIntoView()
    }
  }

  _listUpdated = e => {
    if (e.target.id.startsWith("pendenzen-list")) {
      if (e.target.id === "pendenzen-list-total") {
        this.pendenzen.total.count = e.detail.dataSize
        this.pendenzService.findOldestOpen().then(p => {
          this.pendenzen.total.oldest = p.eingangsdatum
          this.render()
        })
      }
      if (e.target.id === "pendenzen-list-user") {
        this.pendenzen.user.count = e.detail.dataSize
        this.pendenzService.findOldestOpenUser(true).then(p => {
          this.pendenzen.user.oldest = p.eingangsdatum
          this.render()
        })
      }
      if (e.target.id === "pendenzen-list-dueindays") {
        this.pendenzen.dueInDays.count = e.detail.dataSize
      }
      this.render()
    }
    if (e.target.id.startsWith("kautionen-list")) {
      if (e.target.id === "kautionen-list-sperrfrist") {
        this.kautionen.sperrfrist.count = e.detail.dataSize
        this.render()
      }
      if (e.target.id === "kautionen-list-ablauf") {
        this.kautionen.ablauf.count = e.detail.dataSize
        this.render()
      }
      if (e.target.id === "kautionen-list-zupruefende") {
        this.kautionen.zuPruefende.count = e.detail.dataSize
        this.render()
      }
    }
  }

  _rowActionMenuGesuche = (row, _) => {
    navigate({to: geschaeftsVorfallNavigationLinks.betrieb.hash, id: row.id})
  }

  _rowActionMenuPendenzen = (row, _) => {
    navigate({to: geschaeftsVorfallNavigationLinks.pendenzEdit.hash, id: `${row.gesuchId}/${row.id}`})
  }

  _rowActionMenuKautionen = (row, _) => {
    navigate({to: geschaeftsVorfallNavigationLinks.kautionEdit.hash, id: `${row.gesuchId}/${row.id}`})
  }

  _rowActionMenuZuPruefendeKautionen = (row, _) => {
    navigate({to: geschaeftsVorfallNavigationLinks.kaution.hash, id: `${row.gesuchId}`})
  }

}
customElements.define('vzavg-frontend-cockpit', Cockpit)
