import WebComponent from "../../../WebComponent";
import {html, css} from "@isceco/widget-library2/external/lit";
import VzavgListService from "../../../services/VzavgListService";
import DefaultMobileItem from "../../../components/DefaultMobileItem/DefaultMobileItem";

export default class Audit extends WebComponent {
  get translationFile() {
    return './views/Geschaeftsvorfall/Audit/i18n.json'
  }

  constructor(tile) {
    super()
    this.service = tile.service
    this.backUrl = tile.backUrl
    this.columns = tile.columns.getColumns(this.i18n)
  }

  get css() {
    return css`
      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()

    this.gesuchId = getNavigationId()
    this.entityId = window.location.hash.split('?')[0].split('/')[2]

    this.service.getBackendListUrl(`${this.entityId}/history`)
      .then(url => this.url = url)
      .then(_ => this.render())
  }

  getTemplate() {
    return html`
      <div class="buttons">
        <isceco-button
          id="exit-history"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.audit.button.history.back')}"
          @click="${_ => navigate({to: this.backUrl, id: this.gesuchId})}"
        ></isceco-button>
      </div>
      <isceco-list
        id="history-list"
        .listService="${new VzavgListService(this.url)}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
      ></isceco-list>
    `
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-audit', Audit)
