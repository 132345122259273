import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import WebComponent from '../../../WebComponent.js'
import UebersichtService from '../../../services/UebersichtService.js'
import SnapshotService from '../../../services/SnapshotService.js'
import '../../../components/Uebersicht/AdresseElement.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import './BetriebUebersicht/BetriebUebersicht.js'
import './VerantwortlichePersonenUebersicht/VerantwortlichePersonenUebersicht.js'
import './BetriebsartUebersicht/BetriebsartUebersicht.js'
import './GeschaeftsstellenUebersicht/GeschaeftsstellenUebersicht.js'
import './KautionUebersicht/KautionUebersicht.js'
import './BranchenUebersicht/BranchenUebersicht.js'
import './QualifikationenUebersicht/QualifikationenUebersicht.js'
import './MarkenUebersicht/MarkenUebersicht.js'
import './SonderpreisUebersicht/SonderpreisUebersicht.js'
import './BewilligungenUebersicht/BewilligungenUebersicht.js'
import './PendenzenUebersicht/PendenzenUebersicht.js'
import './NotizenUebersicht/NotizenUebersicht.js'
import './ZusammenarbeitsverbotUebersicht/ZusammenarbeitsverbotUebersicht.js'
import './BestaetigungsdatumUebersicht/BestaetigungsdatumUebersicht.js'
import './StatistikendatenUebersicht/StatistikendatenUebersicht.js'
import ExportService from '../../../services/ExportService.js'
import Gesuch from '../Gesuch/Gesuch.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import '../../../components/Link/Link.js'
import GesuchService from '../../../services/GesuchService.js'

export default class Uebersicht extends WebComponent {

  static SAVE_FILENAME_ID = 'save-filename'

  constructor() {
    super()
    this.service = new GesuchService()
    this.uebersichtService = new UebersichtService()
    this.snapshotService = new SnapshotService()
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.exportService = new ExportService(`gesuch/${this.gesuchId}/export`)
    this.uebersicht = {}
    this.showHistory = false

    Promise.all([
      this._updateBetrieb(),
      this.uebersichtService.getUebersicht(this.gesuchId)
    ])
      .then(([_, uebersicht]) => {
        this.uebersicht = uebersicht;
        this.uebersicht.branchen = this._groupBranchen(this.uebersicht.branchen);

        this.snapshotService.getCount(this.uebersicht.betrieb.id).then(value => {
          this.showHistory = value > 0
          this.render()
        })
      })
  }

  _updateBetrieb() {
    return new Promise((resolve, reject) => {
      this.service.read(this.gesuchId, 'betrieb')
        .then(gesuchBetriebResponse => {
          // Check if HTTP response status is NoContent = 204, then initialize for new entry
          if (!isNoDataFound(gesuchBetriebResponse)) {
            window.betrieb$.setValue(gesuchBetriebResponse)
            sessionStorage.setItem('betriebId', gesuchBetriebResponse.id)
            resolve(gesuchBetriebResponse)  // Resolve the promise with the response data
          } else {
            resolve(null) // Resolve with null if no data is found
          }
        })
        .catch(error => {
          reject(error) // Reject the promise in case of an error
        })
    })
  }

  getTemplate() {
    return html`
      <vzavg-title text="${this.i18n.translate('geschaeftsvorfall.uebersicht.header')}">
        <div slot="right" class="title-content">
          ${this.uebersicht.canCreateAenderungsgesuch && (AuthorizationFilter.hasKantonRole() || AuthorizationFilter.hasSuperRole()) ?
            html`
              <isceco-button
                id="add-aenderungsgesuch"
                text="${this.i18n.translate('geschaeftsvorfall.uebersicht.add.aenderugsgesuch')}"
                title="${this.i18n.translate('geschaeftsvorfall.uebersicht.add.aenderugsgesuch')}"
                variant="primary"
                @click="${_ => this._handleAddAenderungsGesuchButton()}"
              ></isceco-button>
            ` : html``}
          <isceco-button
            id="datenHerunterladen"
            icon="download"
            text="${this.i18n.translate('geschaeftsvorfall.entscheid.datenHerunterladen')}"
            variant="secondary"
            @click="${() => this._openCvsExportDialog()}"
          ></isceco-button>
          ${this.showHistory ?
            html`
              <isceco-button
                id="history"
                icon="history"
                text="${this.i18n.translate('geschaeftsvorfall.entscheid.history')}"
                variant="secondary"
                @click="${() => navigate({to: geschaeftsVorfallNavigationLinks.snapshots.hash, id: `${this.gesuchId}`})}"
              ></isceco-button>
            ` : html``}
        </div>
      </vzavg-title>
      <div style="display:flex; flex-direction: column">
        <div class="column3">
          <div class="column">
            <betrieb-uebersicht
              .betrieb="${this.uebersicht.betrieb}"
              gesuchId="${this.gesuchId}"
            ></betrieb-uebersicht>
            <verantwortlichepersonen-uebersicht
              .verantwortlichePersonen="${this.uebersicht.verantwortlichePersonen}"
              gesuchId="${this.gesuchId}"
            ></verantwortlichepersonen-uebersicht>
            <betriebsart-uebersicht
              betriebsart="${this.uebersicht.betriebsart?.bezeichnung}"
              gesuchId="${this.gesuchId}"></betriebsart-uebersicht>
            <adresse-element
              id="revisionsstelle-adresse"
              .adresse="${this.uebersicht.revisionsstelle}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <geschaeftsstellen-uebersicht
              .adressen="${this.uebersicht.geschaeftsstellen}" gesuchId="${this.gesuchId}"
            ></geschaeftsstellen-uebersicht>
            <kaution-uebersicht
              .kautionen="${this.uebersicht.kautions}"
            ></kaution-uebersicht>
          </div>
          <div class="column">
            <branchen-uebersicht
              .branchen="${this.uebersicht.branchen}"
              gesuchId="${this.gesuchId}"
            ></branchen-uebersicht>
            <qualifikationen-uebersicht
              .qualifikationen="${this.uebersicht.qualifikationen}"
              gesuchId="${this.gesuchId}"
            ></qualifikationen-uebersicht>
            <marken-uebersicht
              marken="${this.uebersicht.marken}"
              gesuchId="${this.gesuchId}"
            ></marken-uebersicht>
            ${AuthorizationFilter.hasKantonRole() ? html`` : html`
            <sonderpeis-uebersicht
              gesuchId="${this.gesuchId}"
              value="${this.uebersicht.sonderpreis}"
            ></sonderpeis-uebersicht>
            `}
            <bewilligungen-uebersicht
              .bewilligungs="${this.uebersicht.bewilligungs}"
            ></bewilligungen-uebersicht>
          </div>
          <div class="column">
            <adresse-element
              id="hauptsitz-adresse"
              .adresse="${this.uebersicht.hauptsitz}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <adresse-element
              id="revisionsstelle-hs-adresse"
              .adresse="${this.uebersicht.revisionsstelle_hauptsitz}"
              gesuchId="${this.gesuchId}"
              withTitle
            ></adresse-element>
            <pendenzen-uebersicht
              totalPendenzen="${this.uebersicht.pendenzen?.length ?? 0}"
              gesuchId="${this.gesuchId}"
            ></pendenzen-uebersicht>
            <notizen-uebersicht
              totalNotiz="${this.uebersicht.notizen?.length ?? 0}"
              gesuchId="${this.gesuchId}"
            ></notizen-uebersicht>
            <zusammenarbeitsverbot-uebersicht
              .zusammenarbeitsverbot="${this.uebersicht.zusammenarbeitsverbot}"
              gesuchId="${this.gesuchId}"
            ></zusammenarbeitsverbot-uebersicht>
            <bestaetigungsdatum-uebersicht
              gesuchId="${this.gesuchId}"
              bestaetigungsDatum="${this.uebersicht.bestaetigungsdatum}"
            ></bestaetigungsdatum-uebersicht>
            <statistikdaten-uebersicht
              gesuchId="${this.gesuchId}"
            ></statistikdaten-uebersicht>
          </div>
        </div>
      </div>
      <isceco-dialog
        id="cvs-export-dialog"
        hidden
        header="${this.i18n.translate('suchen.result.export.header')}"
        description="${this.i18n.translate('suchen.result.export.description')}"
        confirm-button="${this.i18n.translate('suchen.result.export.confirm')}"
        Cancel-button="${this.i18n.translate('suchen.result.export.cancel')}"
        @submit="${e => this._exportCsv(e)}"
      >
        <div style="display: flex; align-items: center;gap: 0.1em">
          <isceco-text-input
            id="${Uebersicht.SAVE_FILENAME_ID}"
            class="save-dialog-input"
            maxlength="30"
            style="flex: 1"
          ></isceco-text-input>
          <span>.csv</span>
        </div>
      </isceco-dialog>
    `
  }


  get css() {
    return css`
      .column3 {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
      }

      .column3:not(:last-child) {
        border-right: 1px solid black;
      }

      .column {
        display: flex;
        flex-direction: column;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `;
  }

  _handleAddAenderungsGesuchButton() {
    const params = new URLSearchParams()
    params.set('betriebId', this.uebersicht.betrieb.id)
    navigate({to: geschaeftsVorfallNavigationLinks.gesuch.hash, id: '0', params})
  }

  _openCvsExportDialog() {
    const dialog = document.querySelector('#cvs-export-dialog')
    let filename = "GV_"+ this.uebersicht.betrieb.firmenbezeichnung.replaceAll(' ', '_')
    if (filename.length > 50) {
      filename = filename.substring(0, 50)
    }
    dialog.querySelector(`#${Uebersicht.SAVE_FILENAME_ID}`).value = filename
    dialog.removeAttribute('hidden')
  }

  _groupBranchen(branchen) {
    const group = Gesuch.BRANCH_GROUP

    let isAllMemberPresent = true
    const branchenCodes = branchen.map(branch => branch.code)
    for (const member of group.members) {
      if (!branchenCodes.includes(member)) {
        isAllMemberPresent = false
        break
      }
    }

    if (!isAllMemberPresent || (isAllMemberPresent && branchen.length > group.members.length)) {
      return branchen
    }

    const rest = []
    rest.push({code: 'group', bezeichnung: this.i18n.translate('geschaeftsvorfall.gesuch.branchen.group')})
    for (const branch of branchen) {
      if (!group.members.includes(branch.code)) {
        rest.push(branch)
      }
    }
    return rest
  }

  _exportCsv(e) {
    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector(`#${Uebersicht.SAVE_FILENAME_ID}`).getValue())
      this.exportService.getExport(filename, window.location.hash.split('?').pop())
        .then(response => response.blob())
        .then(blob => download(`${filename}.csv`, blob));
    }
    e.target.setAttribute('hidden', '')
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-uebersicht', Uebersicht)
