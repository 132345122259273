export default class VzavgPattern {
  static get emailPattern() {
    return '^[^@\\s]+@[^@\\s]+\.[a-zA-Z0-9]{2,}$'
  }

  static get simpleTelefonPattern() {
    return '^[+]?[0-9\\s]{0,15}$'
  }

  static get swissTelefonPattern() {
    return '^(?:\\+|00|0)[\\d\\s]{9,15}$'
  }

  static get urlPattern() {
    return '(https:\\/\\/|http:\\/\\/)?[a-zA-Z0-9\\-]{2,}(\\.[a-zA-Z0-9\\-]{2,})+'
  }

  static get chfBetragIntPattern() {
    return '^\\d{1,3}(?:\'\\d{3})*$|^\\d+$'
  }

}
