import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'

export default class BranchenUebersicht extends TranslationElement {

  static get properties() {
    return {
      branchen: {type: Array},
      gesuchId: {type: String}
    }
  }
  render() {
    if(!this.branchen?.length) {
      return html`
      ${this._renderTitel()}
        <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.branchen.no.branchen')}</p>
    `
    }

    return html`
      ${this._renderTitel()}
      <ul>
        ${this.branchen?.map(branche => this._renderBranche(branche))}
      </ul>
    `
  }

  _renderBranche(branche) {
    return html`<li>${branche.bezeichnung}<li>`
  }

  static get styles() {
    return css`
      ul {
        list-style: none;
        padding-left: 0
      }
    `
  }

  _renderTitel() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.branchen.title')}"
        resource="${geschaeftsVorfallNavigationLinks.gesuch.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BranchenUebersicht/i18n.json'
  }


}
customElements.define('branchen-uebersicht', BranchenUebersicht)
