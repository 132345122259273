import {html} from '@isceco/widget-library2/external/lit'
import '../../../components/VerticalNavigation/VerticalTabNavigation.js'
import WebComponent from '../../../WebComponent.js'
import Navigation from '../../Navigation/Navigation.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import Authorization from '../../Authorization/Authorization.js'
import I18n from '../../../i18n.js'
import GesuchService from '../../../services/GesuchService.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall'

export default class GeschaeftsvorfallNavigation extends WebComponent {

  static get EVENT_KEYS() {
    return {
      STATUS_CHANGED: 'geschaeftsvorfall.status-changed',
      _RELOAD_TRIGGERED: 'geschaeftsvorfall.internal.update-nav',
      RELOAD_STATUS_TRIGGERED: 'geschaeftsvorfall.reload-status-triggered'
    }
  }

  static get STATUS_TYPE() {
    return {
      Red: 'red',
      Orange: 'orange',
      Green: 'green'
    }
  }

  constructor() {
    super()
    this.service = new GesuchService()
    this.items = this._initItems()
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Navigation/i18n.json'
  }

  get gesuchId() {
    return getNavigationId();
  }

  connectedCallback() {
    this.betriebSubscription = window.betrieb$.subscribe(betrieb => this._enableOrDisableTabsAfterBetrieb(betrieb))
    super.connectedCallback()

    this.navigationListener = _ => {
      this._updateStatusFromBackend()
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)

    this._refresh = _ => this.reload()
    this._changeLanguage = _ => {
      this._translateItems()
      this._refresh()
      send(GeschaeftsvorfallNavigation.EVENT_KEYS._RELOAD_TRIGGERED);
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)

    this._statusChangedListener = e => this._handleStatusChanged(e)
    document.addEventListener(GeschaeftsvorfallNavigation.EVENT_KEYS.STATUS_CHANGED, this._statusChangedListener)
    document.addEventListener(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED, this._updateStatusFromBackend.bind(this))

    this.translationLoaded.then(_ => this._translateItems())
    this._updateStatusFromBackend()
    this.render()
  }

  disconnectedCallback() {
    this.betriebSubscription.unsubscribe()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
    document.removeEventListener(GeschaeftsvorfallNavigation.EVENT_KEYS.STATUS_CHANGED, e => this._handleStatusChanged(e))
    document.removeEventListener(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED, this._updateStatusFromBackend)
  }

  getTemplate() {
    return html`
      <vzavg-vertical-tab-navigation
        .items="${this.items}"
        update-event="${GeschaeftsvorfallNavigation.EVENT_KEYS._RELOAD_TRIGGERED}"
      >
      </vzavg-vertical-tab-navigation>
    `
  }

  _translateItems() {
    for (const item of this.items) {
      item.text = this.i18n.translate(item.i18Key)
    }
  }

  /**
   * init the Items, when betrieb is not set all tabs expect the Uebersicht und Betrieb are disabled
   * @param {boolean} hasBetrieb
   * @returns {{_$litType$: *, strings: *, values: *[]}|BigUint64Array}
   * @private
   */
  _initItems() {
    const allItems = {
      Geschaeftsvorfall_Uebersicht: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.uebersicht',
        url: geschaeftsVorfallNavigationLinks.uebersicht
      },
      Geschaeftsvorfall_Betrieb: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.betrieb',
        url: geschaeftsVorfallNavigationLinks.betrieb,
        suburls: [
          geschaeftsVorfallNavigationLinks.betriebHistory
        ]
      },
      Geschaeftsvorfall_Gesuch: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.gesuch',
        url: geschaeftsVorfallNavigationLinks.gesuch,
        suburls: [
          geschaeftsVorfallNavigationLinks.gesuchHistory
        ]
      },
      Geschaeftsvorfall_Adresse: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.adresse',
        url: geschaeftsVorfallNavigationLinks.adresse,
        suburls: [
          geschaeftsVorfallNavigationLinks.adresseEdit,
          geschaeftsVorfallNavigationLinks.adresseHistory
        ]
      },
      Geschaeftsvorfall_VerantwortlichePerson: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.verantwortlicheperson',
        url: geschaeftsVorfallNavigationLinks.verantwortlichePerson,
        suburls: [
          geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit,
          geschaeftsVorfallNavigationLinks.verantwortlichePersonHistory
        ]
      },
      Geschaeftsvorfall_Kaution: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.kaution',
        url: geschaeftsVorfallNavigationLinks.kaution,
        suburls: [
          geschaeftsVorfallNavigationLinks.kautionEdit,
          geschaeftsVorfallNavigationLinks.kautionHistory
        ]
      },
      Geschaeftsvorfall_Dokumente: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.dokumente',
        url: geschaeftsVorfallNavigationLinks.dokumente,
        suburls: [
          geschaeftsVorfallNavigationLinks.dokumenteEdit
        ]
      },
      Geschaeftsvorfall_Entscheid: {
        isWorkflow: true,
        i18Key: 'navigation.vertical.entscheid',
        url: geschaeftsVorfallNavigationLinks.entscheid
      },
      Geschaeftsvorfall_Separation: {
        isSeparation: true,
        i18Key: 'navigation.vertical.separation'
      },
      Geschaeftsvorfall_Bewilligung: {
        i18Key: 'navigation.vertical.bewilligung',
        url: geschaeftsVorfallNavigationLinks.bewilligung,
        suburls: [
          geschaeftsVorfallNavigationLinks.bewilligungEdit
        ]
      },
      Geschaeftsvorfall_Statistikdaten: {
        i18Key: 'navigation.vertical.statistikdaten',
        url: geschaeftsVorfallNavigationLinks.statistikDaten,
        suburls: [
          geschaeftsVorfallNavigationLinks.statistikDatenHistory
        ]
      },
      Geschaeftsvorfall_Notiz: {
        i18Key: 'navigation.vertical.notizen',
        url: geschaeftsVorfallNavigationLinks.notiz,
        suburls: [
          geschaeftsVorfallNavigationLinks.notizEdit
        ]
      },
      Geschaeftsvorfall_Pendenz: {
        i18Key: 'navigation.vertical.pendenzen',
        url: geschaeftsVorfallNavigationLinks.pendenz,
        suburls: [
          geschaeftsVorfallNavigationLinks.pendenzEdit
        ]
      },
      Geschaeftsvorfall_History: {
        i18Key: 'navigation.vertical.history',
        url: geschaeftsVorfallNavigationLinks.history
      },
      Geschaeftsvorfall_TechnischeInfos: {
        i18Key: 'navigation.vertical.technische.infos',
        url: geschaeftsVorfallNavigationLinks.techInfos
      }
    }
    const items = getNavigationHash() === Authorization.VIEWS().notAuthorized ? null : AuthorizationFilter.viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    if (items === null) {
      return html``
    }
    items[0].active = true

    return items
  }

  _handleStatusChanged(e) {
    const {target, status} = e.detail

    for (const item of this.items) {
      if (!item.isSeparation && item.url.hash === target) {
        item.status = status
      }
    }

    send(GeschaeftsvorfallNavigation.EVENT_KEYS._RELOAD_TRIGGERED)
  }

  _enableOrDisableTabsAfterBetrieb(betrieb) {
    const enabledTabsWhenBetriebIsNotSet = [geschaeftsVorfallNavigationLinks.betrieb.hash]
    const enabledTabsWhenGesuchIsNotSet = [geschaeftsVorfallNavigationLinks.gesuch.hash]
    const enabledTabsWhenInAbklaerung = [
      geschaeftsVorfallNavigationLinks.uebersicht.hash,
      geschaeftsVorfallNavigationLinks.betrieb.hash,
      geschaeftsVorfallNavigationLinks.notiz.hash,
      geschaeftsVorfallNavigationLinks.pendenz.hash,
      geschaeftsVorfallNavigationLinks.history.hash,
      geschaeftsVorfallNavigationLinks.techInfos.hash
    ]
    const isTabDisabled = i => {
      if (!i.isSeparation) {
        if(betrieb !== null && getNavigationId() === '0') {
          return !enabledTabsWhenGesuchIsNotSet.includes(i.url.hash)
        }
        if (betrieb === null) {
          return !enabledTabsWhenBetriebIsNotSet.includes(i.url.hash)
        }
        if (betrieb.inAbklaerung === 'yes') {
          return !enabledTabsWhenInAbklaerung.includes(i.url.hash)
        }
      }
      return false
    }
    this.items = this.items.map(i => ({...i, disabled: isTabDisabled(i)}))
    this.reload()
  }

  _updateStatusFromBackend() {
    if(isNewGesuch()) {
      return
    }
    this.service.read(this.gesuchId, 'statuses')
      .then(statusesArray => {
        for (const _i in statusesArray) {
          const entries = Object.entries(statusesArray[_i])
          const [kategorie, typ] = entries[0]
          send(GeschaeftsvorfallNavigation.EVENT_KEYS.STATUS_CHANGED, {
            target: 'Geschaeftsvorfall_' + kategorie,
            status: typ
          })
        }
      })
  }


}
customElements.define('vzavg-frontend-geschaeftsvorfall-navigation', GeschaeftsvorfallNavigation)
