import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Authorization from '../Authorization/Authorization'
import AuthorizationFilter from '../Authorization/AuthorizationFilter'
import NotAuthorized from '../NotAuthorized/NotAuthorized'
import FreigebenPruefen from './FreigebenPruefen/FreigebenPruefen'
import ErhebungFreigeben from './ErhebungFreigeben/ErhebungFreigeben'
import ErhebungAbschliessen from './ErhebungAbschliessen/ErhebungAbschliessen'
import Konsolidierung from './Konsolidierung/Konsolidierung'
import I18n from "../../i18n";

export const statistikdatenNavigationLinks = {
  freigeben: {
    id: 'freigeben',
    hash: 'Statistikdaten_Freigeben'
  },
  konsolidieren: {
    id: 'konsolidieren',
    hash: 'Statistikdaten_Konsolidieren'
  },
  erhebung_freigeben: {
    id: 'erhebung_freigeben',
    hash: 'Statistikdaten_Erhebung_Freigeben'
  },
  erhebung_abschliessen: {
    id: 'erhebung_abschliessen',
    hash: 'Statistikdaten_Erhebung_Abschliessen'
  }
}

export default class Statistikdaten extends WebComponent {

  static get EVENT_KEYS() {
    return {
      RELOAD_TRIGGERED: 'statistikdaten.internal.update-nav',
    }
  }

  get translationFile() {
    return './views/Statistikdaten/i18n.json'
  }

  get css() {
    return `
      .statistikdaten_wrapper {
        display: flex;
        gap: 2em;
      }

      .statistikdaten_content {
        width: 100%;
      }
    `
  }

  constructor() {
    super()
    this.root = this
    this.currentView = this.returnNewChild(getNavigationHash())
  }


  connectedCallback() {
    super.connectedCallback()
    this.items = this.initItems()
    this._changeLanguage = _ => {
      this._translateItems()
      const verticalMenu = document.getElementById("statistikdaten-vertical-menu")
      if(verticalMenu) {
        verticalMenu.requestUpdate()
      }
    }
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)

    this.translationLoaded.then(_ => this._translateItems())
    this.render()
  }

  disconnectedCallback() {
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._changeLanguage)
  }

  returnNewChild(linkName) { //NOSONAR
    if (!AuthorizationFilter.viewAllowed(linkName)) {
      return new NotAuthorized()
    }

    switch (linkName) {
      case statistikdatenNavigationLinks.freigeben.hash:
        return new FreigebenPruefen()
      case statistikdatenNavigationLinks.erhebung_freigeben.hash:
        return new ErhebungFreigeben()
      case statistikdatenNavigationLinks.erhebung_abschliessen.hash:
        return new ErhebungAbschliessen()
      case statistikdatenNavigationLinks.konsolidieren.hash:
        return new Konsolidierung()
      default:
        return html``
    }
  }

  getTemplate() {
    return html`
      <div class="statistikdaten_wrapper">
        <isceco-vertical-navigation id="statistikdaten-vertical-menu"
          .items="${this.items}">
        </isceco-vertical-navigation>
        <div class="statistikdaten_content">
          ${this.currentView}
        </div>
      </div>

    `
  }

  _translateItems() {
    for (const item of this.items) {
      item.text = this.i18n.translate(item.i18Key)
    }
  }

  initItems() {
    const allItems = {
      Statistikdaten_Freigeben: {
        i18Key: 'statistikdaten.pruefenFreigeben',
        url: statistikdatenNavigationLinks.freigeben.hash
      },
      Statistikdaten_Konsolidieren: {
        i18Key: 'statistikdaten.konsolidieren',
        url: statistikdatenNavigationLinks.konsolidieren.hash
      },
      Statistikdaten_Erhebung_Freigeben: {
        i18Key: 'statistikdaten.erhebungFreigeben',
        url: statistikdatenNavigationLinks.erhebung_freigeben.hash,
        text: this.i18n.translate('statistikdaten.erhebungFreigeben')
      },
      Statistikdaten_Erhebung_Abschliessen: {
        i18Key: 'statistikdaten.erhebungAbschliessen',
        url: statistikdatenNavigationLinks.erhebung_abschliessen.hash
      },
    }

    const items = getNavigationHash() === Authorization.VIEWS().notAuthorized ? null : AuthorizationFilter.viewsAllowed(Object.keys(allItems)).map(key => allItems[key])
    if (items === null) {
      return html``
    }
    items[0].active = true

    return items
  }

}
customElements.define('vzavg-frontend-statistikdaten', Statistikdaten)
