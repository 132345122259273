import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '../../../components/PlzOrtInput/PlzOrtInput.js'
import '../../../components/CheckList/CheckList.js'
import GesuchService from '../../../services/GesuchService.js'
import I18n from '../../../i18n.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import VzavgListService from '../../../services/VzavgListService.js'
import StammdatenService from '../../../services/StammdatenService.js'
import VzavgPattern from '../../../common/VzavgPattern.js'
import '../../../components/Title/Title.js'
import Dates from "../../../common/Dates.js";

export default class KautionEdit extends WebComponent {

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco

    this.gesuchService = new GesuchService()
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Kaution/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.gesuchId = getNavigationId()
    this.kautionId = this._getKautionId()
    this.hasHauptsitzAdresse = false
    this.backendDataHauptSitzAdresse = null

    const url = await this.gesuchService.getBackendListUrl(`${this.gesuchId}/adresse`)
    const adressList = await new VzavgListService(url).list()
    const kautionsartenStammdaten = await new StammdatenService().getKautionsart()
    this.kautionsarten = kautionsartenStammdaten.map(k => ({name: k.bezeichnung, value: k.code}))
    this.hasHauptsitzAdresse = adressList.json.some(a => a.adresseTypCode === 'HS')

    this._refresh = () => this._getKautionAndRender()


    await this._refresh()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  get css() {
    return css`
      .grid-container {
        display: grid;
        grid-template-areas:
          "first second"
          "third fourth";
        gap: 1em;
      }

      @media (max-width: 991.98px) {
        .grid-container {
          grid-template-areas:
          "first"
          "second"
          "third"
          "fourth";
        }
      }

      .grid-first {
        grid-area: first
      }

      .grid-second {
        grid-area: second;
        display: flex;
        align-items: center;
      }

      .grid-third {
        grid-area: third;
      }

      .grid-fourth {
        grid-area: fourth;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .kaution-art-wrapper {
        display: flex;
        gap: 1em;
        align-items: center;
      }

      .kaution-betrag-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }

      .kaution-betrag-wrapper span {
        font-weight: bold
      }

      .kaution-betrag-wrapper isceco-text-input {
        width: 100%;
      }

      label {
        font-weight: bold;
        color: var(--isceco-color-black-500);
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }
    `
  }

  async _getKautionAndRender() {
    this.gesuchService.read(this.gesuchId, `adresse/hauptSitzAdresse`)
      .then(adresseResponse => {
        if (!isNoDataFound(adresseResponse)) {
          this.backendDataHauptSitzAdresse = adresseResponse
        }

        if (!this.kautionId) {
          this._initBackendData()
          this.render()
          return
        }

        this.gesuchService.read(this.gesuchId, `kaution/${this.kautionId}`)
          .then(kautionResponse => {
            if (isNoDataFound(kautionResponse)) {
              this._initBackendData()
              this.render();
              return
            }

            this.backendData = kautionResponse
            this.isActive = this._calculateIsActive()
            this.backendData.kantonCheckList = []
            this.backendData.secoCheckList = []
            this.backendData.secoCheckList.disabled = false

            // format betrag to frontend format
            this.backendData.kautionsBetrag = this._kautionsBetragToFrontendFormat(this.backendData.kautionsBetrag)

            this.gesuchService.readKautionChecklist(this.gesuchId, this.kautionId)
              .then(checkListsResponse => {
                this._processChecklistResponse(checkListsResponse)
                this.render()
              })
          }).catch(_ => {
          showAlert('', this.i18n.translate('geschaeftsvorfall.kaution.edit.error'))
          this.render()
        })
      }).catch(_ => {
      showAlert('', this.i18n.translate('geschaeftsvorfall.kaution.edit.error'))
      this.render()
    })
  }

  _initBackendData() {
    this.backendData = {}
    this.backendData.kantonCheckList = []
    this.backendData.secoCheckList = []
    this.backendData.secoCheckList.disabled = false
    this.backendData.kautionsDatum = new Date().toISOString().split('T')[0]
    this.backendData.isKantonChecklistOverridden = false
    this.backendData.isSecoChecklistOverridden = false
    this.backendData.isChecklistOverridden = false
    this.isActive = true
  }

  _takeAdresseFromHauptsitz() {
    if (this.backendDataHauptSitzAdresse !== null && this.backendData.kautionDurchHauptsitz) {
      this.backendData.uid = this.backendDataHauptSitzAdresse.unternRegisterId
      this.backendData.name = this.backendDataHauptSitzAdresse.firmenbezeichnung
      this.backendData.adresszusatz = this.backendDataHauptSitzAdresse.adresszusatz
      this.backendData.plz = this.backendDataHauptSitzAdresse.plz
      this.backendData.strasse = this.backendDataHauptSitzAdresse.strasse
      this.backendData.ort = this.backendDataHauptSitzAdresse.ort
    }
  }

  getTemplate() {
    return html`
      <div>
        <isceco-button
          id="kaution-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.button.back.to.list')}"
          @click="${_ => this._backToKautionList()}"
        ></isceco-button>
      </div>
      ${this._renderTitle()}
      <isceco-form id="kaution-form">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>
    `
  }

  _renderTitle() {
    return html`
      <vzavg-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.kaution.header')}">
        <div slot="center" class="title-content">
          <isceco-button
            id="previousKaution"
            icon="chevron left"
            variant="primary"
            @click="${_ => navigate({
              to: geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash,
              id: this.gesuchId
            })}"
          ></isceco-button>
          ${this.isActive ? this._renderCheckLists() : html``}
          <isceco-button
            id="nextKaution"
            icon="chevron right"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.dokumente.hash, id: this.gesuchId})}"
          ></isceco-button>
        </div>
        <div slot="right" class="title-content">
          <isceco-button
            id="kaution-add-btn"
            title="${this.i18n.translate('geschaeftsvorfall.kaution.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({to: geschaeftsVorfallNavigationLinks.kautionEdit.hash, id: this.gesuchId})}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="speichern"
            variant="primary"
            icon="save"
            @click="${_ => this.save()}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <vzavg-checklist-ok
            id="checklistOk"
            name="checklistOk"
            .disabled="${this.backendData.isChecklistOverridden}"
            .hidden="${window.betrieb$?.getValue()?.bewilligungspflicht === 'no' || this.backendData.secoCheckList.disabled || !this.isActive}"
            .clickCallback="${_ => this._handleChecklistOverride()}"
          ></vzavg-checklist-ok>
        </div>
      </vzavg-title>
    `
  }

  _renderCheckLists() {
    return html`
            <vzavg-checklist
              id="kantonCheckListChecks"
              name="kantonCheckListChecks"
              type="kanton"
              .items="${this.backendData.kantonCheckList}"
              .isChecklistOverridden="${this.backendData.isKantonChecklistOverridden}"
              .confirmCallback="${_ => this._handleKantonCheckList()}"
              .disabled="${!this.kautionId}"
            ></vzavg-checklist>
            ${this.backendData.secoCheckList.disabled ? html`` : html`
              <vzavg-checklist
                id="secoCheckListChecks"
                name="secoCheckListChecks"
                type="seco"
                .items="${this.backendData.secoCheckList}"
                .isChecklistOverridden="${this.backendData.isSecoChecklistOverridden}"
                .confirmCallback="${_ => this._handleSecoCheckList()}"
                .disabled="${!this.kautionId}"
              ></vzavg-checklist>
              `
            }
          `;
  }

  _renderFormFields() {
    return html`
      <div class="grid-container">
        <div class="grid-first">
          <isceco-dropdown
            id="kautionsArtCode"
            name="kautionsArtCode"
            value="${this.backendData.kautionsArtCode}"
            placeholder="${this.i18n.translate('geschaeftsvorfall.field.bitte.auswaehlen')}"
            label="${this.i18n.translate('geschaeftsvorfall.kaution.kautionsart')} *"
            .items="${this.kautionsarten}"
            @change="${e => this._kautionsartUpdateValue(e)}"
            required
            ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-dropdown>
        </div>
        <div class="grid-second">
          ${this.hasHauptsitzAdresse ? html`
            <isceco-checkbox
              id="kautionDurchHauptsitz"
              name="kautionDurchHauptsitz"
              label="${this.i18n.translate('geschaeftsvorfall.kaution.kautionDurchHauptsitz')}"
              .value="${this.backendData.kautionDurchHauptsitz}"
              @change="${e => this._updateValue(e, true)}"
              ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            ></isceco-checkbox>
          ` : html``}
        </div>
        <div class="grid-third">
          ${this._renderUrkundeNr()}
          ${this._renderBetrag()}
          ${this._renderKautionDatum()}
          ${this._renderBefristBis()}
          ${this._renderBlockiertBis()}
          ${this._renderSperrfrist()}
        </div>
        <div class="grid-fourth">
          ${this._renderUID()}
          ${this._renderName()}
          ${this._renderAdressZusatz()}
          ${this._renderStrasse()}
          ${this._renderPLZAndOrt()}
        </div>
      </div>
    `
  }

  _renderUrkundeNr() {
    return html`
      <isceco-text-input
        id="urkundenNr"
        name="urkundenNr"
        value="${this.backendData.urkundenNr}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.urkundenNr')}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-text-input>
    `
  }

  _renderBetrag() {
    return html`
      <isceco-text-input
        id="kautionsBetrag"
        name="kautionsBetrag"
        value="${this.backendData.kautionsBetrag}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.kautionsbetrag')} *"
        pattern="${VzavgPattern.chfBetragIntPattern}"
        pattern-error-text="${this.i18n.translate('geschaeftsvorfall.kaution.kautionsbetrag.patternError')}"
        required
        @change="${e => this._updateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-text-input>
    `
  }

  _renderKautionDatum() {
    return html`
      <isceco-date-input
        id="kautionsDatum"
        name="kautionsDatum"
        value="${this.backendData.kautionsDatum}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.kautionsdatum')} *"
        @change="${e => this._updateValue(e)}"
        required
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-date-input>
    `
  }

  _renderBefristBis() {
    return html`
      <isceco-date-input
        id="befristBis"
        name="befristBis"
        value="${this.backendData.befristBis}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.befristet.bis')}"
        @change="${e => this._updateValue(e, true)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-date-input>
    `
  }

  _renderBlockiertBis() {
    return html`
      <isceco-date-input
        id="blockiertBis"
        name="blockiertBis"
        value="${this.backendData.blockiertBis}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.blockiert.bis')}"
        @change="${e => this._updateValue(e, true)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-date-input>
    `
  }

  _renderSperrfrist() {
    return html`
      <isceco-date-input
        id="sperrfrist"
        name="sperrfrist"
        value="${this.backendData.sperrfrist}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.sperrfrist.bis')}"
        @change="${e => this._updateValue(e, true)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-date-input>
    `
  }

  _renderUID() {
    return html`
      <vzavg-uid-search-button
        id="unternRegisterId"
        name="unternRegisterId"
        value="${this.backendData.unternRegisterId}"
        .label="${this.i18n.translate('geschaeftsvorfall.kaution.uid')}"
        @change="${e => this._setBetriebSearchData(e.detail)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></vzavg-uid-search-button>
    `
  }

  _setBetriebSearchData(row) {
    this.backendData.unternRegisterId = row.uid
    this.backendData.name = row.name
    this.backendData.strasse = `${row.street} ${row.houseNumber}`
    this.backendData.plz = row.swissZipCode
    this.backendData.ort = row.town

    this.reload()
  }

  _renderName() {
    return html`
      <isceco-text-input
        id="name"
        name="name"
        value="${this.backendData.name}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.name')} *"
        @change="${e => this._adressUpdateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        required
      ></isceco-text-input>
    `
  }

  _adressUpdateValue(e) {
    this.backendData.uid = ''
    this._updateValue(e, true)
  }

  _renderAdressZusatz() {
    return html`
      <isceco-text-input
        id="adresszusatz"
        name="adresszusatz"
        value="${this.backendData.adresszusatz}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.adresszusatz')}"
        @change="${e => this._adressUpdateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
      ></isceco-text-input>
    `
  }

  _renderStrasse() {
    return html`
      <isceco-text-input
        id="strasse"
        name="strasse"
        value="${this.backendData.strasse}"
        label="${this.i18n.translate('geschaeftsvorfall.kaution.strasse')} *"
        @change="${e => this._adressUpdateValue(e)}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        required
      ></isceco-text-input>
    `
  }

  _renderPLZAndOrt() {
    return html`
      <vzavg-plz-ort-input
        id="plzOrtKaution"
        name="plzOrtKaution"
        .i18n="${this.i18n}"
        .ortValue="${this.backendData.ort}"
        .plzValue="${this.backendData.plz}"
        ?readonly="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
        @change="${e => this._adressUpdateValue(e)}"
        required
      ></vzavg-plz-ort-input>
    `
  }

  _kautionsartUpdateValue(e) {
    if (e.detail.value === 'BarEinlage') {
      const betrieb = window.betrieb$.getValue()
      this.backendData.uid = betrieb.unternRegisterId
      this.backendData.name = betrieb.firmenbezeichnung
      this.backendData.plz = betrieb.plz
      this.backendData.strasse = betrieb.strasse
      this.backendData.ort = betrieb.ort
    }
    this._updateValue(e, true)
  }

  _updateValue(event, reload = false) {
    window.hasChanges = true;
    const key = event.target.id;
    if (key === 'plzOrtKaution') {
      if (this.backendData['plz'] !== event.detail.plz || this.backendData['ort'] !== event.detail.ortBez) {
        this.backendData['plz'] = event.detail.plz
        this.backendData['ort'] = event.detail.ortBez
      }
    } else if (key === 'kautionsBetrag') {
      // format only when the pattern is correct
      if (event.target.validate()) {
        const value = event.target.value;
        event.target.value = this._kautionsBetragToFrontendFormat(value)
      }
    } else if (key === 'kautionDurchHauptsitz') {
      this.backendData.kautionDurchHauptsitz = event.detail.value
      this._takeAdresseFromHauptsitz()
    } else {
      if (this.backendData[key] !== event.detail.value) {
        this.backendData[key] = event.detail.value
      }
    }

    this.isActive = this._calculateIsActive()
    if (reload) {
      this.reload();
    }
  }

  _handleKantonCheckList() {
    this.kantonCheckListChecks = document.getElementById('kantonCheckListChecks').getValue()
    this.save()
  }

  _handleSecoCheckList() {
    this.secoCheckListChecks = document.getElementById('secoCheckListChecks').getValue()
    this.save()
  }

  async save() {
    const form = document.getElementById('kaution-form');

    if (!form.validate()) {
      return Promise.resolve(false)
    }

    this.formDataToSave = this._extractDataFromForm(form);

    return this._createOrUpdate(this.formDataToSave)

  }

  _kautionsBetragToFrontendFormat(value) {
    if (value == null) {
      return null
    } else if (typeof value === "string" && value.trim().length === 0) {
      return value
    }

    let _value = value

    // convert number to string which can be formatted later
    if (typeof value === "number") {
      _value = value.toString()
    }

    // remove ' if we have some
    const plainNumber = _value.replace(/'/g, '')
    // insert ' in right positions
    return plainNumber.replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  }

  _kautionsBetragToBackendFormat(value) {
    const plainNumber = value.replace(/'/g, '')
    return Number(plainNumber)
  }

  _extractDataFromForm(form) {
    const formData = form.getValues()
    formData.plz = formData.plzOrtKaution?.plz
    formData.ort = formData.plzOrtKaution?.ortBez

    formData.kantonCheckListChecks = this.kantonCheckListChecks
    formData.secoCheckListChecks = this.secoCheckListChecks
    formData.isKantonChecklistOverridden = this.backendData.isKantonChecklistOverridden
    formData.isSecoChecklistOverridden = this.backendData.isSecoChecklistOverridden

    return formData
  }

  _createOrUpdate(formData) {
    formData.id = this.kautionId

    // convert kautionsBetrag to a number
    formData.kautionsBetrag = this._kautionsBetragToBackendFormat(formData.kautionsBetrag)

    if (formData.id) {
      return this._update(formData)
    } else {
      return this._create(formData)
    }
  }

  _update(formData) {
    return this.gesuchService.update(formData, `${this.gesuchId}/kaution`)
      .then(_ => {
        this._showAlertAndNavigate(false)
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      })
      .catch(_ => false)
  }

  _create(formData) {
    return this.gesuchService.create(formData, `${this.gesuchId}/kaution`)
      .then(response => {
        const location = response.headers.get('Location')
        this.kautionId = location.slice(location.lastIndexOf('/') + 1)
        this._showAlertAndNavigate()
        send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
        return true
      })
      .catch(_ => false)
  }

  _showAlertAndNavigate(isCreated = true) {
    const messageKeyTitle = isCreated ? 'geschaeftsvorfall.kaution.create.success.title' : 'geschaeftsvorfall.kaution.update.success.title'
    const messageKey = isCreated ? 'geschaeftsvorfall.kaution.create.success.message' : 'geschaeftsvorfall.kaution.update.success.message'
    showAlert(this.i18n.translate(messageKeyTitle), this.i18n.translate(messageKey), 'success')

    if (isCreated) {
      navigate({to: geschaeftsVorfallNavigationLinks.kautionEdit.hash, id: `${this.gesuchId}/${this.kautionId}`})
    } else {
      this.render()
    }
  }

  _processChecklistResponse(checkListsResponse) {
    if (checkListsResponse.checks) {
      for (const check of checkListsResponse.checks) {
        if (check.bereich === 'kanton') {
          this.backendData.kantonCheckList.push(check)
        } else {
          this.backendData.secoCheckList.push(check)
        }
      }
    }

    this.backendData.secoCheckList.disabled = checkListsResponse.disabledForSeco
  }

  _backToKautionList() {
    navigate({to: geschaeftsVorfallNavigationLinks.kaution.hash, id: this.gesuchId})
  }

  _getKautionId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _handlePreviousKaution() {
    navigate({
      to: geschaeftsVorfallNavigationLinks.kautionEdit.hash,
      id: `${this.gesuchId}/${this.backendData.previousKautionId}`
    })
  }

  _handleNextKaution() {
    navigate({
      to: geschaeftsVorfallNavigationLinks.kautionEdit.hash,
      id: `${this.gesuchId}/${this.backendData.nextKautionId}`
    })
  }

  _handleChecklistOverride() {
    if (AuthorizationFilter.hasKantonRole()) {
      this.backendData.isKantonChecklistOverridden = true
    } else {
      this.backendData.isSecoChecklistOverridden = true
    }
    this.backendData.isChecklistOverridden = true
    this.save()
  }

  _calculateIsActive() {
    const now = Dates.getToday()
    const befristetBis= new Date(this.backendData.befristBis)
    const befristet = Dates.isAfter(now, befristetBis)

    const blockiertBis= new Date(this.backendData.blockiertBis)
    const blockiert = Dates.isSameOrBefore(now, blockiertBis)

    const gesperrtBis = new Date(this.backendData.sperrfrist)
    const gesperrt = Dates.isSameOrBefore(now, gesperrtBis)

    return !befristet && !blockiert && !gesperrt
  }

}
customElements.define('vzavg-geschaeftsvorfall-kaution-edit', KautionEdit)
