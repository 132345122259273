import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import VzavgFormation from '../../../../common/VzavgFormation'

export default class KautionUebersicht extends TranslationElement {

  static get properties() {
    return {
      kautionen: {type: Array}
    }
  }

  render() {
    if (!this.kautionen || this.kautionen.length === 0) {
      return this._renderNoItems()
    }
    return html`
      ${this._renderTitle()}
      <div class="kautions-container">
        ${this.kautionen?.map(kaution => this._renderkaution(kaution))}
      </div>
    `;
  }

  _renderTitle() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('title')}"
        resource="${geschaeftsVorfallNavigationLinks.kaution.hash}/${getNavigationId()}"
      ></vzavg-link>
    `
  }

  _renderkaution(kaution) {
    return html`
      <div class="kaution-container">
        <div class="title">${kaution.name}</div>
        <div>CHF ${VzavgFormation.formatAmount(kaution.kautionsBetrag)}</div>
        ${this._renderBefristet(kaution.befristBis)}
      </div>
    `
  }

  _renderBefristet(befristetBis) {
    if (!befristetBis) {
      return html``
    }

    return html`
      <div class="small">
        ${this.i18n.translate('befristet')}
        ${new Date(befristetBis).toLocaleDateString("de-ch")}
      </div>
    `
  }

  _renderNoItems() {
    return html`
      ${this._renderTitle()}
      <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.kaution.no.items')}</p>
    `
  }

  static get styles() {
    return css`
      .small {
        font-size: 0.8em;;
      }

      .kautions-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/KautionUebersicht/i18n.json'
  }

}
customElements.define('kaution-uebersicht', KautionUebersicht)
