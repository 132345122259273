import BaseService from '../BaseService';

export default class ExportService extends BaseService {
  constructor(relativePath) {
    super(relativePath)
  }

  async getExport(filename, uriParameters) {
    await this.retrieveBackendUrl();
    return this.checkError(
      fetch(`${this.backendUrl}/${filename}?${uriParameters}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    );
  }
}
