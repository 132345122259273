import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/DropdownMultiselect/DropdownMultiselect.js'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'
import I18n from '../../../../i18n.js'
import {betriebStatuses} from '../../../Geschaeftsvorfall/Betrieb/Betrieb.js'
import {bewilligungTypen} from "../../../Geschaeftsvorfall/Bewilligung/Bewilligung.js"

export default class BewilligungFilter extends WebComponent {

  constructor() {
    super()
    this.stammdatenService = new StammdatenService();
    this.i18n = new I18n()
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  set values(val) {
    this._values = val
  }

  connectedCallback() {
    // super.connectedCallback(), we call translation file later in process
    this._refresh = () => this.render()

    Promise.all([this.stammdatenService.getOrganisation(), this.stammdatenService.getBranchen(), this.stammdatenService.getQualifikationen()])
      .then(([kantone, branchen, qualifikationen]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.branchen = branchen.map(branche => ({value: branche.code, name: branche.bezeichnung}))
        this.qualifikationen = qualifikationen.map(qualif => ({value: qualif.code, name: qualif.bezeichnung}))
      })
      .then(_ =>
        this.translationLoaded = this.i18n.loadConfiguration(this.translationFile)
          .then(_1 => {
            this.grundItems = [
              {
                value: 'betriebEingestellt',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.betriebEingestellt')
              },
              {
                value: 'konkurs',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.konkurs')
              },
              {
                value: 'bewillNichtErfuellt',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewillNichtErfuellt')
              },
              {
                value: 'sitzverlegung',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.sitzverlegung')
              },
              {
                value: 'bewilRetourniert',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilRetourniert')
              },
              {
                value: 'neuFirmaGegruendet',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.neuFirmaGegruendet')
              },
              {
                value: 'keinVerleihMehr',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.keinVerleihMehr')
              },
              {
                value: 'eintragsfehler',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.eintragsfehler')
              },
              {
                value: 'gesuchUmAufhebung',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesuchUmAufhebung')
              },
              {
                value: 'bewilEntzogen',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.bewilEntzogen')
              },
              {
                value: 'firmaReaktiviert',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.firmaReaktiviert')
              },
              {
                value: 'weggezogen',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.weggezogen')
              },
              {
                value: 'aufenthaltUnbekannt',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.aufenthaltUnbekannt')
              },
              {
                value: 'irrefuerhendeAngabe',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.irrefuerhendeAngabe')
              },
              {
                value: 'gesetzesverstoesse',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.gesetzesverstoesse')
              },
              {
                value: 'erfassungsfehler',
                name: this.i18n.translate('geschaeftsvorfall.bewilligung.grund.erfassungsfehler')
              }
            ]
            this.render()
          })
          .catch(error => {
            this.logging.log(error)
          })
      )

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.kanton')}"
          id="kanton"
          name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.status')}" id="betriebStatus" name="betriebStatus"
          .items="${betriebStatuses(this.i18n)}"
          .value="${this.values?.betriebStatus}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.bewilligungstyp')}" id="bewilligungTyp" name="bewilligungTyp"
          .items="${bewilligungTypen(this.i18n)}"
          .value="${this.values?.bewilligungTyp}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.bereich')}"
          id="bereich"
          name="bereich"
          .value="${this.values?.bereich}"
          .items="${[
            {name: this.i18n.translate('suchen.gesuch.kanton'), value: 'kanton'},
            {name: this.i18n.translate('suchen.gesuch.seco'), value: 'seco'}
          ]}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.erstbewilligungVon')}"
          id="erstbewilligungVon"
          name="erstbewilligungVon"
          .value="${this.values?.erstbewilligungVon}"
          max="${isEmpty(this.values?.erstbewilligungVon) ? '' : this.values?.erstbewilligungBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.erstbewilligungBis')}"
          id="erstbewilligungBis"
          name="erstbewilligungBis"
          .value="${this.values?.erstbewilligungBis}"
          min="${isEmpty(this.values?.erstbewilligungBis) ? '' : this.values?.erstbewilligungVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.aenderungsdatumVon')}"
          id="aenderungsdatumVon"
          name="aenderungsdatumVon"
          .value="${this.values?.aenderungsdatumVon}"
          max="${isEmpty(this.values?.aenderungsdatumVon) ? '' : this.values?.aenderungsdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.aenderungsdatumBis')}"
          id="aenderungsdatumBis"
          name="aenderungsdatumBis"
          .value="${this.values?.aenderungsdatumBis}"
          min="${isEmpty(this.values?.aenderungsdatumBis) ? '' : this.values?.aenderungsdatumVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.entzugAufhebungsdatumVon')}"
          id="entzugAufhebungsdatumVon"
          name="entzugAufhebungsdatumVon"
          .value="${this.values?.entzugAufhebungsdatumVon}"
          max="${isEmpty(this.values?.entzugAufhebungsdatumVon) ? '' : this.values?.entzugAufhebungsdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.entzugAufhebungsdatumBis')}"
          id="entzugAufhebungsdatumBis"
          name="entzugAufhebungsdatumBis"
          .value="${this.values?.entzugAufhebungsdatumBis}"
          min="${isEmpty(this.values?.entzugAufhebungsdatumBis) ? '' : this.values?.entzugAufhebungsdatumVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-dropdown-multiselect
          label="${this.i18n.translate('suchen.gesuch.grund')}"
          id="grund"
          name="grund"
          .items="${this.grundItems}"
          .value="${this.values?.grund}">
        </isceco-dropdown-multiselect>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.inRechtskraftErwachsenVon')}"
          id="inRechtskraftErwachsenVon"
          name="inRechtskraftErwachsenVon"
          .value="${this.values?.inRechtskraftErwachsenVon}"
          max="${isEmpty(this.values?.inRechtskraftErwachsenVon) ? '' : this.values?.inRechtskraftErwachsenBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.bewilligung.inRechtskraftErwachsenBis')}"
          id="inRechtskraftErwachsenBis"
          name="inRechtskraftErwachsenBis"
          .value="${this.values?.inRechtskraftErwachsenBis}"
          min="${isEmpty(this.values?.inRechtskraftErwachsenBis) ? '' : this.values?.inRechtskraftErwachsenVon}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-dropdown-multiselect
          label="${this.i18n.translate('suchen.gesuch.branche')}"
          id="branche"
          name="branche"
          .items="${this.branchen}"
          .value="${this.values?.branche}"
        ></isceco-dropdown-multiselect>
        <isceco-dropdown-multiselect
          label="${this.i18n.translate('suchen.gesuch.qualifikationen')}"
          id="qualifikationen"
          name="qualifikationen"
          .items="${this.qualifikationen}"
          .value="${this.values?.qualifikationen}"
        ></isceco-dropdown-multiselect>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.bewilligung.sonderpreis')}"
          id="sonderpreis"
          name="sonderpreis"
          .value="${this.values?.sonderpreis}"
          .items="${[
            {name: this.i18n.translate('yes'), value: 'yes'},
            {name: this.i18n.translate('no'), value: 'no'}
          ]}"
        ></isceco-dropdown>
      </div>
    `
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key.includes('Von') || key.includes('Bis')) {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
        const date = new Date(event.detail.value)
        if (date?.getFullYear() > 1980) {
          this.reload()
        }
      }
    } else {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
      }
    }
  }

  get css() {
    return css`
      .filter-wrapper > * {
        min-width: 20em;
      }
    `
  }
}
customElements.define('vzavg-frontend-suche-bewilligung-filter', BewilligungFilter)
