import StringColumn from "@isceco/widget-library2/basic-elements/List/column/StringColumn";
import GenericColumn from "@isceco/widget-library2/basic-elements/List/column/GenericColumn";
import DefaultActionCell from "../../../../common/list/DefaultActionCell";
import AuthorizationFilter from "../../../Authorization/AuthorizationFilter";

export default class BenutzerColumns {
  static NAME_COLUMN() {
    return new StringColumn({
      key: 'name',
      text: 'stammdatenpflege.benutzer.name',
      sortable: false
    })
  }

  static VORNANAME_COLUMN() {
    return new StringColumn({
      key: 'vorname',
      text: 'stammdatenpflege.benutzer.vorname',
      sortable: false
    })
  }

  static POSITION_COLUMN() {
    return new StringColumn({
      key: 'position',
      text: 'stammdatenpflege.benutzer.position',
      sortable: false
    })
  }

  static TELEFON_COLUMN() {
    return new StringColumn({
      key: 'telefon',
      text: 'stammdatenpflege.kast.telefon',
      sortable: false
    })
  }

  static EMAIL_COLUMN() {
    return new StringColumn({
      key: 'email',
      text: 'stammdatenpflege.kast.email',
      sortable: false
    })
  }

  static HIERARCHIE_COLUMN() {
    return new StringColumn({
      key: 'hierarchiestufe',
      text: 'stammdatenpflege.benutzer.hierarchie',
      sortable: false
    })
  }

  static AKTIONEN_COLUMN(configuration) {
    const {callback} = configuration
    return new GenericColumn({
      key: 'aktionen',
      text: 'stammdatenpflege.benutzer.aktion',
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: callback,
        buttons: i18n => {
          const buttons= []

          buttons.push({
            action: 'edit',
            icon: 'edit',
            variant: 'primary',
            title: i18n.translate('stammdatenpflege.benutzer.actions.edit')
          })

          buttons.push({
            action: 'delete',
            icon: 'trash',
            variant: 'primary',
            title: i18n.translate('stammdatenpflege.benutzer.actions.delete'),
            disabled: !AuthorizationFilter.hasSuperRole()
          })

          return buttons
        }
      })
    })
  }

}
