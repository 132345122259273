import {css, html} from '@isceco/widget-library2/external/lit'
import TranslationElement from '../../../../common/TranslationElement'
import {geschaeftsVorfallNavigationLinks} from '../../Geschaeftsvorfall'
import VzavgFormation from '../../../../common/VzavgFormation.js'
import AuthorizationFilter from '../../../Authorization/AuthorizationFilter.js'

export default class BetriebUebersicht extends TranslationElement {
  static get properties() {
    return {
      betrieb: {type: Object},
      gesuchId: {type: String}
    }
  }

  render() {
    if (!this.betrieb) {
      return html`
        ${this._renderTitle()}
        <p>${this.i18n.translate('geschaeftsvorfall.uebersicht.betrieb.no.betrieb')}</p>
      `
    }
    return html`
      ${this._renderTitle()}
      <div class="betrieb-container">
        ${this.betrieb.unternRegisterId.length < 36 ?
          html`<span>${VzavgFormation.formatUID(this.betrieb.unternRegisterId)}</span>` :
          html``}
        <span>${this.betrieb.firmenbezeichnung}</span>
        <span>${this.betrieb.strasse}</span>
        <span>${this.betrieb.plz} ${this.betrieb.ort}</span>
        <a href="${'tel:' + this.betrieb.telefon}">${VzavgFormation.formatTel(this.betrieb.telefon)}</a>
        <a href="${`mailto:${this.betrieb.email}`}">${this.betrieb.email}</a>
        <a href="${this._formatUrlWithHttp(this.betrieb.web)}">${this.betrieb.web}</a>
        ${this._renderDmsLink()}
      </div>
    `
  }

  _renderDmsLink() {
    return AuthorizationFilter.hasSecoRole() && !isEmpty(this.betrieb.dmsLink) ? html`
      <br/>
      <a href="${this.betrieb.dmsLink}" target="_blank">DMS</a>
    ` :  html``
  }

  static get styles() {
    return css`
      .betrieb-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/BetriebUebersicht/i18n.json'
  }


  _renderTitle() {
    return html`
      <vzavg-link
        size="medium" text="${this.i18n.translate('geschaeftsvorfall.uebersicht.betrieb.title')}"
        resource="${geschaeftsVorfallNavigationLinks.betrieb.hash}/${this.gesuchId}"
      ></vzavg-link>
    `
  }

  _formatUrlWithHttp(url) {
    if (!url || url.startsWith('https://') || url.startsWith('http://')) {
      return url
    }

    return '//' + url
  }
}
customElements.define('betrieb-uebersicht', BetriebUebersicht)
