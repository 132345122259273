import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import DefaultActionCell from '../../../common/list/DefaultActionCell.js'
import PhoneCell from '../../../common/PhoneCell.js'
import EmailCell from '../../../common/EmailCell.js'


export default class CockpitStatistikdatenColumns {
  constructor(callback) {
    this._columns = [
      this.BETRIEB_COLUMN(),
      this.STRASSE_COLUMN(),
      this.PLZ_COLUMN(),
      this.ORT_COLUMN(),
      this.TEL_COLUMN(),
      this.EMAIL_COLUMN(),
      this.AKTIONEN_COLUMN({
        callback: callback
      })
    ]
  }

  get columns() {
    return this._columns;
  }

  BETRIEB_COLUMN() {
    return new StringColumn({
      key: 'firmenbezeichnung',
      text: 'cockpit.statistikdaten.betrieb',
      sortable: false
    })
  }

  STRASSE_COLUMN() {
    return new StringColumn({
      key: 'strasse',
      text: 'cockpit.statistikdaten.strasse',
      sortable: false
    })
  }

  PLZ_COLUMN() {
    return new StringColumn({
      key: 'plz',
      text: 'cockpit.statistikdaten.plz',
      sortable: false,
      weight: 0.5
    })
  }

  ORT_COLUMN() {
    return new StringColumn({
      key: 'ort',
      text: 'cockpit.statistikdaten.ort',
      sortable: false
    })
  }

  TEL_COLUMN() {
    return new GenericColumn({
      key: 'telefon',
      text: 'cockpit.statistikdaten.tel',
      sortable: false,
      cell: new PhoneCell()
    })
  }

  EMAIL_COLUMN() {
    return new GenericColumn({
      key: 'email',
      text: 'cockpit.statistikdaten.email',
      sortable: false,
      cell: new EmailCell()
    })
  }

  AKTIONEN_COLUMN(configuration) {
    return new GenericColumn({
      key: 'aktionen',
      text: '',
      sortable: false,
      filter: null,
      weight: 0.5,
      cell: new DefaultActionCell({
        callback: configuration.callback,
        buttons: i18n => [
          {
            action: 'detail',
            icon: 'clipboard',
            variant: 'primary',
            title: i18n.translate('cockpit.statistikdaten.list.actions.detail'),
            size: 'small'
          }
        ]
      })
    })
  }
}
