import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'

class GesuchAdresseRsVonHS extends LitElement {

  static get properties() {
    return {
      adresse: {type: Object},
      i18n: {type: Object}
    }
  }

  static get styles() {
    return css`
      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
      }

      .changed {
        background-color: var(--isceco-color-yellow-100);
      }
    `
  }

  render() {
    return html`
      <isceco-dialog
        hidden
        id="isceco-rs-von-hs-dialog"
        size="medium"
        header="${this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.hsrs.header')}"
        description="${this.i18n.translate('geschaeftsvorfall.gesuchadresse.dialog.hsrs.description')}"
        confirm-button="${this.i18n.translate('button.yes')}"
        cancel-button="${this.i18n.translate('button.no')}"
        @submit="${e => this._close(e)}">
        <div class="flex">
          <div class="flex-column">
            ${this._renderGesuchAdresseInfo(this.adresse, this.i18n.translate('geschaeftsvorfall.adresse.typ.revisionstelle'))}
          </div>
        </div>
      </isceco-dialog>
    `
  }

  _close(e) {
    const diffResult = {
      confirmed: e.detail.confirmed
    }

    this.callback(diffResult)
    this.shadowRoot.querySelector('#isceco-rs-von-hs-dialog')
      .setAttribute('hidden', '')

    this.adresse = {}
  }

  _renderGesuchAdresseInfo(adresse, title) {
    if (!adresse) {
      return html``
    }

    return html`
      <div class="flex-column">
        <isceco-title
          id="title-gesuchadresse"
          text="${title}"
          size="small">
        </isceco-title>
        <isceco-text-input
          id="unternRegisterId"
          name="unternRegisterId"
          value="${adresse.unternRegisterId}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.untern.register.id')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="firmenbezeichnung"
          name="firmenbezeichnung"
          value="${adresse.firmenbezeichnung}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.firmenbezeichnung')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="adresszusatz"
          name="adresszusatz"
          value="${adresse.adresszusatz}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.adresszusatz')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="postfach"
          name="postfach"
          value="${adresse.postfach}"
          label="${this.i18n.translate('geschaeftsvorfall.adresse.postfach')}"
          readonly
        ></isceco-text-input>
        <isceco-text-input
          id="strasse"
          name="strasse"
          value="${adresse.strasse}"
          label="${this.i18n.translate('geschaeftsvorfall.betrieb.strasse')}"
          style="flex:1; min-width: 6em;"
          readonly
        ></isceco-text-input>
        <div class="flex" style="gap: 0.3em">
          <isceco-text-input
            id="plz"
            name="plz"
            value="${adresse.plz}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.plz')}"
            style="max-width: 5em"
            readonly
          ></isceco-text-input>
          <isceco-text-input
            id="ort"
            name="ort"
            value="${adresse.ort}"
            label="${this.i18n.translate('geschaeftsvorfall.betrieb.ort')}"
            style="flex:1; min-width: 6em"
            readonly
          ></isceco-text-input>
        </div>
      </div>
    `;
  }
}

customElements.define('vzavg-gesuchadresse-hsrs-dialog', GesuchAdresseRsVonHS)
