import {css, html} from '@isceco/widget-library2/external/lit'
import GesuchPendenzService from '../../../services/GesuchPendenzService'
import StammdatenService from '../../../services/StammdatenService'
import I18n from '../../../i18n'
import WebComponent from '../../../WebComponent'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall'
import VzavgFormation from '../../../common/VzavgFormation.js'
import '../../../components/DocumentUpload/DocumentUpload.js'

export default class PendenzEdit extends WebComponent {

  static PENDENZ_FORM_ID = 'pendenz-form';

  constructor() {
    super()
    this.gesuchPendenzService = new GesuchPendenzService();
    this.stammdatenService = new StammdatenService()
    this.participants = []

    /**
     * Pendenz Type Definition
     * @typedef {Object} Pendenz
     * @property {String} eingangsdatum
     * @property {String} termin
     * @property {String} betreff
     * @property {Object} sender
     * @property {Object} empfaenger
     * @property {string} beschreibung
     * @property {String} erledigtAm
     * @property {String} erledigtDurch
     */

    /**
     *
     * @type {Pendenz}
     */
    this.backendData = {}
  }

  get css() {
    return css`

      [hidden] {
        display: none;
      }

      nav {
        padding-bottom: 0.5rem;
      }

      .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .buttons-column {
        display: flex;
        gap: 0.5em;
        flex-direction: column;
        align-items: start;
      }

      .title-content {
        display: flex;
        gap: 2em;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }

      .title-navigation {
        display: flex;
        gap: 1em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Pendenz/i18n.json'
  }

  get isAnswer() {
    return !this._equalsIgnoringCase(keycloak.tokenParsed.email, this.backendData.sender)
  }

  async connectedCallback() {
    super.connectedCallback()
    this._refresh = async () => this._getPendenzAndRender()

    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    await this._refresh()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      <div class="header-wrapper">
        <isceco-button
          id="pendenz-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('geschaeftsvorfall.pendenzen.back.to.list')}"
          @click="${_ => this._navigateBackToPendenzList()}"
        ></isceco-button>
      </div>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.pendenzen.create.title')}">
        <nav class="title-navigation">
          <isceco-button text="${this.i18n.translate('geschaeftsvorfall.pendenz.complete.button')}"
                         @click="${() => this.save(true)}"
                         ?disabled="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}">

          </isceco-button>
          <isceco-button
            id="pendenz-update"
            variant="primary"
            text="${this.i18n.translate(this.isAnswer ? 'geschaeftsvorfall.pendenz.answer.button' : 'geschaeftsvorfall.pendenz.send.button')}"
            icon="paper plane"
            @click="${() => this.save()}"
            ?disabled="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
          >
          </isceco-button>
        </nav>
      </isceco-title>
      <isceco-form id="${PendenzEdit.PENDENZ_FORM_ID}">
        <div slot="form-elements">
          ${this._renderFormFields()}
        </div>
      </isceco-form>
    `
  }

  _renderFormFields() {
    return html`
      <div class="flex">
        <div class="flex-column">
          <isceco-date-input
            id="eingangsdatum"
            name="eingangsdatum"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.eingangsdatum')}"
            value="${this.backendData.eingangsdatum}"
            disabled
            required
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
          ></isceco-date-input>
          <isceco-date-input
            id="termin"
            name="termin"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.termin')} *"
            @change="${e => this._updateValue(e, true)}"
            value="${this.backendData.termin}"
            min="${VzavgFormation.formatDateToYYYYMMDD(new Date())}"
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
            required
          ></isceco-date-input>
          <isceco-text-input
            id="betreff"
            name="betreff"
            required
            value="${this.backendData.betreff}"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.betreff')} *"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
          ></isceco-text-input>
          <isceco-text-input
            id="sender"
            name="sender"
            value="${this.backendData.sender}"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.sender')} *"
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
            disabled>
          </isceco-text-input>
          <vzavg-searchable-text-input
            id="empfaenger"
            name="empfaenger"
            required
            value="${this.backendData.empfaenger}"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.empfaenger')} *"
            .items="${this.participants}"
            @change="${e => this._updateValue(e)}"
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
          >
          </vzavg-searchable-text-input>
          <isceco-textarea
            id="beschreibung"
            name="beschreibung"
            required
            value="${this.backendData.editableText}"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.beschreibung')} *"
            @change="${e => this._updateValue(e)}"
            resize="auto"
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
            maxlength="2000"
          ></isceco-textarea>
        </div>
        <div class="flex-column">
          <isceco-date-input
            id="erledigtAm"
            name="erledigtAm"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.erledigt_am')}"
            @change="${e => this._updateValue(e, true)}"
            value="${this.backendData.erledigtAm}"
            disabled
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
          ></isceco-date-input>
          <vzavg-searchable-text-input
            id="erledigtDurch"
            name="erledigtDurch"
            value="${this.backendData.erledigtDurch}"
            .items="${this.participants}"
            label="${this.i18n.translate('geschaeftsvorfall.pendenzen.form.erledigt_durch')}"
            disabled
            ?readonly="${this.backendData.erledigtAm?.length || !this._isSenderOrEmpfaenger()}"
            @change="${e => this._updateValue(e)}"
          ></vzavg-searchable-text-input>
          ${
            this._getPendenzId() !== undefined ?
              html`
                <vzavg-document-upload
                  gesuchId="${this.gesuchId}"
                  kategorie="pendenzen"
                  subKategorie="pendenz"
                  objektId="${this._getPendenzId()}"
                  .i18n="${this.i18n}"
                  .readonly="${!this._isSenderOrEmpfaenger()}">
                </vzavg-document-upload>
              ` :
              html``
          }
        </div>
      </div>
    `
  }

  async _getPendenzAndRender() {
    this.gesuchId = getNavigationId()
    const participants = await this.gesuchPendenzService.getParticipants(this.gesuchId);
    this.participants = participants.map(p => ({name: p.value, ...p}));
    const pendenzId = this._getPendenzId()

    if (!pendenzId) {
      this._initBackendData()
      this.render()
      return
    }

    try {
      const gesuchPendenzResponse = await this.gesuchPendenzService.getPendenz(this.gesuchId, pendenzId)
      if (isNoDataFound(gesuchPendenzResponse)) {
        this.render()
        return
      }
      this.backendData = {
        ...gesuchPendenzResponse,
        senderType: gesuchPendenzResponse.sender.type,
        sender: gesuchPendenzResponse.sender.value,
        empfaengerType: gesuchPendenzResponse.empfaenger.type,
        empfaenger: gesuchPendenzResponse.empfaenger.value
      }
      const lastAuthor = this.backendData.lastAuthor
      const lastDate = VzavgFormation.formatDateToHuman(this.backendData.lastModificationTime)
      this.backendData.editableText = `\n----------------------------------------\n${lastAuthor} / ${lastDate}\n${this.backendData.beschreibung}`
      this.render()
    } catch (_) {
      showAlert('', this.i18n.translate('geschaeftsvorfall.pendenzen.edit.error'))
      this.render()
    }

  }

  _getPendenzId() {
    return window.location.hash.split('?')[0].split('/')[2]
  }

  _initBackendData() {
    this.backendData = {};
    this.backendData.sender = keycloak.tokenParsed.email
    this.backendData.eingangsdatum = VzavgFormation.formatDateToYYYYMMDD(new Date());
    this.backendData.editableText = ''
  }

  _navigateBackToPendenzList() {
    navigate({to: geschaeftsVorfallNavigationLinks.pendenz.hash, id: this.gesuchId})
  }

  _navigateToSavedPendenz(pendenzId) {
    navigate({to: geschaeftsVorfallNavigationLinks.pendenzEdit.hash, id: `${this.gesuchId}/${pendenzId}`})
  }

  _updateValue(event, reload) {
    const key = event.target.id
    const value = event.detail.value
    if (this.backendData[key] !== value) {
      window.hasChanges = true;
      this.backendData[key] = value
    }
    if (reload) {
      this.reload();
    }
  }

  save(completeTask = false) {
    const form = document.getElementById(PendenzEdit.PENDENZ_FORM_ID);
    if (!form.validate()) {
      return Promise.resolve(false);
    }
    const formDatas = this._prePareFormDataForSaving(completeTask, form)
    if (this.isAnswer) {
      formDatas.empfaenger = formDatas.sender
      formDatas.sender = this._findParticipant(keycloak.tokenParsed.email)
    }
    return this._createOrUpdate(formDatas)
  }

  _prePareFormDataForSaving(completeTask, form) {
    const formDatas = form.getValues();

    formDatas.sender = this._findParticipant(this.backendData.sender)
    formDatas.empfaenger = this._findParticipant(formDatas.empfaenger)
    formDatas.erledigtDurch = this._findParticipant(formDatas.erledigtDurch)
    formDatas.id = this.backendData.id
    formDatas.eingangsdatum = this.backendData.eingangsdatum
    if (completeTask) {
      formDatas.erledigtAm = VzavgFormation.formatDateToYYYYMMDD(new Date())
      formDatas.erledigtDurch = keycloak.tokenParsed.email
    }
    return formDatas
  }

  _findParticipant = value => this.participants.find(p => this._equalsIgnoringCase(p.value, value))

  _createOrUpdate(formDatas) {
    if (formDatas.id) {
      return this._update(formDatas)
    } else {
      return this._create(formDatas)
    }
  }

  _update(formDatas) {
    return this.gesuchPendenzService.updatePendenz(formDatas, this.gesuchId)
      .then(_ => {
        this._showSavedAlert()
        if (formDatas.erledigtAm) {
          this._navigateBackToPendenzList()
        }
        return true
      }).catch(_ => false)
  }

  _create(formDatas) {
    return this.gesuchPendenzService.createPendenz(formDatas, this.gesuchId)
      .then(resp => {
        this._showSavedAlert()
        const pendenzId = this._getNewPendenzId(resp.headers.get('Location'))
        pendenzId ? this._navigateToSavedPendenz(pendenzId) : this._navigateBackToPendenzList()
        return true
      }).catch(_ => false)
  }

  _getNewPendenzId(location) {
    if (!location) {
      return undefined
    }
    const locationParts = location.split('/')
    const newPendenzId = Number(locationParts[locationParts.length - 1])
    return Number.isInteger(newPendenzId) ? newPendenzId : undefined
  }

  _showSavedAlert() {
    showAlert(
      this.i18n.translate('geschaeftsvorfall.pendenz.create.success.title'),
      this.i18n.translate('geschaeftsvorfall.pendenz.create.success.message'),
      'success'
    )
  }

  _isSenderOrEmpfaenger() {
    return this._isSender() || this._isEmpfaenger();
  }

  _isSender() {
    const user = this.backendData.senderType === 'OE' ? sessionStorage.getItem('userRechtCodeKanton')  :  keycloak.tokenParsed.email;
    return this._equalsIgnoringCase(this.backendData.sender, user)
  }

  _isEmpfaenger() {
    const user = this.backendData.empfaengerType === 'OE' ? sessionStorage.getItem('userRechtCodeKanton')  :  keycloak.tokenParsed.email;
    return this._equalsIgnoringCase(this.backendData.empfaenger, user)
  }

  _equalsIgnoringCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
  }

}
customElements.define('vzavg-pendenz-edit', PendenzEdit)
