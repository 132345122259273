import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall.js'
import I18n from '../../../i18n.js'
import VzavgListService from '../../../services/VzavgListService.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import DokumenteColumns from './DokumenteColumns.js'
import GesuchService from '../../../services/GesuchService.js'
import DokumentService from '../../../services/DokumentService.js'

export default class Dokumente extends WebComponent {

  static get EVENT_KEYS() {
    return {
      TRIGGER_TABLE_RELOAD: 'geschaeftvorfall.dokumente.table.reload.event'
    }
  }

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.gesuchService = new GesuchService()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
  }

  get css() {
    return css`
      .flex-columns {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .statusRed {
        background: linear-gradient(to right, #e779794d, #F6F6F6);
      }

      .statusOrange {
        background: linear-gradient(to right, #f5e4bdd6, #F6F6F6);
      }

      .statusGreen {
        background: linear-gradient(to right, #98f99859, #F6F6F6);
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Dokumente/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.personId = this._getVerantwortlichePersonId()

    this._refresh = () => this.render()
    document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)

    this.dokumentService = new DokumentService(this.gesuchId, 'dokumente/file')
    this.columns = [
      DokumenteColumns.FILENAME_COLUMN(),
      DokumenteColumns.VERSION_COLUMN(),
      DokumenteColumns.DOK_TYP_COLUMN(),
      DokumenteColumns.VARANTW_PERSON_COLUMN(),
      DokumenteColumns.ABLAGEORT_COLUMN(this.dokumentService, this.i18n),
      DokumenteColumns.IO_COLUMN(this.i18n),
      DokumenteColumns.PRUEFSTATUS_COLUMN(),
      DokumenteColumns.NOTIZ_COLUMN(),
      DokumenteColumns.CREATED_AT_COLUMN(),
      DokumenteColumns.UPDATED_AT_COLUMN(),
      DokumenteColumns.USER_COLUMN()
    ]

    this.url = await this.gesuchService.getBackendListUrl(`${this.gesuchId}/dokument/dokumente`)
    this.gesuchService.createOrGetDokument(this.gesuchId + '/dokument/dokumente/detail')
      .then(dokumentId => {
        this.dokumentId = dokumentId
        this.gesuchService.read(this.gesuchId, `dokument/dokumente/detail/${this.dokumentId}`)
          .then(backendData => {
            this.backendData = backendData
            this.render()
          })
      })
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this._refresh)
  }

  getTemplate() {
    return html`
      ${this.personId ? html`
        <div class="header-wrapper">
          <isceco-button
            id="verantwortliche-person-back"
            variant="secondary"
            icon="arrow left"
            text="${this.i18n.translate('geschaeftsvorfall.dokumente.person.back')}"
            @click="${_ => this._backToVerantwortlichePerson(this.personId)}"
          ></isceco-button>
        </div>
      ` : html``}
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.dokumente.header')}">

        <div class="title-content">
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteViewer()}"
          ></isceco-button>
          <div class="${this._getStatusClass()}" style="padding: 10px 5px 10px 25px; border-radius: 20px;">
            <isceco-button
              id="dokumente-add-btn"
              title="${this.i18n.translate('geschaeftsvorfall.dokumente.button.create')}"
              icon="plus"
              text="${this.i18n.translate('geschaeftsvorfall.dokument.create.title')}"
              variant="primary"
              @click="${_ => this._navigateAndCreateDokumentIfNeeded()}"
            ></isceco-button>
          </div>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>

      </isceco-title>

      <isceco-list
        id="dokumenteList"
        .listService="${new VzavgListService(this.url)}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
        order="desc"
        max="50"
        update-event="${Dokumente.EVENT_KEYS.TRIGGER_TABLE_RELOAD}">
      </isceco-list>
    `
  }

  _getStatusClass() {
    if (this.backendData.status === 'green') {
      return 'statusGreen'
    } else if (this.backendData.status === 'orange') {
      return 'statusOrange'
    } else if (this.backendData.status === 'red') {
      return 'statusRed'
    } else {
      return ''
    }
  }

  _openDokumenteViewer() {
    const to = `#${geschaeftsVorfallNavigationLinks.documentViewer.hash}`
    const id = this.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }

  _navigateAndCreateDokumentIfNeeded() {
    navigate({to: geschaeftsVorfallNavigationLinks.dokumenteEdit.hash, id: `${this.gesuchId}/${this.dokumentId}`})
  }

  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  _getVerantwortlichePersonId() {
    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    return urlParams.get('personId');
  }

  _backToVerantwortlichePerson(personId) {
    navigate({to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash, id: `${this.gesuchId}/${personId}`})
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-dokumente', Dokumente)
